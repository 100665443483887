import BaseObject from "./BaseObject";
import * as THREE from "three";
import Background from "../Assets/Textures/background.jpg";

class SceneBO extends BaseObject {
  constructor(params) {
    super();
    this._params = params;
    this._scene = new THREE.Scene();
    this.loadBackground();
  }
  loadBackground = () => {
    const texLoader = new THREE.TextureLoader();
    texLoader.load(Background, (texture) => {
      this._textureBackground = texture;
      this._params.modelLoadedEvent({source: this});
    });
  };
  setBackground = () => {
    this._scene.background = this._textureBackground;
  }
  get scene() {
    return this._scene;
  }
}
export default SceneBO;