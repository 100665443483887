import GameObject from "./GameObject";
import Room from "../../Assets/Models/Room.glb";

class RoomGO extends GameObject {
  loadModels() {
    this._params.loader.load(Room, (object) => {
      object.scene.traverse((c) => {
        c.receiveShadow = true;
        c.castShadow = false;
      });

      this._mesh = object.scene;

      this.setIsVisible(false);
      
      this.dispatchModelLoaded(this._mesh);
    });
  }
}
export default RoomGO;
