import * as THREE from "three";
import * as TWEEN from "@tweenjs/tween.js";
import GameObject from "./GameObject";
import Book from "../../Assets/Models/Book.glb";
import BookGrey from "../../Assets/Textures/book_grey.jpg";
import BookGreyLight from "../../Assets/Textures/book_grey_light.jpg";
import BookRed from "../../Assets/Textures/book_red.jpg";
import BookBlue from "../../Assets/Textures/book_blue.jpg";
import BookBlueDark from "../../Assets/Textures/book_blue_dark.jpg";
import BookGreen from "../../Assets/Textures/book_green.jpg";
import BookWhite from "../../Assets/Textures/book_white.jpg";
import BookYellow from "../../Assets/Textures/book_yellow.jpg";
import BookHazel from "../../Assets/Textures/book_hazel.jpg";
import NTierNETContent from "../../Assets/Textures/NTier.NET-Content.jpg";
import PaypalNETContent from "../../Assets/Textures/Paypal.NET-Content.jpg";
import TcpNETContent from "../../Assets/Textures/Tcp.NET-Content.jpg";
import WebsocketsSimpleContent from "../../Assets/Textures/WebsocketsSimple-Content.jpg";
import JWTsContent from "../../Assets/Textures/JWTs-Content.jpg";
import OAuthServerNETContent from "../../Assets/Textures/OAuthServer.NET-Content.jpg";
import TheMonitaurContent from "../../Assets/Textures/TheMonitaur-Content.jpg";
import AllieChatContent from "../../Assets/Textures/Allie.Chat-Content.jpg";
import SiteMapsNETContent from "../../Assets/Textures/SiteMaps.NET-Content.jpg";
import SteamNETContent from "../../Assets/Textures/Steam.NET-Content.jpg";
import TwitchBotsNETContent from "../../Assets/Textures/TwitchBots.NET-Content.jpg";
import PHSCoreContent from "../../Assets/Textures/PHS.Core-Content.jpg";

class BookGO extends GameObject {
  loadModels() {
    this._params.loader.load(Book, (object) => {
      object.scene.traverse((c) => {
        if (c.name !== "GitHub" && c.name !== "NuGet" && c.name !== "Extra") {
          c.castShadow = true;
        } else {
          switch (c.name) {
            case "GitHub":
              this._gitHub = c;
              break;
            case "NuGet":
              this._nuGet = c;
              break;
            case "Extra":
              this._extra = c;
              break;
            default:
              break;
          }
          // Create non-lit materials for Links
          const mat = new THREE.MeshBasicMaterial({
            map: c.material.map,
            name: c.name,
          });
          c.material.dispose();
          c.material = mat;
        }

        if (c instanceof THREE.SkinnedMesh) {
          try {
            c.geometry.computeBoundingBox();
            c.geometry.computeBoundingSphere();
            c.geometry.boundingSphere.radius *= 400;
            c.geometry.boundingBox.min *= 400;
            c.geometry.boundingBox.max *= 400;
          } catch {}
        }

        c.hoverCallback = () => this.hoverCallback(c);
        c.clickCallback = () => this.clickCallback(c);
      });

      this._animations = object.animations;
      this._mesh = object.scene;
      this._mesh.scale.setScalar(0);

      this.setIsVisible(false);

      this.dispatchModelLoaded(this._mesh);

      this._mixer = new THREE.AnimationMixer(object.scene);
      const clip = THREE.AnimationClip.findByName(
        this._animations,
        "Book_Open"
      );
      this.action = this._mixer.clipAction(clip);
      this.action.play();
      this.action.paused = true;
    });

    const tex_loader = new THREE.TextureLoader();
    tex_loader.load(BookGrey, (texture) => {
      texture.encoding = THREE.sRGBEncoding;
      texture.flipY = false;
      this._greyTexture = texture;
    });
    tex_loader.load(BookGreyLight, (texture) => {
      texture.encoding = THREE.sRGBEncoding;
      texture.flipY = false;
      this._greyLightTexture = texture;
    });
    tex_loader.load(BookRed, (texture) => {
      texture.encoding = THREE.sRGBEncoding;
      texture.flipY = false;
      this._redTexture = texture;
    });
    tex_loader.load(BookBlue, (texture) => {
      texture.encoding = THREE.sRGBEncoding;
      texture.flipY = false;
      this._blueTexture = texture;
    });
    tex_loader.load(BookBlueDark, (texture) => {
      texture.encoding = THREE.sRGBEncoding;
      texture.flipY = false;
      this._blueDarkTexture = texture;
    });
    tex_loader.load(BookGreen, (texture) => {
      texture.encoding = THREE.sRGBEncoding;
      texture.flipY = false;
      this._greenTexture = texture;
    });
    tex_loader.load(BookWhite, (texture) => {
      texture.encoding = THREE.sRGBEncoding;
      texture.flipY = false;
      this._whiteTexture = texture;
    });
    tex_loader.load(BookYellow, (texture) => {
      texture.encoding = THREE.sRGBEncoding;
      texture.flipY = false;
      this._yellowTexture = texture;
    });
    tex_loader.load(BookHazel, (texture) => {
      texture.encoding = THREE.sRGBEncoding;
      texture.flipY = false;
      this._hazelTexture = texture;
    });
    tex_loader.load(NTierNETContent, (texture) => {
      texture.encoding = THREE.sRGBEncoding;
      texture.flipY = false;
      this._nTierNETContent = texture;
    });
    tex_loader.load(PaypalNETContent, (texture) => {
      texture.encoding = THREE.sRGBEncoding;
      texture.flipY = false;
      this._paypalNETContent = texture;
    });
    tex_loader.load(TcpNETContent, (texture) => {
      texture.encoding = THREE.sRGBEncoding;
      texture.flipY = false;
      this._tcpNETContent = texture;
    });
    tex_loader.load(WebsocketsSimpleContent, (texture) => {
      texture.encoding = THREE.sRGBEncoding;
      texture.flipY = false;
      this._websocketsSimpleContent = texture;
    });
    tex_loader.load(JWTsContent, (texture) => {
      texture.encoding = THREE.sRGBEncoding;
      texture.flipY = false;
      this._jwtsContent = texture;
    });
    tex_loader.load(OAuthServerNETContent, (texture) => {
      texture.encoding = THREE.sRGBEncoding;
      texture.flipY = false;
      this._oauthServerNETContent = texture;
    });
    tex_loader.load(TheMonitaurContent, (texture) => {
      texture.encoding = THREE.sRGBEncoding;
      texture.flipY = false;
      this._theMonitaurContent = texture;
    });
    tex_loader.load(AllieChatContent, (texture) => {
      texture.encoding = THREE.sRGBEncoding;
      texture.flipY = false;
      this._allieChatContent = texture;
    });
    tex_loader.load(SiteMapsNETContent, (texture) => {
      texture.encoding = THREE.sRGBEncoding;
      texture.flipY = false;
      this._siteMapsNETContent = texture;
    });
    tex_loader.load(SteamNETContent, (texture) => {
      texture.encoding = THREE.sRGBEncoding;
      texture.flipY = false;
      this._steamNETContent = texture;
    });
    tex_loader.load(TwitchBotsNETContent, (texture) => {
      texture.encoding = THREE.sRGBEncoding;
      texture.flipY = false;
      this._twitchBotsNETContent = texture;
    });
    tex_loader.load(PHSCoreContent, (texture) => {
      texture.encoding = THREE.sRGBEncoding;
      texture.flipY = false;
      this._phsCoreContent = texture;
    });
  }
  update = (deltaTime) => {
    if (this._mixer) {
      this._mixer.update(deltaTime);
    }

    if (this.tween) {
      this.tween.update();
    }
  };
  hoverCallback = (mesh) => {
    if (this._canBeSelected) {
      if (
        mesh.name === "GitHub" ||
        mesh.name === "NuGet" ||
        mesh.name === "Extra"
      ) {
        this._params.hoverEvent({
          source: this,
          items: [mesh],
        });
        return;
      }
    }

    this._params.hoverEvent({
      source: this,
      items: [],
    });
  };
  clickCallback = (mesh) => {
    if (this._canBeSelected) {
      switch (mesh.name) {
        case "GitHub":
          this._params.clickEvent({
            source: this,
            mesh: mesh,
            url: this._book.githubUrl,
            name: mesh.name
          });
          break;
        case "NuGet":
          this._params.clickEvent({
            source: this,
            mesh: mesh,
            url: this._book.nugetUrl,
            name: mesh.name
          });
          break;
        case "Extra":
          if (mesh.visible) {
            this._params.clickEvent({
              source: this,
              mesh: mesh,
              url: this._book.extraUrl,
              name: mesh.name
            });
          }
          break;
        default:
          break;
      }
    }
  };
  showBook = (book, cameraGO) => {
    this._book = book;

    this._mesh.traverse((child) => {
      if (child instanceof THREE.Mesh) {
        if (child.material.name === "Book") {
          switch (this._book.bookType) {
            case "paypal.net":
              child.material.map = this._greyTexture;
              break;
            case "red":
              child.material.map = this._redTexture;
              break;
            case "ntier.net":
              child.material.map = this._blueTexture;
              break;
            case "tcp.net":
              child.material.map = this._greenTexture;
              break;
            case "websocketssimple":
              child.material.map = this._redTexture;
              break;
            case "jwts":
              child.material.map = this._redTexture;
              break;
            case "oauthserver.net":
              child.material.map = this._greyTexture;
              break;
            case "allie.chat":
              child.material.map = this._greyLightTexture;
              break;
            case "themonitaur":
              child.material.map = this._blueDarkTexture;
              break;
            case "sitemaps.net":
              child.material.map = this._whiteTexture;
              break;
            case "steam.net":
              child.material.map = this._yellowTexture;
              break;
            case "twitchbots.net":
              child.material.map = this._hazelTexture;
              break;
            case "phs.core":
              child.material.map = this._whiteTexture;
              break;
            default:
              break;
          }
        }

        // Set extra panel visible or not
        if (child.material.name === "Extra") {
          if (
            this._book.bookType === "oauthserver.net" ||
            this._book.bookType === "allie.chat" ||
            this._book.bookType === "themonitaur"
          ) {
            child.visible = true;
          } else {
            child.visible = false;
          }
        }

        if (
          child.material.name === "Content" ||
          child.material.name === "Cover" ||
          child.material.name === "Title" ||
          child.material.name === "GitHub" ||
          child.material.name === "NuGet" ||
          child.material.name === "Extra"
        ) {
          switch (this._book.bookType) {
            case "paypal.net":
              child.material.map = this._paypalNETContent;
              break;
            case "red":
              child.material.map = this._redTexture;
              break;
            case "ntier.net":
              child.material.map = this._nTierNETContent;
              break;
            case "tcp.net":
              child.material.map = this._tcpNETContent;
              break;
            case "websocketssimple":
              child.material.map = this._websocketsSimpleContent;
              break;
            case "jwts":
              child.material.map = this._jwtsContent;
              break;
            case "oauthserver.net":
              child.material.map = this._oauthServerNETContent;
              break;
            case "themonitaur":
              child.material.map = this._theMonitaurContent;
              break;
            case "allie.chat":
              child.material.map = this._allieChatContent;
              break;
            case "sitemaps.net":
              child.material.map = this._siteMapsNETContent;
              break;
            case "steam.net":
              child.material.map = this._steamNETContent;
              break;
            case "twitchbots.net":
              child.material.map = this._twitchBotsNETContent;
              break;
            case "phs.core":
              child.material.map = this._phsCoreContent;
              break;
            default:
              break;
          }
        }
      }
    });

    this.dispatchMovingEvent(true, "showBook");
    
    this.tween = new TWEEN.Tween(
      new THREE.Vector3(
        this._book.point.x,
        this._book.point.y,
        this._book.point.z
      )
    ).to(
      {
        x: cameraGO.camera.position.x - 1.5,
        y: cameraGO.camera.position.y - 0.5,
        z: cameraGO.camera.position.z + 3,
      },
      400
    );

    this.tween.onUpdate((object, elapsed) => {
      this._mesh.scale.setScalar(elapsed * 0.15);

      this._mesh.position.set(object.x, object.y, object.z);
      this._mesh.quaternion.copy(cameraGO.camera.quaternion);

      if (elapsed > 0.2) {
        const initQuat = new THREE.Quaternion();
        initQuat.copy(cameraGO.camera.quaternion);
        cameraGO.camera.lookAt(
          new THREE.Vector3(
            this._mesh.position.x,
            cameraGO.camera.position.y - 0.5,
            this._mesh.position.z
          )
        );
        const dest = new THREE.Quaternion();
        dest.copy(cameraGO.camera.quaternion);
        cameraGO.camera.quaternion.slerpQuaternions(
          initQuat,
          dest,
          elapsed
        );
        cameraGO.camera.quaternion.normalize();
      }
    });

    this.tween.onComplete((object) => {
      cameraGO.camera.lookAt(
        new THREE.Vector3(
          this._mesh.position.x,
          cameraGO.camera.position.y - 0.5,
          this._mesh.position.z
          )
        );
      cameraGO.camera.quaternion.normalize();
      this._mesh.quaternion.copy(cameraGO.camera.quaternion);

      this.tween = undefined;

      this.dispatchMovingEvent(false, "showBook");
    });

    this.tween.start();
  }
  hideBook = () => {
    this.dispatchMovingEvent(true, "hideBook");

    this.tween = new TWEEN.Tween(this._mesh.position).to(
      {
        x: this._book.point.x,
        y: this._book.point.y,
        z: this._book.point.z,
      },
      400
    );

    this.tween.onUpdate((object, elapsed) => {
      this._mesh.position.set(object.x, object.y, object.z);
      this._mesh.scale.setScalar((1 - elapsed) * 0.15);
    });

    this.tween.onComplete(() => {
      this.dispatchMovingEvent(false, "hideBook");
      this.tween = undefined;
    });

    this.tween.start();
  }
  openBook = () => {
    if (!this._canBeSelected) {
      this.dispatchMovingEvent(true, "openBook");

      this.action.time = 0;
      this.action.clampWhenFinished = true;
      this.action.reset();
      this.action.setEffectiveTimeScale(1.65);
      this.action.enabled = true;
      this.action.setLoop(THREE.LoopOnce);

      this.finishedCallback = () => {
        this._mixer.removeEventListener("finished", this.finishedCallback);
        this.dispatchMovingEvent(false, "openBook");
        this.setCanBeSelected(true);
      };

      this._mixer.addEventListener("finished", this.finishedCallback);
      this.action.paused = false;
      this.action.play();
    }
  };
  closeBook = () => {
    this.dispatchMovingEvent(true, "closeBook");
    this.setCanBeSelected(false);

    this.action.setEffectiveTimeScale(-1.65);
    this.action.setLoop(THREE.LoopOnce);
    this.action.paused = false;
    this.action.play();

    this.finishedCallback = () => {
      this._mixer.removeEventListener("finished", this.finishedCallback);

      this.dispatchMovingEvent(false, "closeBook");
    };

    this._mixer.addEventListener("finished", this.finishedCallback);
  };
  setCanBeSelected = (canBeSelected) => {
    this._canBeSelected = canBeSelected;
  };
  reset = () => {
    this._book = undefined;
    this.setCanBeSelected(false);
  };
  dispatchMovingEvent = (moving, state) => {
    this._params.movingObjectEvent({moving, state});
  };
  get book() {
    return this._book;
  }
  get gitHub() {
    return this._gitHub;
  }
  get nuGet() {
    return this._nuGet;
  }
  get extra() {
    return this._extra;
  }
}
export default BookGO;
