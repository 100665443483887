import GameObject from "./GameObject";
import * as THREE from "three";
import * as TWEEN from "@tweenjs/tween.js";
import { SceneType } from "../../enums";

class CameraGO extends GameObject {
  constructor(params) {
    super(params);

    this.camera = new THREE.PerspectiveCamera(
      this.fov,
      window.innerWidth / window.innerHeight,
      .1,
      40
    );

    switch (this._params.sceneType) {
      case SceneType.typing:
        this.camera.position.set(16, 4, -1.36);
        this.camera.lookAt(-20, -4, 5);
        break;
      default:
        break;
    }
  }
  update(deltaTime) {
    if (this.tween) {
      this.tween.update();
    }
    
    if (this.tweenCameraUp) {
      this.tweenCameraUp.update();
    }

    if (this.tweenCameraDown) {
      this.tweenCameraDown.update();
    }
  }
  moveToBookcase = () => {
    this.dispatchMovingEvent(true, "bookcase");

    this.tween = new TWEEN.Tween(this.camera.position)
      .to({ x: 1, y: 3, z: -0.5 }, 500)
      .easing(TWEEN.Easing.Quadratic.Out);

    this.tween.onUpdate((object, elapsed) => {
      this.camera.position.set(object.x, object.y, object.z);
      this.lookAt(new THREE.Vector3(-20, 1, 0), elapsed);
    });

    this.tween.onComplete((object) => {
      this.dispatchMovingEvent(false, "bookcase");
      this.tween = undefined;
    });

    this.tween.start();
  };
  moveToMonitor = () => {
    this.dispatchMovingEvent(true, "monitor");

    this.tween = new TWEEN.Tween(this.camera.position)
      .to({ x: 10, y: 3, z: 0 }, 500)
      .easing(TWEEN.Easing.Quadratic.Out);

    this.tween.onUpdate((object, elapsed) => {
      this.camera.position.set(object.x, object.y, object.z);

      const initQuat = new THREE.Quaternion();
      initQuat.copy(this.camera.quaternion);

      this.camera.lookAt(new THREE.Vector3(10, 3, -20));
      const dest = new THREE.Quaternion();
      dest.copy(this.camera.quaternion);

      this.camera.quaternion.slerpQuaternions(initQuat, dest, elapsed);
      this.camera.quaternion.normalize();
    });

    this.tween.onComplete((object) => {
      this.dispatchMovingEvent(false, "monitor");
      this.tween = undefined;
    });

    this.tween.start();
  };
  moveToArtOpensource = () => {
    this.dispatchMovingEvent(true, "artOpensource");

    this.tween = new TWEEN.Tween(this.camera.position)
      .to({ x: 7, y: 5.5, z: 0 }, 500)
      .easing(TWEEN.Easing.Quadratic.Out);

    this.tween.onUpdate((object, elapsed) => {
      this.camera.position.set(object.x, object.y, object.z);

      const initQuat = new THREE.Quaternion();
      initQuat.copy(this.camera.quaternion);

      this.camera.lookAt(new THREE.Vector3(7, 5.5, -20));
      const dest = new THREE.Quaternion();
      dest.copy(this.camera.quaternion);

      this.camera.quaternion.slerpQuaternions(initQuat, dest, elapsed);
      this.camera.quaternion.normalize();
    });

    this.tween.onComplete((object) => {
      this.dispatchMovingEvent(false, "artOpensource");
      this.tween = undefined;
    });

    this.tween.start();
  };
  moveToTV = () => {
    this.dispatchMovingEvent(true, "tv");

    this.tween = new TWEEN.Tween(this.camera.position)
      .to({ x: 0, y: 3, z: 5 }, 500)
      .easing(TWEEN.Easing.Quadratic.Out);

    this.tween.onUpdate((object, elapsed) => {
      this.camera.position.set(object.x, object.y, object.z);

      const initQuat = new THREE.Quaternion();
      initQuat.copy(this.camera.quaternion);

      this.camera.lookAt(new THREE.Vector3(0, 3, -10));
      const dest = new THREE.Quaternion();
      dest.copy(this.camera.quaternion);

      this.camera.quaternion.slerpQuaternions(initQuat, dest, elapsed);
      this.camera.quaternion.normalize();
    });

    this.tween.onComplete((object) => {
      this.dispatchMovingEvent(false, "tv");
      this.tween = undefined;
    });

    this.tween.start();
  };
  moveToArtBackWall = () => {
    this.dispatchMovingEvent(true, "artBackWall");

    this.tween = new TWEEN.Tween(this.camera.position)
      .to({ x: 5, y: 5, z: -3 }, 500)
      .easing(TWEEN.Easing.Quadratic.Out);

    this.tween.onUpdate((object, elapsed) => {
      this.camera.position.set(object.x, object.y, object.z);
      this.lookAt(new THREE.Vector3(-3, 2, 20), elapsed);
    });

    this.tween.onComplete((object) => {
      this.dispatchMovingEvent(false, "artBackWall");
      this.tween = undefined;
    });

    this.tween.start();
  };
  moveToSynthesizer = () => {
    this.dispatchMovingEvent(true, "synthesizer");

    this.tween = new TWEEN.Tween(this.camera.position)
      .to({ x: 8, y: 4, z: 5 }, 500)
      .easing(TWEEN.Easing.Quadratic.Out);

    this.tween.onUpdate((object, elapsed) => {
      this.camera.position.set(object.x, object.y, object.z);
      this.lookAt(new THREE.Vector3(0, -4, -20), elapsed);
    });

    this.tween.onComplete((object) => {
      this.dispatchMovingEvent(false, "synthesizer");
      this.tween = undefined;
    });

    this.tween.start();
  };
  moveToRey = () => {
    this.dispatchMovingEvent(true, "rey");

    this.tween = new TWEEN.Tween(this.camera.position)
      .to({ x: 8, y: 3, z: 2 }, 500)
      .easing(TWEEN.Easing.Quadratic.Out);

    this.tween.onUpdate((object, elapsed) => {
      this.camera.position.set(object.x, object.y, object.z);
      this.lookAt(new THREE.Vector3(-20, -20, 35), elapsed);
    });

    this.tween.onComplete((object) => {
      this.dispatchMovingEvent(false, "rey");
      this.tween = undefined;
    });

    this.tween.start();
  };
  reyJump = () => {
    const initQuat = new THREE.Quaternion();
    initQuat.copy(this.camera.quaternion);

    this.camera.lookAt(-20, 20, 35);
    const destQuat = new THREE.Quaternion();
    destQuat.copy(this.camera.quaternion);

    this.camera.lookAt(-20, -20, 35);

    this.tweenCameraUp = new TWEEN.Tween({t: 0})
      .to({t: 1}, 500)
      .easing(TWEEN.Easing.Quadratic.InOut);

    this.tweenCameraUp.onUpdate((object, elapsed) => {
      this.camera.quaternion.slerpQuaternions(
        initQuat,
        destQuat,
        elapsed
      );
      this.camera.quaternion.normalize();
    });

    this.tweenCameraUp.onComplete((object) => {
      this.tweenCameraUp = undefined;
    });

    this.tweenCameraDown = new TWEEN.Tween({t: 0})
      .to({t: 1}, 500)
      .easing(TWEEN.Easing.Quadratic.InOut);

    this.tweenCameraDown.onUpdate((object, elapsed) => {
      this.camera.quaternion.slerpQuaternions(
        destQuat,
        initQuat,
        elapsed
      );
      this.camera.quaternion.normalize();
    });

    this.tweenCameraDown.onComplete((object) => {
      this.tweenCameraDown = undefined;
    });

    this.tweenCameraUp.chain(this.tweenCameraDown).start();
  };
  reset = () => {
    this.dispatchMovingEvent(true, "reset");

    this.tween = new TWEEN.Tween(this.camera.position)
      .to({ x: 16, y: 4, z: -1.36 }, 500)
      .easing(TWEEN.Easing.Quadratic.Out);

    this.tween.onUpdate((object, elapsed) => {
      this.camera.position.set(object.x, object.y, object.z);
      this.lookAt(new THREE.Vector3(-20, -4, 5), elapsed);
    });

    this.tween.onComplete((object) => {
      this.dispatchMovingEvent(false, "reset");
      this.tween = undefined;
    });

    this.tween.start();
  };
  lookAt = (vector, elapsed) => {
    const initQuat = new THREE.Quaternion();
    initQuat.copy(this.camera.quaternion);

    this.camera.lookAt(vector);
    const dest = new THREE.Quaternion();
    dest.copy(this.camera.quaternion);

    this.camera.quaternion.slerpQuaternions(initQuat, dest, elapsed);
    this.camera.quaternion.normalize();
  };
  dispose() {
  }
  get fov() {
    const ratio = window.innerWidth / window.innerHeight;

    if (ratio < 0.2) {
      return 145;
    } else if (ratio < 0.21) {
      return 144;
    } else if (ratio < 0.22) {
      return 143;
    } else if (ratio < 0.23) {
      return 142;
    } else if (ratio < 0.24) {
      return 140;
    } else if (ratio < 0.25) {
      return 139;
    } else if (ratio < 0.26) {
      return 138;
    } else if (ratio < 0.27) {
      return 137;
    } else if (ratio < 0.285) {
      return 135;
    } else if (ratio < 0.3) {
      return 132;
    } else if (ratio < 0.33) {
      return 128;
    } else if (ratio < 0.36) {
      return 125;
    } else if (ratio < 0.4) {
      return 120;
    } else if (ratio < 0.44) {
      return 115;
    } else if (ratio < 0.48) {
      return 110;
    } else if (ratio < 0.51) {
      return 107;
    } else if (ratio < 0.53) {
      return 105;
    } else if (ratio < 0.58) {
      return 100;
    } else if (ratio < 0.63) {
      return 97;
    } else if (ratio < 0.7) {
      return 90;
    } else if (ratio < 0.76) {
      return 85;
    } else if (ratio < .82) {
      return 80;
    } else if (ratio < .92) {
      return 75;
    } else if (ratio < 1.1) {
      return 68;
    } else if (ratio < 1.3) {
      return 60;
    } else if (ratio < 1.6) {
      return 52;
    }

    return 50;
  }
}
export default CameraGO;
