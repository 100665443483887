import * as THREE from "three";
import FiniteStateMachine from "./FiniteStateMachine";
import AnimationState from "./States/AnimationState";
import { ReyAnimationType } from "./States/enums";

class ReyFSM extends FiniteStateMachine {
  constructor(parent) {
    super(parent);
    this.init();
  }
  init = () => {
    this.addState(ReyAnimationType.idle0, new AnimationState(this, ReyAnimationType.idle0, THREE.LoopOnce, 0.2));
    this.addState(ReyAnimationType.idle1, new AnimationState(this, ReyAnimationType.idle1, THREE.LoopOnce, 0.2));
    this.addState(ReyAnimationType.idle2, new AnimationState(this, ReyAnimationType.idle2, THREE.LoopOnce, 0.2));
    this.addState(ReyAnimationType.idle3, new AnimationState(this, ReyAnimationType.idle3, THREE.LoopOnce, 0.2));
    this.addState(ReyAnimationType.idleEnd, new AnimationState(this, ReyAnimationType.idleEnd, THREE.LoopOnce, 0.2));
    this.addState(ReyAnimationType.idleLoop, new AnimationState(this, ReyAnimationType.idleLoop, THREE.LoopRepeat, 0.2));
    this.addState(ReyAnimationType.idleStart, new AnimationState(this, ReyAnimationType.idleStart, THREE.LoopOnce, 0.2));
    this.addState(ReyAnimationType.lieEnd, new AnimationState(this, ReyAnimationType.lieEnd, THREE.LoopOnce, 0.2));
    this.addState(ReyAnimationType.lieLoop, new AnimationState(this, ReyAnimationType.lieLoop, THREE.LoopRepeat, 0.2));
    this.addState(ReyAnimationType.lieLoop1, new AnimationState(this, ReyAnimationType.lieLoop1, THREE.LoopRepeat, 0.2));
    this.addState(ReyAnimationType.lieStart, new AnimationState(this, ReyAnimationType.lieStart, THREE.LoopOnce, 0.2));
    this.addState(ReyAnimationType.lieSleepEnd, new AnimationState(this, ReyAnimationType.lieSleepEnd, THREE.LoopOnce, 0.2));
    this.addState(ReyAnimationType.lieSleepLoop, new AnimationState(this, ReyAnimationType.lieSleepLoop, THREE.LoopRepeat, 0.2));
    this.addState(ReyAnimationType.lieSleepStart, new AnimationState(this, ReyAnimationType.lieSleepStart, THREE.LoopOnce, 0.2));
    this.addState(ReyAnimationType.sittingEnd, new AnimationState(this, ReyAnimationType.sittingEnd, THREE.LoopOnce, 0.2));
    this.addState(ReyAnimationType.sittingLoop, new AnimationState(this, ReyAnimationType.sittingLoop, THREE.LoopRepeat, 0.2));
    this.addState(ReyAnimationType.sittingStart, new AnimationState(this, ReyAnimationType.sittingStart, THREE.LoopOnce, 0.2));
    this.addState(ReyAnimationType.jump, new AnimationState(this, ReyAnimationType.jump, THREE.LoopOnce, 0.2));
    this.addState(ReyAnimationType.attack, new AnimationState(this, ReyAnimationType.attack, THREE.LoopOnce, 0.2));
    this.addState(ReyAnimationType.swipe, new AnimationState(this, ReyAnimationType.swipe, THREE.LoopOnce, 0.2));
    this.addState(ReyAnimationType.swipe2, new AnimationState(this, ReyAnimationType.swipe2, THREE.LoopOnce, 0.2));
    this.addState(ReyAnimationType.diggingStart, new AnimationState(this, ReyAnimationType.diggingStart, THREE.LoopOnce, 0.2));
    this.addState(ReyAnimationType.diggingLoop, new AnimationState(this, ReyAnimationType.diggingLoop, THREE.LoopRepeat, 0.2));
    this.addState(ReyAnimationType.diggingEnd, new AnimationState(this, ReyAnimationType.diggingEnd, THREE.LoopOnce, 0.2));
  };
}
export default ReyFSM;
