import BaseObject from "./BaseObject";
import * as THREE from "three";

class RendererBO extends BaseObject {
  constructor() {
    super();

    this._renderer = new THREE.WebGLRenderer({
      canvas: document.querySelector("#canvas"),
      // antialias: true,
      // alpha: true
      powerPreference: "high-performance"
    });

    this._renderer.autoClear = false;
    this._renderer.shadowMap.enabled = true;
    this._renderer.shadowMap.type = THREE.PCFSoftShadowMap;
    this._renderer.setPixelRatio(window.devicePixelRatio);
    this._renderer.setSize(window.innerWidth, window.innerHeight);
  }
  get renderer() {
    return this._renderer;
  }
}
export default RendererBO;
