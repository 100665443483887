import * as THREE from "three";
import GameObject from "./GameObject";
import Curtains from "../../Assets/Models/Curtains.glb";

class CurtainsGO extends GameObject {
  loadModels() {
    if (this._params.isDay) {
      this._params.loader.load(Curtains, (object) => {
        object.scene.traverse((c) => {
          c.receiveShadow = true;
          c.castShadow = true;

          if (c instanceof THREE.SkinnedMesh) {
            c.geometry.computeBoundingBox();
            c.geometry.computeBoundingSphere();
            c.geometry.boundingSphere.radius *= 400;
            c.geometry.boundingBox.min *= 400;
            c.geometry.boundingBox.max *= 400;
          }

          c.hoverCallback = () => this.hoverCallback(object.scene.children);
          c.clickCallback = () => this.clickCallback(object.scene.children);
        });

        this._mesh = object.scene;

        this._animations = object.animations;

        this.setIsVisible(false);

        this.dispatchModelLoaded(this._mesh);

        this.reset();

        this._mixer = new THREE.AnimationMixer(object.scene);

        if (Math.random() > .5) {
          this._state = "Open";
        } else {
          this._state = "Close";
        }

        const clip = THREE.AnimationClip.findByName(
          this._animations,
          this._state
        );

        this.action = this._mixer.clipAction(clip);
        this.action.clampWhenFinished = true;
        this.action.time = 0;
        this.action.reset();
        this.action.setEffectiveTimeScale(1);
        this.action.setEffectiveWeight(1);
        this.action.enabled = true;
        this.action.setLoop(THREE.LoopOnce);
        this.action.play();
      });
    }
  }
  toggleState = () => {
    let action;

    if (this._state === "Open") {
      action = "Closing";
      this._state = "Close";
    } else {
      action = "Opening";
      this._state = "Open";
    }

    this.setCanBeSelected(false);

    this.action.enabled = false;

    const clip = THREE.AnimationClip.findByName(this._animations, action);
    this.action = this._mixer.clipAction(clip);
    this.action.clampWhenFinished = true;
    this.action.time = 0;
    this.action.reset();
    this.action.setEffectiveTimeScale(1);
    this.action.setEffectiveWeight(1);
    this.action.enabled = true;
    this.action.setLoop(THREE.LoopOnce);
    this._mixer.addEventListener("finished", this.finishedCallback);
    this.action.play();
  };
  finishedCallback = () => {
    this._mixer.removeEventListener("finished", this.finishedCallback);
    this.setCanBeSelected(true);
  };
  update = (deltaTime) => {
    if (this._mixer) {
      this._mixer.update(deltaTime);
    }
  };
  hoverCallback = (meshes) => {
    if (this._canBeSelected) {
      this._params.hoverEvent({
        items: meshes,
        source: this,
      });
    }
  };
  clickCallback = (meshes) => {
    if (this._canBeSelected) {
      this._params.clickEvent({
        items: meshes,
        source: this,
      });
    }
  };
  reset = () => {
    this.setCanBeSelected(true);
  };
  setCanBeSelected = (canBeSelected) => {
    this._canBeSelected = canBeSelected;
  };
  setIsVisible = (isVisible) => {
    this._mesh.visible = isVisible;
  };
}
export default CurtainsGO;
