import * as THREE from "three";
import "./PhoneIFrameGO.css";
import GameObject from "./GameObject";
import { CSS3DObject } from "three/examples/jsm/renderers/CSS3DRenderer.js";
import ReactDOMServer from "react-dom/server";
import { WEBAPI_URI } from "../../env";

class PhoneIFrameGO extends GameObject {
  loadModels() {
    this._object3d = new THREE.Object3D();

    const html = (
      <div className="container">
        <h1>Contact Me</h1>

        <div className="card">
          <h2>Rob Engel</h2>
          <hr />
          <p>
            <span>PO Box 20881</span>
            <span>Bakersfield, California, 93390</span>
            <span>United States of America</span>
          </p>
          <p>
            <span>+1 805-667-0929</span>
          </p>
          <a href="mailto:liveordevtrying@gmail.com">
            liveordevtrying@gmail.com
          </a>
          <a href="mailto:rob@robthegamedev.com">rob@robthegamedev.com</a>
          <a href="mailto:rob@robthedeveloper.com">rob@robthedeveloper.com</a>
          <a href="mailto:rob@pixelhorrorstudios.com">
            rob@pixelhorrorstudios.com
          </a>
        </div>

        <div id="card-message" className="card">
          <h3>Send me a message!</h3>
          <form id="contact-form">
            <div id="loader" className="card-hide"></div>
            <span>
              <label id="lbl-name">Name</label>
              <input
                type="text"
                id="name"
                name="name"
                placeholder="Your name.."
                maxLength={50}
              />
            </span>
            <label id="lbl-name-fail" className="validation-fail card-hide">Please enter your name.</label>

            <span>
              <label id="lbl-email">Email Address</label>
              <input
                type="text"
                id="email"
                name="email"
                placeholder="Your email.."
                maxLength={150}
              />
            </span>
            <label id="lbl-email-fail" className="validation-fail card-hide">Please enter a valid email.</label>

            <span>
              <label id="lbl-subject">Subject</label>
              <input
                id="subject"
                type="text"
                name="subject"
                placeholder="Enter subject.."
                maxLength={255}
              />
            </span>
            <label id="lbl-subject-fail" className="validation-fail card-hide">Please enter a subject.</label>

            <span>
              <label id="lbl-message">Message</label>
              <textarea
                id="message"
                name="message"
                placeholder="Enter message.."
                maxLength={1024}
                ></textarea>
            </span>

            <button id="btn-submit" type="button">
              Submit
            </button>
          </form>
        </div>

        <div id="card-success" className="card card-hide">
          <h3>Your message has been submit!</h3>
        </div>

        <div id="card-fail" className="card card-hide">
          <h3>Your message could not be delivered. Please try again later.</h3>
        </div>
      </div>
    );

    var div = document.createElement("div");
    div.style.width = 430 + "px";
    div.style.height = 600 + "px";
    div.innerHTML = ReactDOMServer.renderToStaticMarkup(html);

    this._domObject = new CSS3DObject(div);
    this._domObject.position.set(12, 3.7, -0.65);
    this._domObject.scale.setScalar(0.0045);

    this._object3d.add(this._domObject);

    var material = new THREE.MeshPhongMaterial({
      opacity: 0,
      color: new THREE.Color("black"),
      blending: THREE.NoBlending,
      side: THREE.DoubleSide,
      transparent: true,
    });

    var geometry = new THREE.PlaneGeometry(430, 600);
    this._mesh = new THREE.Mesh(geometry, material);
    this._mesh.position.copy(this._domObject.position);
    this._mesh.scale.copy(this._domObject.scale);
    this._mesh.quaternion.copy(this._params.cameraGO.camera.quaternion);
    this._domObject.rotation.copy(this._mesh.rotation);

    this._mesh.castShadow = false;
    this._mesh.receiveShadow = true;

    this.setIsVisible(false);

    setTimeout(() => {
      this.dispatchModelLoaded(this._object3d);
      this.dispatchModelLoaded(this._mesh);
    }, 1);
  }
  onRecaptchaChanged = async (token) => {
    try {
      const response = await fetch(`${WEBAPI_URI}/ContactUs`, {
        method: "post",
        mode: "cors",
        body: JSON.stringify({
          name: this._name.value,
          email: this._email.value,
          subject: this._subject.value,
          message: this._message.value,
          token
        }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      
      this._loader.classList.add("card-hide");
      this._cardMessage.classList.add("card-hide");

      if (response.status === 200) {
        this._cardSuccess.classList.remove("card-hide");
        return;
      }
    } catch {}

    this._loader.classList.add("card-hide");
    this._cardMessage.classList.add("card-hide");
    this._cardFail.classList.remove("card-hide");
  };
  setIsVisible(visible) {
    if (visible) {
      this._domObject.position.set(12, 3.7, -0.65);

      if (!this._cardMessage) {
        this._cardMessage = document.getElementById("card-message");
      }
      this._cardMessage.classList.remove("card-hide");

      if (!this._cardSuccess) {
        this._cardSuccess = document.getElementById("card-success");
      }
      this._cardSuccess.classList.add("card-hide");
      
      if (!this._cardFail) {
        this._cardFail = document.getElementById("card-fail");
      }
      this._cardFail.classList.add("card-hide");

      if (!this._loader) {
        this._loader = document.getElementById("loader");
      }
      this._loader.classList.add("card-hide");

      this._params.recaptchaRef.current.reset();
      
      if (!this._name) {
        this._name = document.getElementById("name");
        this._nameLabel = document.getElementById("lbl-name");
        this._nameLabelFail = document.getElementById("lbl-name-fail");

        this._name.onchange = () => {
          if (!this._name.value) {
            this._name.classList.add("validation-fail");
            this._nameLabel.classList.add("validation-fail");
            this._nameLabelFail.classList.remove("card-hide");
          } else {
            this._name.classList.remove("validation-fail");
            this._nameLabel.classList.remove("validation-fail");
            this._nameLabelFail.classList.add("card-hide");
          }

          this.validateForm();
        }
      }

      if (!this._email) {
        this._email = document.getElementById("email");
        this._emailLabel = document.getElementById("lbl-email");
        this._emailLabelFail = document.getElementById("lbl-email-fail");

        this._email.onchange = () => {
          if (!this.isValidEmail(this._email.value)) {
            this._email.classList.add("validation-fail");
            this._emailLabel.classList.add("validation-fail");
            this._emailLabelFail.classList.remove("card-hide");
          } else {
            this._email.classList.remove("validation-fail");
            this._emailLabel.classList.remove("validation-fail");
            this._emailLabelFail.classList.add("card-hide");
          }

          this.validateForm();
        }
      }

      if (!this._subject) {
        this._subject = document.getElementById("subject");
        this._subjectLabel = document.getElementById("lbl-subject");
        this._subjectLabelFail = document.getElementById("lbl-subject-fail");

        this._subject.onchange = () => {
          if (!this._subject.value) {
            this._subject.classList.add("validation-fail");
            this._subjectLabel.classList.add("validation-fail");
            this._subjectLabelFail.classList.remove("card-hide");
          } else {
            this._subject.classList.remove("validation-fail");
            this._subjectLabel.classList.remove("validation-fail");
            this._subjectLabelFail.classList.add("card-hide");
          }

          this.validateForm();
        }
      }

      if (!this._message) {
        this._message = document.getElementById("message");
      }

      if (!this._btnSubmit) {
        this._btnSubmit = document.getElementById("btn-submit");
        this._btnSubmit.onclick = () => {
          document.getElementById("loader").classList.remove("card-hide");
          this._params.recaptchaRef.current.execute();
        };
      }

      this._name.value = "";
      this._email.value = "";
      this._subject.value = "";
      this._message.value = "";
      this._btnSubmit.classList.add("card-hide");
    } else {
      this._domObject.position.set(100, 100, 100);
    }

    this._mesh.position.copy(this._domObject.position);
  }
  isValidEmail = (email) => {
    return /\S+@\S+\.\S+/.test(email);
  }
  validateForm = () => {
    if (this.isValidEmail(this._email.value) &&
      this._name.value &&
      this._subject.value) {
      this._btnSubmit.classList.remove("card-hide");
    } else {
      this._btnSubmit.classList.add("card-hide");
    }
  }
  reset = () => {
    this.setIsVisible(false);
  };
}
export default PhoneIFrameGO;
