import * as THREE from "three";
import * as TWEEN from "@tweenjs/tween.js";
import GameObject from "./GameObject";
import Phone from "../../Assets/Models/Phone.glb";

class PhoneGO extends GameObject {
  loadModels() {
    this._params.loader.load(Phone, (object) => {
      object.scene.traverse((c) => {
        c.receiveShadow = true;
        c.castShadow = true;

        if (c instanceof THREE.Mesh && c.material.name === "Screen") {
          const mat = new THREE.MeshBasicMaterial({
            map: c.material.map,
            name: c.name,
          });
          c.material.dispose();
          c.material = mat;
        }

        c.hoverCallback = () => this.hoverCallback(object.scene.children);
        c.clickCallback = () => this.clickCallback(object.scene.children);
      });

      this._mesh = object.scene;
      this._mesh.scale.setScalar(0.05);
      this._mesh.position.set(12, 2.16, -2);
      this._mesh.rotation.set(
        (Math.PI * 3) / 2,
        this._mesh.rotation.y,
        (Math.PI * 11) / 6
      );

      this.setIsVisible(false);

      this.dispatchModelLoaded(this._mesh);

      this.reset();
    });
  }
  hoverCallback = (meshes) => {
    if (this._canBeSelected && !this._isZoomedIn) {
      this._params.hoverEvent({
        items: meshes,
        source: this,
      });
    }
  };
  clickCallback = (meshes) => {
    if (this._canBeSelected) {
      this._params.clickEvent({
        items: meshes,
        source: this,
      });
    }
  };
  update(deltaTime) {
    if (this.tween) {
      this.tween.update();
    }
  }
  showPhone = (cameraGO) => {
    this._isZoomedIn = true;
    this.dispatchMovingEvent(true, "showPhone");

    this._initQuat = new THREE.Quaternion();
    this._initQuat.copy(this._mesh.quaternion);

    this.tween = new TWEEN.Tween({...this._mesh.position, fov: cameraGO.camera.fov}).to(
      {
        x: cameraGO.camera.position.x - 0.5,
        y: cameraGO.camera.position.y - 0.1,
        z: cameraGO.camera.position.z + 0.085,
        fov: 70
      },
      400
    );

    this.tween.onUpdate((object, elapsed) => {
      this._mesh.position.set(object.x, object.y, object.z);

      const initQuat = new THREE.Quaternion();
      initQuat.copy(this._mesh.quaternion);

      const dest = new THREE.Quaternion();
      dest.copy(cameraGO.camera.quaternion);
      this._mesh.quaternion.slerpQuaternions(initQuat, dest, elapsed);
      this._mesh.quaternion.normalize();

      cameraGO.camera.fov = object.fov;
      cameraGO.camera.updateProjectionMatrix();
    });

    this.tween.onComplete((object) => {
      this.tween = undefined;

      cameraGO.camera.fov = 70;
      cameraGO.camera.updateProjectionMatrix();

      setTimeout(() => {
        this.dispatchMovingEvent(false, "showPhone");
      }, 100);
    });

    this.tween.start();
  };
  hidePhone = (cameraGO) => {
    this._isZoomedIn = false;

    this.dispatchMovingEvent(true, "hidePhone");

    this.tween = new TWEEN.Tween({...this._mesh.position, fov: cameraGO.camera.fov}).to(
      { x: 12, y: 2.16, z: -2, fov: cameraGO.fov },
      400
    );

    this.tween.onUpdate((object, elapsed) => {
      this._mesh.position.set(object.x, object.y, object.z);

      const initQuat = new THREE.Quaternion();
      initQuat.copy(this._mesh.quaternion);

      this._mesh.rotation.set(
        (Math.PI * 3) / 2,
        this._mesh.rotation.y,
        (Math.PI * 11) / 6
      );
      this._mesh.quaternion.slerpQuaternions(initQuat, this._initQuat, elapsed);
      this._mesh.quaternion.normalize();

      cameraGO.camera.fov = object.fov;
      cameraGO.camera.updateProjectionMatrix();
    });

    this.tween.onComplete((object) => {
      this.tween = undefined;
      this.dispatchMovingEvent(false, "hidePhone");

      cameraGO.camera.fov = cameraGO.fov;
      cameraGO.camera.updateProjectionMatrix();
    });

    this.tween.start();
  };
  reset = () => {
    this.setCanBeSelected(true);
  };
  setCanBeSelected = (canBeSelected) => {
    this._canBeSelected = canBeSelected;
  };
  setIsVisible = (isVisible) => {
    this._mesh.visible = isVisible;
  };
  get mesh() {
    return this._mesh;
  }
}
export default PhoneGO;
