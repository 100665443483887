import * as THREE from "three";
import * as TWEEN from "@tweenjs/tween.js";
import GameObject from "./GameObject";
import PaintingTINTB from "./Paintings/PaintingTINTB";
import PaintingGemWars from "./Paintings/PaintingGemWars";
import PaintingOpenDJRadio from "./Paintings/PaintingOpenDJRadio";
import PaintingTheMonitaur from "./Paintings/PaintingTheMonitaur";
import PaintingAllieChat from "./Paintings/PaintingAllieChat";
import PaintingGoldBunnyStore from "./Paintings/PaintingGoldBunnyStore";
import PaintingPHS from "./Paintings/PaintingPHS";
import ArtBackWall from "../../Assets/Models/ArtBackWall.glb";

class ArtBackWallGO extends GameObject {
  loadModels() {
    this._paintings = [];

    this._params.loader.load(ArtBackWall, (object) => {
      object.scene.traverse((c) => {
        c.receiveShadow = true;
        c.castShadow = true;

        if (c instanceof THREE.Group) {
          if (c.name === "TINTB") {
            this._paintings.push(new PaintingTINTB(c));
          } else if (c.name === "GemWars") {
            this._paintings.push(new PaintingGemWars(c));
          } else if (c.name === "OpenDJRadio") {
            this._paintings.push(new PaintingOpenDJRadio(c));
          } else if (c.name === "TheMonitaur") {
            this._paintings.push(new PaintingTheMonitaur(c));
          } else if (c.name === "AllieChat") {
            this._paintings.push(new PaintingAllieChat(c));
          } else if (c.name === "GoldBunnyStore") {
            this._paintings.push(new PaintingGoldBunnyStore(c));
          } else if (c.name === "PHS") {
            this._paintings.push(new PaintingPHS(c));
          }
        }
        c.hoverCallback = () =>
          !this._isZoomedIn
            ? this.hoverCallback(object.scene.children)
            : this.hoverCallback(!this._activeObject ? c.parent.children : [c]);
        c.clickCallback = () =>
          !this._isZoomedIn
            ? this.clickCallback(object.scene.children)
            : this.clickCallback(!this._activeObject ? c.parent.children : [c]);
      });

      this._mesh = object.scene;

      this.setIsVisible(false);

      this.dispatchModelLoaded(this._mesh);

      this.reset();
    });
  };
  hoverCallback = (meshes) => {
    if (this._canBeSelected) {
      if (this._activeObject) {
        if (this._activeObject.getLinkUrl(meshes[0])) {
          this._params.hoverEvent({
            items: meshes,
            source: this,
          });
        } else {
          this._params.hoverEvent({
            items: [],
            source: this,
          });
        }
      } else {
        this._params.hoverEvent({
          items: meshes,
          source: this,
        });
      }
    }
  };
  clickCallback = (meshes) => {
    if (this._canBeSelected) {
      let painting;
      let url;

      if (this._activeObject) {
        url = this._activeObject.getLinkUrl(meshes[0]);

        if (!url) {
          return;
        }

        if (this._activeObject instanceof PaintingTINTB) {
          this._activeObject.pauseVideo();
        }
      }

      if (this._isZoomedIn) {
        painting = this.getPainting(meshes[0]);
      }

      this._params.clickEvent({
        items: meshes,
        source: this,
        painting,
        url
      });
    }
  };
  getPainting(object) {
    if (object.parent.name === "TINTB") {
      return this._paintings.find(x => x instanceof PaintingTINTB);
    } else if (object.parent.name === "GemWars") {
      return this._paintings.find(x => x instanceof PaintingGemWars);
    } else if (object.parent.name === "OpenDJRadio") {
      return this._paintings.find(x => x instanceof PaintingOpenDJRadio);
    } else if (object.parent.name === "TheMonitaur") {
      return this._paintings.find(x => x instanceof PaintingTheMonitaur);
    } else if (object.parent.name === "GoldBunnyStore") {
      return this._paintings.find(x => x instanceof PaintingGoldBunnyStore);
    } else if (object.parent.name === "AllieChat") {
      return this._paintings.find(x => x instanceof PaintingAllieChat);
    } else if (object.parent.name === "PHS") {
      return this._paintings.find(x => x instanceof PaintingPHS);
    }
  }
  update(deltaTime) {
    if (this.tween) {
      this.tween.update();
    }
  }
  showPainting(painting, cameraGO) {
    this._activeObject = painting;

    this._activeObject.setMaterial(true);

    this.dispatchMovingEvent(true, "showPainting");
    const initQuat = new THREE.Quaternion();
    initQuat.copy(painting.mesh.quaternion);

    const dest = new THREE.Quaternion();
    dest.copy(cameraGO.camera.quaternion);

    painting.mesh.traverse(x => {
      x.receiveShadow = false;
      x.castShadow = false;
    });

    this.tween = new TWEEN.Tween({...painting.mesh.position, fov: cameraGO.camera.fov}
    ).to(
      this.getPaintingZoomedLocation(cameraGO)
      ,
      400
    );

    this.tween.onUpdate((object, elapsed) => {
      painting.mesh.position.set(object.x, object.y, object.z);
      painting.mesh.quaternion.slerpQuaternions(
        initQuat,
        dest,
        elapsed
      );
      painting.mesh.quaternion.normalize();

      cameraGO.camera.fov = object.fov;
      cameraGO.camera.updateProjectionMatrix();
    });

    this.tween.onComplete(() => {
      this.tween = undefined;

      this.dispatchMovingEvent(false, "showPainting");

      this._activeObject.setLinksActive(true);

      cameraGO.camera.fov = this.getPaintingZoomedLocation(cameraGO).fov;
      cameraGO.camera.updateProjectionMatrix();
    });

    this.tween.start();
  };
  getPaintingZoomedLocation(cameraGO) {
    return {
      x: cameraGO.camera.position.x - 1.05,
      y: cameraGO.camera.position.y - 0.5,
      z: cameraGO.camera.position.z + 3,
      fov: 80
    }
  }
  hidePainting(cameraGO) {
    this.dispatchMovingEvent(true, "hidePainting");

    this._activeObject.setLinksActive(false);

    const initQuat = new THREE.Quaternion();
    initQuat.copy(this._activeObject.mesh.quaternion);

    this.tween = new TWEEN.Tween({...this._activeObject.mesh.position, fov: cameraGO.camera.fov}).to({...this._activeObject._startingPosition, fov: cameraGO.fov}, 400);

    this.tween.onUpdate((object, elapsed) => {
      this._activeObject.mesh.position.set(object.x, object.y, object.z);

      this._activeObject.mesh.lookAt(this._activeObject.startingLookAt);
      const dest = new THREE.Quaternion();
      dest.copy(this._activeObject.mesh.quaternion);

      this._activeObject.mesh.quaternion.slerpQuaternions(
        initQuat,
        dest,
        elapsed
      );
      this._activeObject.mesh.quaternion.normalize();

      cameraGO.camera.fov = object.fov;
      cameraGO.camera.updateProjectionMatrix();
    });

    this.tween.onComplete(() => {
      this.dispatchMovingEvent(false, "hidePainting");
      this.tween = undefined;

      this._activeObject.mesh.traverse(x => {
        x.receiveShadow = true;
        x.castShadow = true;
      });

      this._activeObject.setMaterial(false);

      this._activeObject = undefined;

      cameraGO.camera.fov = cameraGO.fov;
      cameraGO.camera.updateProjectionMatrix();
    });

    this.tween.start();
  }
  setIsZoomedIn(isZoomedIn) {
    this._isZoomedIn = isZoomedIn;
  };
  reset() {
    this.setCanBeSelected(true);
    this.setIsZoomedIn(false);
  };
  setCanBeSelected(canBeSelected) {
    this._canBeSelected = canBeSelected;
  };
  dispose() {
    this._paintings.find(x => x instanceof PaintingTINTB).dispose();
    super.dispose();
  }
  get activeObject() {
    return this._activeObject;
  }
  get paintings() {
    return this._paintings;
  }
}
export default ArtBackWallGO;
