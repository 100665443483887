import * as THREE from "three";
import * as TWEEN from "@tweenjs/tween.js";
import GameObject from "./GameObject";
import ReyFSM from "./StateMachines/ReyFSM";
import { ReyAnimationType } from "./StateMachines/States/enums";
import Rey from "../../Assets/Models/Rey.glb";

class ReyGO extends GameObject {
  constructor(params) {
    super(params);
    this.stateMachine = new ReyFSM(this);
    this.animations = [];
    this._timers = [];

    this.start();
  }
  start() {
    this._params.loader.load(Rey, (object) => {
      object.scene.traverse((c) => {
        c.receiveShadow = true;
        c.castShadow = true;

        if (c instanceof THREE.SkinnedMesh) {
          try {
            c.geometry.computeBoundingBox();
            c.geometry.computeBoundingSphere();
            c.geometry.boundingSphere.radius *= 400;
            c.geometry.boundingBox.min *= 400;
            c.geometry.boundingBox.max *= 400;
          } catch {}
        }

        c.hoverCallback = () => this.hoverCallback(c);
        c.clickCallback = () => this.clickCallback(c);
      });

      this._mesh = object.scene;

      this._mesh.scale.setScalar(0.04);
      this._mesh.position.set(5.5, 0.55, 4.5);
      this._mesh.lookAt(5.5, 1, -20);

      this._mixer = new THREE.AnimationMixer(object.scene);

      for (let i = 0; i < object.animations.length; i++) {
        const clip = object.animations[i];
        const action = this._mixer.clipAction(clip);
    
        this.animations[object.animations[i].name] = {
          clip: clip,
          action: action,
        };
      }

      this.setIsVisible(false);

      this.reset();
      
      this.dispatchModelLoaded(this._mesh);
    });
  }
  update = (deltaTime) => {
    if (!this._mesh) {
      return;
    }

    if (this.tween) {
      this.tween.update();
    }

    if (!this._elapsedTime) {
      this._elapsedTime = 0;
    }
    this._elapsedTime += deltaTime;

    this.stateMachine.update(deltaTime);
    this._mixer.update(deltaTime);

    for (let i = 0; i < this._timers.length; i++) {
      if (
        this._elapsedTime - this._timers[i].startTime >=
        this._timers[i].timeLimit
      ) {
        this.onTimerComplete(this._timers[i].state);

        this._timers = [
          ...this._timers.slice(0, this._timers.indexOf(this._timers[i])),
          ...this._timers.slice(this._timers.indexOf(this._timers[i]) + 1),
        ];
      }
    }
  };
  hoverCallback = (mesh) => {
    if (this._canBeSelected) {
      this._params.hoverEvent({
        source: this, 
        items: mesh.parent.children
      });
    } else {
      this._params.hoverEvent({
        source: this,
        items: []
      });
    }
  };
  clickCallback = (mesh) => {
    if (this._canBeSelected) {
      this._params.clickEvent({
        source: this,
        items: mesh.parent.children,
      });
    }
  };
  onClickEvent = (cameraGO) => {
    this.setState("clicked");
    this.setCanBeSelected(false);
    this._mesh.lookAt(
      new THREE.Vector3(
        cameraGO.camera.position.x,
        this._mesh.position.y,
        cameraGO.camera.position.z
      )
    );
    this.hoverCallback(this._mesh);
  };
  reset = () => {
    this.setCanBeSelected(true);
  };
  setState = (state) => {
    this._state = state;

    switch (state) {
      case "idle":
        if (this._isSitting) {
          this._isSitting = false;
          this.stateMachine.setState(ReyAnimationType.sittingEnd);
        } else if (this._isSleeping) {
          this._isSleeping = false;
          this.stateMachine.setState(ReyAnimationType.lieSleepEnd);
        } else if (this._isLying) {
          this._isLying = false;
          this.stateMachine.setState(ReyAnimationType.lieEnd);
        } else if (this._isDigging) {
          this._isDigging = false;
          this.stateMachine.setState(ReyAnimationType.diggingEnd);
        } else {
          this.onAnimationCompleted(ReyAnimationType.lieEnd);
        }
        break;
      case "lie":
        if (this._isSitting) {
          this._isSitting = false;
          this.stateMachine.setState(ReyAnimationType.sittingEnd);
        } else if (this._isSleeping) {
          this._isSleeping = false;
          this.stateMachine.setState(ReyAnimationType.lieSleepEnd);
        } else if (this._isLying) {
          this.onAnimationCompleted(ReyAnimationType.lieStart);
        } else if (this._isDigging) {
          this._isDigging = false;
          this.stateMachine.setState(ReyAnimationType.diggingEnd);
        } else {
          this._isLying = true;
          this.stateMachine.setState(ReyAnimationType.lieStart);
        }
        break;
      case "sleep":
        if (this._isSitting) {
          this._isSitting = false;
          this.stateMachine.setState(ReyAnimationType.sittingEnd);
        } else if (this._isSleeping) {
          this.onAnimationCompleted(ReyAnimationType.lieSleepStart);
        } else if (this._isLying) {
          this._isSleeping = true;
          this.stateMachine.setState(ReyAnimationType.lieSleepStart);
        } else if (this._isDigging) {
          this._isDigging = false;
          this.stateMachine.setState(ReyAnimationType.diggingEnd);
        } else {
          this._isLying = true;
          this.stateMachine.setState(ReyAnimationType.lieStart);
        }
        break;
      case "sitting":
        if (this._isSleeping) {
          this._isSleeping = false;
          this.stateMachine.setState(ReyAnimationType.lieSleepEnd);
        } else if (this._isLying) {
          this._isLying = false;
          this.stateMachine.setState(ReyAnimationType.lieEnd);
        } else if (this._isSitting) {
          this.onAnimationCompleted(ReyAnimationType.sittingStart);
        } else if (this._isDigging) {
          this._isDigging = false;
          this.stateMachine.setState(ReyAnimationType.diggingEnd);
        } else {
          this._isSitting = true;
          this.stateMachine.setState(ReyAnimationType.sittingStart);
        }
        break;
      case "digging":
        if (this._isSleeping) {
          this._isSleeping = false;
          this.stateMachine.setState(ReyAnimationType.lieSleepEnd);
        } else if (this._isLying) {
          this._isLying = false;
          this.stateMachine.setState(ReyAnimationType.lieEnd);
        } else if (this._isSitting) {
          this._isSitting = false;
          this.stateMachine.setState(ReyAnimationType.sittingEnd);
        } else if (this._isDigging) {
          this.onAnimationCompleted(ReyAnimationType.diggingStart);
        } else {
          this._isDigging = true;
          this.stateMachine.setState(ReyAnimationType.diggingStart);
        }
        break;
      case "clicked":
        this._isSitting = false;
        this._isLying = false;
        this._isSleeping = false;
        this._isDigging = false;
        this.tween = undefined;

        const actionType = Math.random();
        if (actionType > .66) {
          this._params.actionEvent({action: "rey", animation: "jump"});
          this.stateMachine.setState(ReyAnimationType.jump);
        } else if (actionType > .33) {
          this._params.actionEvent({action: "rey", animation: "attack"});
          this.stateMachine.setState(ReyAnimationType.attack);
        } else {
          this.stateMachine.setState(Math.random() > 0.5 ? ReyAnimationType.swipe : ReyAnimationType.swipe2);
          this._params.actionEvent({action: "rey", animation: "swipe"});
        }
        break;
      default:
        break;
    }
  };
  onAnimationStarted = (animationName) => {};
  onAnimationCompleted = (animationName) => {
    switch (this._state) {
      case "idle":
        switch (animationName) {
          case ReyAnimationType.idleStart:
            this.stateMachine.setState(ReyAnimationType.idleLoop);

            this._timers.push({
              timeLimit: Math.random() * 20,
              startTime: this._elapsedTime,
              state: "idle",
            });
            break;
          case ReyAnimationType.idleLoop:
          case ReyAnimationType.idle0:
          case ReyAnimationType.idle1:
          case ReyAnimationType.idle2:
          case ReyAnimationType.idle3:
            this.playNextAnimation();
            break;
          case ReyAnimationType.lieSleepEnd:
            this._isLying = false;
            this.stateMachine.setState(ReyAnimationType.lieEnd);
            break;
          default:
            const idleAnimation = Math.random();

            if (idleAnimation > 0.75) {
              this.stateMachine.setState(ReyAnimationType.idle1);
            } else if (idleAnimation > 0.5) {
              this.stateMachine.setState(ReyAnimationType.idle2);
            } else if (idleAnimation > 0.25) {
              this.stateMachine.setState(ReyAnimationType.idle3);
            } else {
              this.stateMachine.setState(ReyAnimationType.idleStart);
            }
            break;
        }
        break;
      case "lie":
        switch (animationName) {
          case ReyAnimationType.sittingEnd:
            this._isLying = true;
            this.stateMachine.setState(ReyAnimationType.lieStart);
            break;
          case ReyAnimationType.lieStart:
          case ReyAnimationType.lieSleepEnd:
            this.stateMachine.setState(Math.random() > 0.5 ? ReyAnimationType.lieLoop : ReyAnimationType.lieLoop1);

            this._timers.push({
              timeLimit: Math.random() * 20,
              startTime: this._elapsedTime,
              state: "lie",
            });
            break;
          case ReyAnimationType.lieLoop:
          case ReyAnimationType.lieLoop1:
            this.playNextAnimation();
            break;
          default:
            this._isLying = true;
            this.stateMachine.setState(ReyAnimationType.lieStart);
            break;
        }
        break;
      case "sleep":
        switch (animationName) {
          case ReyAnimationType.sittingEnd:
            this._isLying = true;
            this.stateMachine.setState(ReyAnimationType.lieStart);
            break;
          case ReyAnimationType.lieStart:
            this._isSleeping = true;
            this.stateMachine.setState(ReyAnimationType.lieSleepStart);
            break;
          case ReyAnimationType.lieSleepStart:
            this.stateMachine.setState(ReyAnimationType.lieSleepLoop);

            this._timers.push({
              timeLimit: Math.random() * 20,
              startTime: this._elapsedTime,
              state: "sleep",
            });
            break;
          case ReyAnimationType.lieSleepLoop:
            this.playNextAnimation();
            break;
          default:
            this._isLying = true;
            this.stateMachine.setState(ReyAnimationType.lieStart);
            break;
        }
        break;
      case "sitting":
        switch (animationName) {
          case ReyAnimationType.sittingStart:
            this.stateMachine.setState(ReyAnimationType.sittingLoop);

            this._timers.push({
              timeLimit: Math.random() * 20,
              startTime: this._elapsedTime,
              state: "sitting",
            });
            break;
          case ReyAnimationType.lieSleepEnd:
            this._isLying = false;
            this.stateMachine.setState(ReyAnimationType.lieEnd);
            break;
          case ReyAnimationType.sittingLoop:
            this.playNextAnimation();
            break;
          default:
            this._isSitting = true;
            this.stateMachine.setState(ReyAnimationType.sittingStart);
            break;
        }
        break;
      case "digging":
        switch (animationName) {
          case ReyAnimationType.diggingStart:
            this.stateMachine.setState(ReyAnimationType.diggingLoop);

            this._timers.push({
              timeLimit: Math.random() * 10,
              startTime: this._elapsedTime,
              state: "digging",
            });
            break;
          case ReyAnimationType.lieSleepEnd:
            this._isLying = false;
            this.stateMachine.setState(ReyAnimationType.lieEnd);
            break;
          case ReyAnimationType.diggingLoop:
            this.playNextAnimation();
            break;
          default:
            this._isDigging = true;
            this.stateMachine.setState(ReyAnimationType.diggingStart);
            break;
        }
        break;
      case "clicked":
        this.rotateToIdle();
        this.setCanBeSelected(true);
        this.playNextAnimation();
        break;
      default:
        break;
    }
  };
  rotateToIdle = () => {
    const initQuat = new THREE.Quaternion();
    initQuat.copy(this._mesh.quaternion);

    this._mesh.lookAt(5.5, 1, -20);
    const destQuat = new THREE.Quaternion();
    destQuat.copy(this._mesh.quaternion);
    this._mesh.quaternion.slerpQuaternions(
      initQuat,
      this._destQuat,
      0
    );

    this.tween = new TWEEN.Tween({t: 0})
      .to({t: 1}, 100)
      .easing(TWEEN.Easing.Quadratic.Out);

    this.tween.onUpdate((object, elapsed) => {
      this._mesh.quaternion.slerpQuaternions(
        initQuat,
        destQuat,
        elapsed
      );
      this._mesh.quaternion.normalize();
    });

    this.tween.onComplete((object) => {
      this.tween = undefined;
    });

    this.tween.start();
  };
  playNextAnimation = () => {
    const nextAction = Math.random();

    if (nextAction > 0.8) {
      this.setState("idle");
    } else if (nextAction > 0.6) {
      this.setState("lie");
    } else if (nextAction > 0.4) {
      this.setState("sleep");
    } else if (nextAction > 0.2) {
      this.setState("digging");
    } else {
      this.setState("sitting");
    }
  };
  onTimerComplete = (state) => {
    if (state === this._state) {
      this.playNextAnimation();
    }
  };
  setCanBeSelected = (canBeSelected) => {
    this._canBeSelected = canBeSelected;
  };
  get mesh() {
    return this._mesh;
  }
}
export default ReyGO;
