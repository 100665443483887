import * as THREE from "three";
import GameObject from "./GameObject";
import BookcaseBooks from "../../Assets/Models/BookcaseBooks.glb";

class BookshelfBooksGO extends GameObject {
  loadModels() {
    this._canBeSelected = false;

    this._params.loader.load(BookcaseBooks, (object) => {
      object.scene.traverse((c) => {
        c.receiveShadow = true;
        c.castShadow = true;

        if (c instanceof THREE.Group) {
          if (c.name === "paypalnet") {
            this._paypalNET = c;
          } else if (c.name === "alliechat") {
            this._allieChat = c;
          } else if (c.name === "jwts") {
            this._jwts = c;
          } else if (c.name === "oauthservernet") {
            this._oauthServerNET = c;
          } else if (c.name === "ntiernet") {
            this._ntierNET = c;
          } else if (c.name === "sitemapsnet") {
            this._siteMapsNET = c;
          } else if (c.name === "steamnet") {
            this._steamNET = c;
          } else if (c.name === "tcpnet") {
            this._tcpNET = c;
          } else if (c.name === "websocketssimple") {
            this._websocketsSimple = c;
          } else if (c.name === "themonitaur") {
            this._theMonitaur = c;
          } else if (c.name === "phscore") {
            this._phsCore = c;
          } else if (c.name === "twitchbotsnet") {
            this._twitchBotsNET = c;
          }
        }

        c.hoverCallback = () => this.onHoverCallback(c);
        c.clickCallback = () => this.onClickCallback(c);
      });

      this._mesh = object.scene;

      this.setIsVisible(false);

      this.dispatchModelLoaded(this._mesh);

      this.reset();
    });
  }
  onHoverCallback = (mesh) => {
    this._params.hoverEvent({
      items: mesh.parent.children,
      source: this,
    });
  };
  onClickCallback = (c) => {
    let bookType = "";
    let githubUrl = "";
    let nugetUrl = "";
    let extraUrl = "";
    let point;

    if (c.parent.name === this._paypalNET.name) {
      bookType = "paypal.net";
      githubUrl = "https://www.github.com/liveordevtrying/paypal.net";
      nugetUrl = "https://www.nuget.org/packages/paypal.net";
      point = new THREE.Vector3(-2.980, 2.502, 0.607);
    } else if (c.parent.name === this._siteMapsNET.name) {
      bookType = "sitemaps.net";
      githubUrl = "https://www.github.com/liveordevtrying/sitemap.net";
      nugetUrl = "https://www.nuget.org/packages/sitemap.net";
      point = new THREE.Vector3(-2.991, 2.596, -0.145);
    } else if (c.parent.name === this._ntierNET.name) {
      bookType = "ntier.net";
      githubUrl = "https://www.github.com/liveordevtrying/ntier.net";
      nugetUrl = "https://www.nuget.org/packages/ntier.net";
      point = new THREE.Vector3(-2.947, 2.637, 0.276);
    } else if (c.parent.name === this._steamNET.name) {
      bookType = "steam.net";
      githubUrl = "https://www.github.com/liveordevtrying/steam.net";
      nugetUrl = "https://www.nuget.org/packages/steam.net";
      point = new THREE.Vector3(-2.976, 2.780, 1.101);
    } else if (c.parent.name === this._tcpNET.name) {
      bookType = "tcp.net";
      githubUrl = "https://www.github.com/liveordevtrying/tcp.net";
      nugetUrl = "https://www.nuget.org/packages/tcp.net";
      point = new THREE.Vector3(-2.960, 1.521, 0.600);
    } else if (c.parent.name === this._websocketsSimple.name) {
      bookType = "websocketssimple";
      githubUrl = "https://www.github.com/liveordevtrying/websocketssimple";
      nugetUrl = "https://www.nuget.org/packages/websocketssimple";
      point = new THREE.Vector3(-2.851, 1.379, 0.697);
    } else if (c.parent.name === this._oauthServerNET.name) {
      bookType = "oauthserver.net";
      githubUrl = "https://www.github.com/liveordevtrying/oauthserver.net";
      nugetUrl = "https://www.nuget.org/packages/oauthserver.net";
      extraUrl = "https://hub.docker.com/r/liveordevtrying/oauthserver.net";
      point = new THREE.Vector3(-2.930, 3.594, -1.282);
    } else if (c.parent.name === this._jwts.name) {
      bookType = "jwts";
      githubUrl = "https://www.github.com/liveordevtrying/jwts";
      nugetUrl = "https://www.nuget.org/packages/jwts";
      point = new THREE.Vector3(-2.962, 3.706, -1.306);
    } else if (c.parent.name === this._allieChat.name) {
      bookType = "allie.chat";
      githubUrl = "https://www.github.com/liveordevtrying/allie.chat";
      nugetUrl = "https://www.nuget.org/packages/allie.chat";
      extraUrl = "https://www.allie.chat";
      point = new THREE.Vector3(-2.929, 1.364, -1.421);
    } else if (c.parent.name === this._theMonitaur.name) {
      bookType = "themonitaur";
      githubUrl = "https://www.github.com/liveordevtrying/themonitaur";
      nugetUrl = "https://www.nuget.org/packages/themonitaur";
      extraUrl = "https://www.themonitaur.com";
      point = new THREE.Vector3(-2.958, 1.424, -0.845);
    } else if (c.parent.name === this._phsCore.name) {
      bookType = "phs.core";
      githubUrl = "https://www.github.com/liveordevtrying/phs.core";
      nugetUrl = "https://www.nuget.org/packages/phs.core";
      point = new THREE.Vector3(-2.897, 0.462, 0.149);
    } else if (c.parent.name === this._twitchBotsNET.name) {
      bookType = "twitchbots.net";
      githubUrl = "https://www.github.com/liveordevtrying/twitchbots.net";
      nugetUrl = "https://www.nuget.org/packages/twitchbots.net";
      point = new THREE.Vector3(-2.864, 0.421, 0.625);
    }

    this._params.clickEvent({
      items: [c],
      source: this,
      point,
      bookType,
      githubUrl,
      nugetUrl,
      extraUrl,
    });
  };
  selectBook = (book) => {
    this._selectedBook = book;
    book.parent.traverse((ch) => {
      ch.visible = false;
    });

    this.setCanBeSelected(false);
  };
  setCanBeSelected = (canBeSelected) => {
    this._canBeSelected = canBeSelected;

    if (this._canBeSelected && this._selectedBook) {
      this._selectedBook.parent.traverse((c) => {
        c.visible = true;
      });
      this._selectedBook = undefined;
    }
  };
  reset = () => {
    this._selectedBook = undefined;
    this.setCanBeSelected(false);
  }
  setIsVisible = (isVisible) => {
    this._mesh.visible = isVisible;
  }
  get canBeSelected() {
    return this._canBeSelected;
  }
  get paypalNET() {
    return this._paypalNET;
  }
  get allieChat() {
    return this._allieChat;
  }
  get jwts() {
    return this._jwts;
  }
  get oauthServerNET() {
    return this._oauthServerNET;
  }
  get nTierNET() {
    return this._ntierNET;
  }
  get siteMapsNET() {
    return this._siteMapsNET;
  }
  get steamNET() {
    return this._steamNET;
  }
  get tcpNET() {
    return this._tcpNET;
  }
  get websocketsSimple() {
    return this._websocketsSimple;
  }
  get theMonitaur() {
    return this._theMonitaur;
  }
  get phsCore() {
    return this._phsCore;
  }
  get twitchBotsNET() {
    return this._twitchBotsNET;
  }
}
export default BookshelfBooksGO;
