import BaseObject from "./BaseObject";
import * as THREE from "three";
import { EffectComposer } from "three/addons/postprocessing/EffectComposer.js";
import { RenderPass } from "three/addons/postprocessing/RenderPass.js";
//import { ShaderPass } from "three/addons/postprocessing/ShaderPass.js";
import { OutlinePass } from "three/addons/postprocessing/OutlinePass.js";
import { UnrealBloomPass } from "three/addons/postprocessing/UnrealBloomPass.js";

class PostProcessBO extends BaseObject {
  constructor(params) {
    super();
    this._params = params;

    this._composer = new EffectComposer(this._params.rendererBO.renderer);

    this._renderPass = new RenderPass(
      this._params.sceneBO.scene,
      this._params.cameraGO.camera
    );
    this._composer.addPass(this._renderPass);

    this._outlinePass = new OutlinePass(
      new THREE.Vector2(window.innerWidth, window.innerHeight),
      this._params.sceneBO.scene,
      this._params.cameraGO.camera
    );
    this._outlinePass.edgeGlow = 5;
    this._outlinePass.edgeThickness = 4;
    this._composer.addPass(this._outlinePass);

    this._bloomPass = new UnrealBloomPass(
      new THREE.Vector2(window.innerWidth, window.innerHeight), // Resolution of the scene
      0.175, // Intensity
      0.1, // Radius of the bloom
      0.05 // Determines which pixels emit the bloom
    );
    this._bloomPass.strength = 0.275;
    this._composer.addPass(this._bloomPass);

    // var instagramPass = new ShaderPass(InstagramFilter);
    // instagramPass.renderToScreen = true;
    // this._composer.addPass(instagramPass);

  }
  update(deltaTime) {
    this._composer.render(deltaTime);
  }
  get outlinePass() {
    return this._outlinePass;
  }
}
export default PostProcessBO;

export const InstagramFilter = {
  uniforms: {
    tDiffuse: { value: null },
    vignette: { value: 0.4 },
    exposure: { value: 1.4 },
    color: { value: new THREE.Color(1, 1, 1) }
  },

  vertexShader: [
    "varying vec2 vUv;",

    "void main() {",

    "vUv = uv;",
    "gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );",

    "}",
  ].join("\n"),

  fragmentShader: [
    "uniform sampler2D tDiffuse;",
    "uniform float vignette;",
    "uniform float exposure;",
    "uniform vec3 color;",
    "varying vec2 vUv;",

    "void main() {",

    "vec4 texel = texture2D( tDiffuse, vUv );",
    "vec2 p = vUv * 2.0 - 1.0;",
    "gl_FragColor = texel;",
    "gl_FragColor.xyz = pow(gl_FragColor.xyz*exposure, color);",
    "gl_FragColor.xyz *= clamp(1.0 - length(p) *vignette, 0.0, 1.0 );",

    "}",
  ].join("\n"),
};
