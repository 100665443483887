import State from "./State";

class AnimationState extends State {
  constructor(parent, animationType, loopType, crossfadeTime, timescale, duration) {
    super(parent);
    this._animationType = animationType;
    this._loopType = loopType;
    this._crossfadeTime = crossfadeTime;
    this._timescale = timescale;
    this._duration = duration;
  }
  get name() {
    return this._animationType;
  }
  enter = (prevState) => {
    this._action = this.parent.parent.animations[this._animationType].action;
    const mixer = this._action.getMixer();
    mixer.addEventListener("finished", this.finishedCallback);

    this._action.setLoop(this._loopType);
    this._action.reset();
    this._action.clampWhenFinished = true;
    this._action.time = 0.0;
    this._action.enabled = true;
    this._action.setEffectiveTimeScale(this._timescale ?? 1.0);
    this._action.setEffectiveWeight(1.0);

    if (this._duration) {
      this._action.setDuration(this._duration);
    }

    if (prevState) {
      const prevAction = this.parent.parent.animations[prevState.name].action;
      this._action.crossFadeFrom(prevAction, this._crossfadeTime, false);
    }

    this.parent.parent.onAnimationStarted(this._animationType);

    this._action.play();
  }
  finished = () => {
    this.cleanup();

    this.parent.parent.onAnimationCompleted(this._animationType);
  }
  cleanup = () => {
    this._action.paused = true;
    
    const action = this.parent.parent.animations[this._animationType].action;
    action.getMixer().removeEventListener("finished", this.finishedCallback);
  }
  exit = () => {
    this.cleanup();
  }
}
export default AnimationState;
