import { GLTFLoader } from "three/addons/loaders/GLTFLoader.js";
import { DRACOLoader } from "three/addons/loaders/DRACOLoader.js";
import BaseObject from "./BaseObject";
import RoomGO from "./GameObjects/RoomGO";
import MonitorGO from "./GameObjects/MonitorGO";
import ChairGO from "./GameObjects/ChairGO";
import BookshelfGO from "./GameObjects/BookshelfGO";
import BookshelfBooksGO from "./GameObjects/BookshelfBooksGO";
import BookGO from "./GameObjects/BookGO";
import SynthesizerGO from "./GameObjects/SynthesizerGO";
import TVGO from "./GameObjects/TVGO";
import ArtBackWallGO from "./GameObjects/ArtBackWallGO";
import ArtOpensourceGO from "./GameObjects/ArtOpensourceGO";
import CurtainsGO from "./GameObjects/CurtainsGO";
import RobGO from "./GameObjects/RobGO";
import ReyGO from "./GameObjects/ReyGO";
import FurnitureGO from "./GameObjects/FurnitureGO";
import TVIFrameGO from "./GameObjects/TVIFrameGO";
import MonitorIFrameGO from "./GameObjects/MonitorIFrameGO";
import PhoneGO from "./GameObjects/PhoneGO";
import PhoneIFrameGO from "./GameObjects/PhoneIFrameGO";

class GameObjectManagerBO extends BaseObject {
  constructor(params) {
    super();
    this._gameObjects = [];
    this._params = params;

    this.load(new GLTFLoader());
  }
  update(deltaTime) {
    for (let i = 0; i < this._gameObjects.length; i++) {
      this._gameObjects[i].update(deltaTime);
    }
  }
  load = (loader) => {
    this._gameObjects.push(
      new RoomGO({ loader: loader, disposeEvent: this.onDisposeEvent, modelLoadedEvent: this.onModelLoadedEvent })
    );

    this._gameObjects.push(
      new MonitorIFrameGO({ disposeEvent: this.onDisposeEvent, modelLoadedEvent: this.onModelLoadedEvent })
    );

    this._gameObjects.push(
      new MonitorGO({
        loader: loader,
        monitorIFrameGO: this._gameObjects.find(
          (x) => x instanceof MonitorIFrameGO
        ),
        hoverEvent: this.onHoverEvent,
        clickEvent: this.onClickEvent,
        actionEvent: this.onActionEvent,
        disposeEvent: this.onDisposeEvent, 
        modelLoadedEvent: this.onModelLoadedEvent
      })
    );

    this._gameObjects.push(
      new ChairGO({ loader: loader, disposeEvent: this.onDisposeEvent, modelLoadedEvent: this.onModelLoadedEvent })
    );

    this._gameObjects.push(
      new BookshelfGO({
        loader: loader,
        hoverEvent: this.onHoverEvent,
        clickEvent: this.onClickEvent,
        disposeEvent: this.onDisposeEvent, 
        modelLoadedEvent: this.onModelLoadedEvent
      })
    );

    this._gameObjects.push(
      new BookGO({
        loader: loader,
        hoverEvent: this.onHoverEvent,
        clickEvent: this.onClickEvent,
        movingObjectEvent: this.onMovingObjectEvent,
        modelLoadedEvent: this.onModelLoadedEvent,
        disposeEvent: this.onDisposeEvent,
      })
    );

    this._gameObjects.push(
      new BookshelfBooksGO({
        loader: loader,
        hoverEvent: this.onHoverEvent,
        clickEvent: this.onClickEvent,
        disposeEvent: this.onDisposeEvent, 
        modelLoadedEvent: this.onModelLoadedEvent,
      })
    );

    this._gameObjects.push(
      new SynthesizerGO({
        loader: loader,
        hoverEvent: this.onHoverEvent,
        clickEvent: this.onClickEvent, 
        modelLoadedEvent: this.onModelLoadedEvent,
        disposeEvent: this.onDisposeEvent,
      })
    );

    this._gameObjects.push(
      new TVIFrameGO({ disposeEvent: this.onDisposeEvent, modelLoadedEvent: this.onModelLoadedEvent })
    );

    this._gameObjects.push(
      new TVGO({
        loader: loader,
        tvIFrameGO: this._gameObjects.find((x) => x instanceof TVIFrameGO),
        hoverEvent: this.onHoverEvent,
        clickEvent: this.onClickEvent, 
        modelLoadedEvent: this.onModelLoadedEvent,
        actionEvent: this.onActionEvent,
        disposeEvent: this.onDisposeEvent,
      })
    );

    this._gameObjects.push(
      new ArtBackWallGO({
        loader: loader,
        hoverEvent: this.onHoverEvent,
        clickEvent: this.onClickEvent,
        movingObjectEvent: this.onMovingObjectEvent, 
        modelLoadedEvent: this.onModelLoadedEvent,
        disposeEvent: this.onDisposeEvent,
      })
    );

    this._gameObjects.push(
      new ArtOpensourceGO({
        loader: loader,
        hoverEvent: this.onHoverEvent,
        clickEvent: this.onClickEvent,
        movingObjectEvent: this.onMovingObjectEvent, 
        modelLoadedEvent: this.onModelLoadedEvent,
        disposeEvent: this.onDisposeEvent,
      })
    );

    this._gameObjects.push(
      new FurnitureGO({
        loader: loader,
        disposeEvent: this.onDisposeEvent, 
        modelLoadedEvent: this.onModelLoadedEvent,
      })
    );

    this._gameObjects.push(
      new CurtainsGO({
        loader: loader,
        isDay: this._params.isDay,
        hoverEvent: this.onHoverEvent,
        clickEvent: this.onClickEvent,
        disposeEvent: this.onDisposeEvent, 
        modelLoadedEvent: this.onModelLoadedEvent,
      })
    );

    this._gameObjects.push(
      new ReyGO({
        loader: loader,
        hoverEvent: this.onHoverEvent,
        clickEvent: this.onClickEvent,
        disposeEvent: this.onDisposeEvent, 
        actionEvent: this.onActionEvent,
        modelLoadedEvent: this.onModelLoadedEvent,
      })
    );

    this._gameObjects.push(
      new PhoneIFrameGO({
        cameraGO: this._params.cameraGO,
        recaptchaRef: this._params.recaptchaRef,
        disposeEvent: this.onDisposeEvent, 
        modelLoadedEvent: this.onModelLoadedEvent,
      })
    );

    this._gameObjects.push(
      new PhoneGO({
        loader: loader,
        hoverEvent: this.onHoverEvent,
        clickEvent: this.onClickEvent,
        movingObjectEvent: this.onMovingObjectEvent,
        disposeEvent: this.onDisposeEvent, 
        modelLoadedEvent: this.onModelLoadedEvent,
      })
    );
    
    this._gameObjects.push(
      new RobGO({
        camera: this._params.cameraGO.camera,
        loader: loader,
        actionEvent: this.onActionEvent,
        disposeEvent: this.onDisposeEvent, 
        modelLoadedEvent: this.onModelLoadedEvent,
        movingObjectEvent: this.onMovingObjectEvent
      })
    );
  };
  onHoverEvent = (eventData) => {
    this._params.hoverEvent(eventData);
  };
  onClickEvent = (eventData) => {
    this._params.clickEvent(eventData);
  };
  onMovingObjectEvent = (eventData) => {
    this._params.movingObjectEvent(eventData);
  };
  onActionEvent = (eventData) => {
    this._params.actionEvent(eventData);
  };
  onModelLoadedEvent = (eventData) => {
    this._params.modelLoadedEvent(eventData);
  };
  onDisposeEvent = (eventData) => {
    this._params.disposeEvent(eventData);
  };
  dispose() {
    for (let i = 0; i < this._gameObjects.length; i++) {
      try {
        this._gameObjects[i].dispose();
      } catch {}
    }
  }
  get gameObjects() {
    return this._gameObjects;
  }
}
export default GameObjectManagerBO;
