export const RobAnimationType = {
    typing: "typing",
    sitting: "sitting",
    sittingLoop: "sittingLoop",
    sittingIdle: "sittingIdle",
    sittingClap: "sittingClap",
    sittingCheer: "sittingCheer",
    sittingLaughing: "sittingLaughing",
    sittingDisapproval: "sittingDisapproval",
    sittingDisbelief: "sittingDisbelief",
    sittingRubbingArm: "sittingRubbingArm",
    sittingGunMotion: "sittingGunMotion",
    sittingAngry: "sittingAngry",
    sittingVictory: "sittingVictory",
    typeToSit: "typeToSit",
    sitToType: "sitToType",
    piano: "piano",
    pianoSlow: "pianoSlow"
}

export const ReyAnimationType = {
    idle0: "idle0",
    idle1: "idle1",
    idle2: "idle2",
    idle3: "idle3",
    idleEnd: "idleEnd",
    idleLoop: "idleLoop",
    idleStart: "idleStart",
    lieLoop: "lieLoop",
    lieLoop1: "lieLoop1",
    lieEnd: "lieEnd",
    lieSleepEnd: "lieSleepEnd",
    lieSleepLoop: "lieSleepLoop",
    lieSleepStart: "lieSleepStart",
    lieStart: "lieStart",
    sittingEnd: "sittingEnd",
    sittingLoop: "sittingLoop",
    sittingStart: "sittingStart",
    jump: "jump",
    attack: "attack",
    swipe: "swipe",
    swipe2: "swipe2",
    diggingStart: "diggingStart",
    diggingLoop: "diggingLoop",
    diggingEnd: "diggingEnd"
}