import * as THREE from "three";
import { Object3D } from "three";
import BaseObject from "./BaseObject";

class LightManagerBO extends BaseObject {
  constructor(params) {
    super();
    this._params = params;
    this._lights = [];
    
    this.createLights();
    // this.createHelpers();
  }
  createLights = () => {
    const pointLight0 = new THREE.PointLight({ color: 0xffffff });
    pointLight0.position.set(0.825, 5.45, 1.25);
    pointLight0.castShadow = true;
    pointLight0.power = 20;
    pointLight0.distance = 20;
    pointLight0.decay = 2;
    pointLight0.shadow.bias = -0.005;
    this._lights.pointLight0 = pointLight0;

    const pointLight1 = new THREE.PointLight({ color: 0xffffff });
    pointLight1.position.set(10.75, 5.45, 1.25);
    pointLight1.castShadow = true;
    pointLight1.power = 15;
    pointLight1.distance = 15;
    pointLight1.decay = 2;
    pointLight1.shadow.bias = -0.005;
    this._lights.pointLight1 = pointLight1;

    const spotlightSun = new THREE.SpotLight({ color: 0xffffff });
    spotlightSun.position.set(-10, 5.45, 1.25);
    spotlightSun.castShadow = true;
    spotlightSun.intensity = 0.25;
    spotlightSun.decay = 2;
    spotlightSun.shadow.bias = -0.005;
    this._lights.spotlightSun = spotlightSun;

    // Computer monitor spotlight
    const spotLight1 = new THREE.SpotLight({color: new THREE.Color(0.7625, 0.750, 1)});
    spotLight1.position.set(10.5, 3.45, -3.25);
    spotLight1.castShadow = true;
    spotLight1.power = 8;
    spotLight1.distance = 8;
    spotLight1.decay = 2;
    spotLight1.shadow.bias = -0.001;
    spotLight1.penumbra = .35;

    const target = new Object3D();
    target.position.set(10, 3, 20);
    target.updateWorldMatrix();
    spotLight1.target = target;
    this._lights.spotLight1 = spotLight1;

    this._params.sceneBO.scene.add(pointLight0, pointLight1, spotlightSun, spotLight1);
  };
  createHelpers = () => {
    const gridHelper = new THREE.GridHelper(200, 50);
    const axisHelper = new THREE.AxesHelper(5);
    const lightHelper0 = new THREE.PointLightHelper(this._lights.pointLight0);
    const lightHelper1 = new THREE.PointLightHelper(this._lights.pointLight1);
    const spotlightSunHelper = new THREE.SpotLightHelper(
      this._lights.spotlightSun
    );
    const spotlight1Helper = new THREE.SpotLightHelper(
      this._lights.spotLight1
    );
    this._params.sceneBO.scene.add(
      gridHelper,
      axisHelper,
      lightHelper0,
      lightHelper1,
      spotlightSunHelper,
      spotlight1Helper
    );
  };
}
export default LightManagerBO;
