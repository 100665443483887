import BaseObject from "../BaseObject";

class GameObject extends BaseObject {
  constructor(params) {
    super();
    this._params = params;

    this.loadModels();
  }
  loadModels() {}
  dispatchMovingEvent = (moving, state) => {
    this._params.movingObjectEvent({moving, state});
  }
  dispatchModelLoaded(target) {
    this._params.modelLoadedEvent({source: this, target});
  }
  setIsVisible(isVisible) {
    this._mesh.visible = isVisible;
  }
  dispose() {
    this._params.disposeEvent({source: this, mesh: this._mesh});

    if (this._mesh) {
      for (let i = 0; i < this._mesh.children; i++) {
        if (this._mesh.children[i]) {
          this._mesh.children[i].geometry.dispose();
          this._mesh.children[i].material.dispose();
          this._mesh.children[i] = undefined;
        }
      }
    }
  }
  get mesh() {
    return this._mesh;
  }
}
export default GameObject;
