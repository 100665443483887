import "./App.scss";
import React from "react";
import { SceneType } from "./enums";
import GameManager from "./GameManager";
import ClipLoader from "react-spinners/ClipLoader";
import ReCAPTCHA from "react-google-recaptcha";
import Menu from "./Menu";
import VolumeControls from "./VolumeControls";
import TitleText from "./TitleText";
import ReactGA from "react-ga4";
import { GOOGLE_ANALYTICS_TRACKING_ID, RECAPTCHA_KEY, WEBAPI_URI } from "./env";
import GemWars from "./GemWars";
import WebGL from 'three/examples/jsm/capabilities/WebGL';

class App extends React.Component {
  constructor() {
    super();

    this.state = {
      loading: true,
      volume: localStorage.getItem("volume") ?? 50,
      muted: localStorage.getItem("mute") === "true",
      recaptchaRef: React.createRef(),
      menu: React.createRef(),
      titleText: React.createRef(),
      loadGemWars: false,
    };
  }
  componentDidMount = async () => {
    ReactGA.initialize(GOOGLE_ANALYTICS_TRACKING_ID);

    ReactGA.send({ hitType: "pageview", page: window.location.hostname });

    if ( WebGL.isWebGLAvailable() ) {
      this._isLooping = true;
      this.setState({
        gameManager: new GameManager({
          sceneType: SceneType.typing,
          isDay: true,
          recaptchaRef: this.state.recaptchaRef,
          muted: this.state.muted,
          volume: this.state.volume,
          navigationEvent: this.onNavigationEvent,
          hoverEvent: this.state.menu.current.onObjectHoverEvent,
          titleText: this.state.titleText.current,
          loadGemWars: this.onLoadGemWars,
          backClicked: this.onBackClicked,
        }),
      });
      this.animate();
    } else {
      document.getElementById('content').innerHTML = WebGL.getWebGLErrorMessage();
      ReactGA.event("WebGL not supported");
    }

    try {
      await fetch(`${WEBAPI_URI}/Load`, {
        method: "get",
        mode: "cors",
      });
    } catch {}
  };
  componentWillUnmount = () => {
    this._isLooping = false;

    if (this.state.gameManager) {
      this.state.gameManager.dispose();
    }
  };
  animate = (now) => {
    if (this._isLooping) {
      now *= 0.001;
      const deltaTime = now - this.then;
      this.then = now;

      if (this.state.gameManager) {
        this.state.gameManager.update(deltaTime);
      }

      requestAnimationFrame(this.animate);
    }
  };
  onRecaptchaChanged = (token) => {
    this.state.gameManager.onRecaptchaChanged(token);
  };
  onNavigationEvent = (location) => {
    if (this.state.loading) {
      this.setState({ location, loading: false });
      document.getElementById("canvas").classList.add("fade-in");
    } else {
      this.setState({ location });
    }
  };
  onButtonHover = (eventArgs) => {
    this.state.gameManager.onButtonHover(eventArgs);
  };
  onButtonClick = (eventArgs) => {
    this.state.gameManager.onButtonClick(eventArgs);
  };
  onButtonLeave = () => {
    this.state.gameManager.onButtonLeave();
  };
  setMuted = (muted) => {
    this.setState({ muted });
    this.state.gameManager.onMuteClicked(muted);
  };
  setVolume = (volume) => {
    this.setState({ volume });
    this.state.gameManager.onVolumeChanged(volume);
  };
  onLoadGemWars = () => {
    if (!this.state.loadGemWars) {
      this.setState({ loadGemWars: true, loading: true });
    }
    document.getElementById("gemwars-container").classList.remove("hidden");
  };
  onLoadedGemWars = () => {
    this.setState({ loading: false });
  };
  onBackClicked = () => {
    this.state.gameManager.setIsPlayingGame(false);
    document.getElementById("gemwars-container").classList.add("hidden");
  };
  onRestartClicked = () => {
    this.setState({ loading: true });
  };
  gemWars = () => {
    if (this.state.loadGemWars) {
      return (
        <div id="gemwars-container">
          <GemWars
            backClicked={this.onBackClicked}
            restartClicked={this.onRestartClicked}
            loaded={this.onLoadedGemWars}
          />
          ;
        </div>
      );
    } else {
      return <div id="gemwars-container"></div>;
    }
  };
  render = () => {
    return (
      <div id="App" className="App">
        <video
          id="tintb_video"
          playsInline
          loop
          src={"Videos/TINTB.mp4"}
        ></video>

        <ReCAPTCHA
          ref={this.state.recaptchaRef}
          size={"invisible"}
          sitekey={RECAPTCHA_KEY}
          onChange={this.onRecaptchaChanged}
        />

        {this.gemWars()}

        <div id="content" className="content">
          <TitleText ref={this.state.titleText} loading={this.state.loading} />

          <Menu
            ref={this.state.menu}
            location={this.state.location}
            gameManager={this.state.gameManager}
            buttonHover={this.onButtonHover}
            buttonClick={this.onButtonClick}
            buttonLeave={this.onButtonLeave}
          />

          <div className="loader_container">
            <ClipLoader
              color={"#FFFFFF"}
              loading={this.state.loading}
              size={45}
              aria-label="Loading Spinner"
              data-testid="loader"
            />

            <VolumeControls
              loading={this.state.loading}
              muted={this.state.muted}
              volume={this.state.volume}
              setMuted={this.setMuted}
              setVolume={this.setVolume}
            />
          </div>
        </div>

        <div id="css">
          <canvas id="canvas"></canvas>
        </div>
      </div>
    );
  };
}

export default App;
