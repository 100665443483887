import BaseObject from "./BaseObject";
import {CSS3DRenderer} from "three/examples/jsm/renderers/CSS3DRenderer";

class RendererCSSBO extends BaseObject {
  constructor(params) {
    super();
    this._params = params;

    this._renderer = new CSS3DRenderer();
    this._renderer.setSize(window.innerWidth, window.innerHeight);
    this._renderer.domElement.style.position = "absolute";
    this._renderer.domElement.style.top = 0;
    document.getElementById("css").appendChild(this._renderer.domElement);
  }
  update(deltaTime) {
    this._renderer.render(this._params.sceneBO.scene, this._params.cameraGO.camera);
  }
  get renderer() {
    return this._renderer;
  }
}
export default RendererCSSBO;
