import * as THREE from "three";

class PaintingGoldBunnyStore {
  constructor(mesh) {
    this._mesh = mesh;
    this._startingPosition = new THREE.Vector3(-1.95, 5.65, 6.15);
    this._startingLookAt = new THREE.Vector3(-1.95, 5.65, -20);

    this._mesh.position.copy(this._startingPosition);
    this._mesh.lookAt(this._startingLookAt);

    this._mesh.traverse((x) => {
      if (x instanceof THREE.Mesh) {
        switch (x.material.name) {
          case "GoldBunnyStore_PaintingTexture":
            this._paintingTexture = x;
            this._paintingMaterialOrig = x.material;
            this._paintingMaterial = new THREE.MeshBasicMaterial({
              map: x.material.map,
              name: x.name,
            });
            break;
          case "VisitTheSite":
            this._visitTheSite = x;
            x.visible = false;

            const mat = new THREE.MeshBasicMaterial({
              map: x.material.map,
              name: x.name,
            });
            x.material.dispose();
            x.material = mat;
            break;
          default:
            break;
        }
      }
    });
  }
  setLinksActive = (isActive) => {
    this._visitTheSite.visible = isActive;
  };
  setMaterial = (isShowingPaining) => {
    this._paintingTexture.material = isShowingPaining ? this._paintingMaterial : this._paintingMaterialOrig;
  };
  getLinkUrl = (mesh) => {
    switch (mesh.name) {
      case this._visitTheSite.name:
        return "https://www.goldbunnystore.com";
      default:
        break;
    }
  };
  get name() {
    return "GoldBunnyStore";
  }
  get mesh() {
    return this._mesh;
  }
  get visitTheSite() {
    return this._visitTheSite;
  }
  get startingPosition() {
    return this._startingPosition;
  }
  get startingLookAt() {
    return this._startingLookAt;
  }
}
export default PaintingGoldBunnyStore;
