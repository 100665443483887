import * as THREE from "three";
import PaintingGitHub from "./Paintings/PaintingGitHub";
import PaintingNuGet from "./Paintings/PaintingNuGet";
import ArtBackWallGO from "./ArtBackWallGO";
import ArtOpensource from "../../Assets/Models/ArtOpensource.glb";

class ArtOpensourceGO extends ArtBackWallGO {
  loadModels() {
    this._paintings = [];

    this._params.loader.load(ArtOpensource, (object) => {
      object.scene.traverse((c) => {
        c.receiveShadow = true;
        c.castShadow = true;

        if (c instanceof THREE.Group) {
          if (c.name === "GitHub") {
            this._paintings.push(new PaintingGitHub(c));
          } else if (c.name === "NuGet") {
            this._paintings.push(new PaintingNuGet(c));
          }
        }

        c.hoverCallback = () =>
        !this._isZoomedIn
          ? this.hoverCallback(object.scene.children)
          : this.hoverCallback(!this._activeObject ? c.parent.children : [c]);
        c.clickCallback = () =>
          !this._isZoomedIn
            ? this.clickCallback(object.scene.children)
            : this.clickCallback(!this._activeObject ? c.parent.children : [c]);
      });

      this._mesh = object.scene;

      this.setIsVisible(false);

      this.dispatchModelLoaded(this._mesh);

      this.reset();
    });
  }
  getPainting(object) {
    if (object.parent.name === "GitHub") {
      return this._paintings.find(x => x instanceof PaintingGitHub);
    } else if (object.parent.name === "NuGet") {
      return this._paintings.find(x => x instanceof PaintingNuGet);
    }
  }
  getPaintingZoomedLocation(cameraGO) { 
    return {
      x: cameraGO.camera.position.x,
      y: cameraGO.camera.position.y,
      z: cameraGO.camera.position.z - 2,
      fov: 90
    };
  }
}
export default ArtOpensourceGO;
