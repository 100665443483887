import { CSS3DObject } from "three/examples/jsm/renderers/CSS3DRenderer.js";
import * as THREE from "three";
import GameObject from "./GameObject";

class TVIFrameGO extends GameObject {
  loadModels() {
    this._object3d = new THREE.Object3D();

    var div = document.createElement("div");
    div.style.width = 1240 + "px";
    div.style.height = 740 + "px";
    div.style.backgroundColor = "#000";

    this._iframe = document.createElement("iframe");
    this._iframe.id = "tv_iframe";
    this._iframe.style.width = 1240 + "px";
    this._iframe.style.height = 740 + "px";
    this._iframe.style.border = "0px";
    this._iframe.src = ""; 

    div.appendChild(this._iframe);

    this._domObject = new CSS3DObject(div);
    this._domObject.position.set(0.8125, 3.98, -3);
    this._domObject.scale.setScalar(0.0045);

    this._object3d.add(this._domObject);

    var material = new THREE.MeshPhongMaterial({
      opacity: 0.2,
      color: new THREE.Color("black"),
      blending: THREE.NoBlending,
      side: THREE.DoubleSide,
    });
    
    var geometry = new THREE.PlaneGeometry(1240, 740);
    this._mesh = new THREE.Mesh(geometry, material);
    this._mesh.position.copy(this._domObject.position);
    this._mesh.scale.copy(this._domObject.scale);
    this._mesh.lookAt(new THREE.Vector3(0.8125, 3.98, 20));
    this._domObject.rotation.copy(this._mesh.rotation);
    
    this._mesh.castShadow = false;
    this._mesh.receiveShadow = true;

    this.setIsVisible(false);

    setTimeout(() => {
      this.dispatchModelLoaded(this._object3d);
      this.dispatchModelLoaded(this._mesh);
    }, 1);
  }
  setIFrame(src) {
    this._iframe.src = src;
    this.setIsVisible(src && src !== "about:blank");
  }
  update(deltaTime) {
  }
  setIsVisible(visible) {
    this._domObject.visible = visible;
    this._mesh.visible = visible;
  }
  reset = () => {
    this.setIsVisible(false);
  }
}
export default TVIFrameGO;
