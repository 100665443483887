import * as THREE from "three";
import * as TWEEN from "@tweenjs/tween.js";
import GameObject from "./GameObject";
import RobFSM from "./StateMachines/RobFSM";
import { RobAnimationType } from "./StateMachines/States/enums";
import Rob from "../../Assets/Models/Rob.glb";

class RobGO extends GameObject {
  constructor(params) {
    super(params);
    // this._input = new BasicCharacterControllerInput();
    this.stateMachine = new RobFSM(this);
    this.animations = [];
    this._timers = [];
    this._elapsedTime = 0;

    this.start();
  }
  start = () => {
    this._params.loader.load(Rob, (object) => {
      object.scene.traverse((c) => {
        c.castShadow = true;
        c.receiveShadow = true;
      });
      object.scene.position.set(9.9, 0.1, 0);
      object.scene.scale.setScalar(1.375);
      object.scene.rotation.y = Math.PI;

      this._mesh = object.scene;
      this._lastMonitorChange = 0;

      this.setIsVisible(false);

      this.mixer = new THREE.AnimationMixer(this._mesh);

      for (let i = 0; i < object.animations.length; i++) {
        const clip = object.animations[i];
        const action = this.mixer.clipAction(clip);

        const animationName = clip.name;
        if (animationName === RobAnimationType.sitting) {
          this.animations[RobAnimationType.sitting] = {
            clip: clip,
            action: action,
          };

          this.animations[RobAnimationType.sittingLoop] = {
            clip: clip,
            action: action,
          };
        } else if (animationName === RobAnimationType.piano) {
          this.animations[RobAnimationType.piano] = {
            clip: clip,
            action: action,
          };

          this.animations[RobAnimationType.pianoSlow] = {
            clip: clip,
            action: action,
          };
        } else {
          this.animations[animationName] = {
            clip: clip,
            action: action,
          };
        }
      }

      this.dispatchModelLoaded(this._mesh);
    });
  };
  update = (deltaTime) => {
    if (!this._mesh) {
      return;
    }

    if (this.tween) {
      this.tween.update();
    }

    this._elapsedTime += deltaTime;

    this.stateMachine.update(deltaTime);
    this.mixer.update(deltaTime);

    // Change monitor
    if (
      this._timers.length > 0 &&
      this._timers[0].state === "typing" &&
      this._elapsedTime > this._lastMonitorChange
    ) {
      this._lastMonitorChange = this._elapsedTime + 3 + Math.random() * 5;

      this.dispatchActionEvent({
        action: "typing",
      });
    }

    for (let i = 0; i < this._timers.length; i++) {
      if (
        this._elapsedTime - this._timers[i].startTime >=
        this._timers[i].timeLimit
      ) {
        this.onTimerComplete(this._timers[i].state);

        this._timers = [
          ...this._timers.slice(0, this._timers.indexOf(this._timers[i])),
          ...this._timers.slice(this._timers.indexOf(this._timers[i]) + 1),
        ];
      }
    }
  };
  reset = () => {};
  setState = (state, force) => {
    if (state !== this._state || force) {
      this._state = state;

      switch (state) {
        case "movingToPiano":
          this.stateMachine.setState(RobAnimationType.typeToSit);
          break;
        case "startPlayingPiano":
          this.stateMachine.setState(RobAnimationType.sitToType);
          break;
        case "piano":
          this.stateMachine.setState(RobAnimationType.piano);
          break;
        case "pianoSlow":
          this.stateMachine.setState(RobAnimationType.pianoSlow);
          break;
        case "movingToComputer":
          this.stateMachine.setState(RobAnimationType.sittingVictory);
          break;
        case "movingToComputerSad":
          this.stateMachine.setState(RobAnimationType.sittingDisbelief);
          break;
        case "startingToTypeComputer":
          this.stateMachine.setState(RobAnimationType.sitToType);
          break;
        case "computer":
          this._animationState = RobAnimationType.typing;
          this._lastMonitorChange = this._elapsedTime + 3 + Math.random() * 4;

          this._timers.push({
            timeLimit: Math.random() * 15 + 5,
            startTime: this._elapsedTime,
            state: "typing",
          });

          if (this._isSitting) {
            this.stateMachine.setState(RobAnimationType.sitToType);
          } else {
            this.stateMachine.setState(RobAnimationType.typing);
          }
          break;
        case "idle":
          if (this._isSitting) {
            this.stateMachine.setState(RobAnimationType.typeToSit);
          } else {
            this.stateMachine.setState(RobAnimationType.sittingIdle);
          }
          break;
        default:
          break;
      }
    }
  };
  onAnimationStarted = (animationName) => {
    if (animationName === RobAnimationType.sittingClap) {
      this.dispatchActionEvent({
        action: "playClapping",
      });
    }
  };
  onAnimationCompleted = (animationName) => {
    switch (this._state) {
      case "idle":
        if (animationName !== RobAnimationType.sitting) {
          this._isSitting = true;
          this.stateMachine.setState(RobAnimationType.sitting);
        } else {
          const nextAction = Math.random();

          if (nextAction > 0.4) {
            this.stateMachine.setState(RobAnimationType.sittingIdle);
          } else if (nextAction > 0.1) {
            this.stateMachine.setState(RobAnimationType.sittingRubbingArm);
          } else {
            this.stateMachine.setState(RobAnimationType.sittingGunMotion);
          }
        }
        break;
      case "movingToPiano":
        if (animationName === RobAnimationType.typeToSit) {
          this.dispatchActionEvent({
            action: "moveToPiano",
          });
        }
        break;
      case "startPlayingPiano":
        if (animationName === RobAnimationType.sitToType) {
          this.dispatchActionEvent({
            action: "playPiano",
          });
        }
        break;
      case "movingToComputer":
        if (animationName === RobAnimationType.sittingVictory) {
          this.dispatchActionEvent({
            action: "moveToComputer",
          });
          this.stateMachine.setState(RobAnimationType.sittingLoop);
        }
        break;
      case "movingToComputerSad":
        if (animationName === RobAnimationType.sittingDisbelief) {
          this.dispatchActionEvent({
            action: "moveToComputer",
          });
          this.stateMachine.setState(RobAnimationType.sittingLoop);
        }
        break;
      case "startingToTypeComputer":
        if (animationName === RobAnimationType.sitToType) {
          this.setState("computer");
        }
        break;
      case "computer":
        if (this._animationState) {
          switch (this._animationState) {
            case RobAnimationType.typing:
              switch (animationName) {
                case RobAnimationType.sitToType:
                  this._isSitting = false;
                  this.stateMachine.setState(RobAnimationType.typing);
                  break;
                default:
                  break;
              }
              break;
            case RobAnimationType.sittingIdle:
              switch (animationName) {
                case RobAnimationType.typeToSit:
                  this.stateMachine.setState(RobAnimationType.sittingIdle);
                  break;
                case RobAnimationType.sittingIdle:
                  this.stateMachine.setState(RobAnimationType.sitting);
                  break;
                case RobAnimationType.sitting:
                  this._isSitting = true;
                  this.playNextAnimation();
                  break;
                default:
                  break;
              }
              break;
            case RobAnimationType.sittingClap:
              switch (animationName) {
                case RobAnimationType.sittingClap:
                  this.stateMachine.setState(RobAnimationType.sitting);
                  break;
                case RobAnimationType.sitting:
                  this._isSitting = true;
                  this.playNextAnimation();
                  break;
                default:
                  break;
              }
              break;
            case RobAnimationType.sittingCheer:
              switch (animationName) {
                case RobAnimationType.sittingCheer:
                  this.stateMachine.setState(RobAnimationType.sitting);
                  break;
                case RobAnimationType.sitting:
                  this._isSitting = true;
                  this.playNextAnimation();
                  break;
                default:
                  break;
              }
              break;
            case RobAnimationType.sittingLaughing:
              switch (animationName) {
                case RobAnimationType.typeToSit:
                  this.stateMachine.setState(RobAnimationType.sittingLaughing);
                  break;
                case RobAnimationType.sittingLaughing:
                  this.stateMachine.setState(RobAnimationType.sitting);
                  break;
                case RobAnimationType.sitting:
                  this._isSitting = true;
                  this.playNextAnimation();
                  break;
                default:
                  break;
              }
              break;
            case RobAnimationType.sittingDisapproval:
              switch (animationName) {
                case RobAnimationType.sittingDisapproval:
                  this.stateMachine.setState(RobAnimationType.sitting);
                  break;
                case RobAnimationType.sitting:
                  this._isSitting = true;
                  this.playNextAnimation();
                  break;
                default:
                  break;
              }
              break;
            case RobAnimationType.sittingDisbelief:
              switch (animationName) {
                case RobAnimationType.typeToSit:
                  this.stateMachine.setState(RobAnimationType.sittingDisbelief);
                  break;
                case RobAnimationType.sittingDisbelief:
                  this.stateMachine.setState(RobAnimationType.sitting);
                  break;
                case RobAnimationType.sitting:
                  this._isSitting = true;
                  this.playNextAnimation();
                  break;
                default:
                  break;
              }
              break;
            case RobAnimationType.sittingRubbingArm:
              switch (animationName) {
                case RobAnimationType.sittingRubbingArm:
                  this.stateMachine.setState(RobAnimationType.sitting);
                  break;
                case RobAnimationType.sitting:
                  this._isSitting = true;
                  this.playNextAnimation();
                  break;
                default:
                  break;
              }
              break;
            case RobAnimationType.sittingGunMotion:
              switch (animationName) {
                case RobAnimationType.sittingGunMotion:
                  this.stateMachine.setState(RobAnimationType.sitting);
                  break;
                case RobAnimationType.sitting:
                  this._isSitting = true;
                  this.playNextAnimation();
                  break;
                default:
                  break;
              }
              break;
            case RobAnimationType.sittingAngry:
              switch (animationName) {
                case RobAnimationType.sittingAngry:
                  this.stateMachine.setState(RobAnimationType.sitting);
                  break;
                case RobAnimationType.sitting:
                  this._isSitting = true;
                  this.playNextAnimation();
                  break;
                default:
                  break;
              }
              break;
            default:
              break;
          }
        } else {
          this.playNextAnimation();
        }
        break;
      default:
        break;
    }
  };
  playNextAnimation = () => {
    if (!this._state) {
      this._state = "computer";
    }

    if (Math.random() > 0.85) {
      this.onTimerComplete("typing");
    } else {
      this.setState("computer", true);
    }
  };
  onTimerComplete = (state) => {
    switch (state) {
      case "typing":
        if (this._state === "computer") {
          if (Math.random() > 0.5) {
            this.setState("computer", true);
          } else {
            switch (Math.ceil(Math.random() * 9)) {
              case 1:
                this._animationState = RobAnimationType.sittingIdle;

                if (this._isSitting) {
                  this._isSitting = false;
                  this.stateMachine.setState(RobAnimationType.sittingIdle);
                } else {
                  this.stateMachine.setState(RobAnimationType.typeToSit);
                }
                break;
              case 2:
                this._animationState = RobAnimationType.sittingClap;
                this.stateMachine.setState(RobAnimationType.sittingClap);
                break;
              case 3:
                this._animationState = RobAnimationType.sittingCheer;
                this.stateMachine.setState(RobAnimationType.sittingCheer);
                break;
              case 4:
                this._animationState = RobAnimationType.sittingLaughing;

                if (this._isSitting) {
                  this._isSitting = false;
                  this.stateMachine.setState(RobAnimationType.sittingLaughing);
                } else {
                  this.stateMachine.setState(RobAnimationType.typeToSit);
                }
                break;
              case 5:
                this._animationState = RobAnimationType.sittingDisapproval;
                this.stateMachine.setState(RobAnimationType.sittingDisapproval);
                break;
              case 6:
                this._animationState = RobAnimationType.sittingDisbelief;

                if (this._isSitting) {
                  this._isSitting = false;
                  this.stateMachine.setState(RobAnimationType.sittingDisbelief);
                } else {
                  this.stateMachine.setState(RobAnimationType.typeToSit);
                }
                break;
              case 7:
                this._animationState = RobAnimationType.sittingRubbingArm;
                this.stateMachine.setState(RobAnimationType.sittingRubbingArm);
                break;
              case 8:
                this._animationState = RobAnimationType.sittingGunMotion;
                this.stateMachine.setState(RobAnimationType.sittingGunMotion);
                break;
              default:
                this._animationState = RobAnimationType.sittingAngry;
                this.stateMachine.setState(RobAnimationType.sittingAngry);
                break;
            }
          }
        }
        break;
      default:
        break;
    }
  };
  robMoveToSynthesizer = (chairGO) => {
    this.dispatchMovingEvent(true, "synthesizerRob");

    this.tween = new TWEEN.Tween({
      robX: this._mesh.position.x,
      robY: this._mesh.position.y,
      robZ: this._mesh.position.z,
      chairX: chairGO.mesh.position.x,
      chairY: chairGO.mesh.position.y,
      chairZ: chairGO.mesh.position.z,
    })
      .to(
        {
          robX: 7.6,
          robY: 0.1,
          robZ: -0.25,
          chairX: 7.4,
          chairY: 0.2,
          chairZ: -0.25,
        },
        1000
      )
      .easing(TWEEN.Easing.Quadratic.Out);

    this._initQuat = new THREE.Quaternion();
    this._initQuat.copy(this._mesh.quaternion);

    const destQuat = new THREE.Quaternion();
    this._mesh.rotation.y = Math.PI / -2;
    destQuat.copy(this._mesh.quaternion);

    this._initChairQuat = new THREE.Quaternion();
    this._initChairQuat.copy(chairGO.mesh.quaternion);

    const destChairQuat = new THREE.Quaternion();
    chairGO.mesh.rotation.y = Math.PI / 2;
    destChairQuat.copy(chairGO.mesh.quaternion);

    this.tween.onUpdate((object, elapsed) => {
      this._mesh.position.set(object.robX, object.robY, object.robZ);
      chairGO.mesh.position.set(object.chairX, object.chairY, object.chairZ);

      this._mesh.quaternion.slerpQuaternions(this._initQuat, destQuat, elapsed);
      this._mesh.quaternion.normalize();

      chairGO.mesh.quaternion.slerpQuaternions(
        this._initChairQuat,
        destChairQuat,
        elapsed
      );
      chairGO.mesh.quaternion.normalize();
    });

    this.tween.onComplete((object) => {
      this.dispatchMovingEvent(false, "synthesizerRob");

      this._mesh.quaternion.copy(destQuat);
      this._mesh.quaternion.normalize();

      chairGO.mesh.quaternion.copy(destChairQuat);
      chairGO.mesh.quaternion.normalize();

      this.tween = undefined;
    });

    this.tween.start();
  };
  robMoveToComputer = (chairGO) => {
    this.dispatchMovingEvent(true, "computerRob");

    this.tween = new TWEEN.Tween({
      robX: this._mesh.position.x,
      robY: this._mesh.position.y,
      robZ: this._mesh.position.z,
      chairX: chairGO.mesh.position.x,
      chairY: chairGO.mesh.position.y,
      chairZ: chairGO.mesh.position.z,
    })
      .to(
        {
          robX: 9.9,
          robY: 0.1,
          robZ: 0,
          chairX: 9.9,
          chairY: 0.2,
          chairZ: 0,
        },
        1000
      )
      .easing(TWEEN.Easing.Quadratic.Out);

    const initQuat = new THREE.Quaternion();
    initQuat.copy(this._mesh.quaternion);

    const initChair = new THREE.Quaternion();
    initChair.copy(chairGO.mesh.quaternion);

    this.tween.onUpdate((object, elapsed) => {
      this._mesh.position.set(object.robX, object.robY, object.robZ);
      chairGO.mesh.position.set(object.chairX, object.chairY, object.chairZ);

      this._mesh.quaternion.slerpQuaternions(initQuat, this._initQuat, elapsed);
      this._mesh.quaternion.normalize();

      chairGO.mesh.quaternion.slerpQuaternions(
        initChair,
        this._initChairQuat,
        elapsed
      );
      chairGO.mesh.quaternion.normalize();
    });

    this.tween.onComplete((object) => {
      this.dispatchMovingEvent(false, "computerRob");

      this._mesh.quaternion.copy(this._initQuat);
      this._mesh.quaternion.normalize();

      chairGO.mesh.quaternion.copy(this._initChairQuat);
      chairGO.mesh.quaternion.normalize();

      this.tween = undefined;
    });

    this.tween.start();
  };
  dispatchActionEvent = (detail) => {
    this._params.actionEvent(detail);
  };
  dispatchMovingEvent = (moving, state) => {
    this._params.movingObjectEvent({ moving, state });
  };
  get isPianoActive() {
    return (
      this.isMovingToPiano ||
      this._state === "piano" ||
      this._state === "pianoSlow"
    );
  }
  get isMovingToPiano() {
    return (
      this._state === "movingToPiano" || this._state === "startPlayingPiano"
    );
  }
  get mesh() {
    return this._mesh;
  }
}
export default RobGO;
