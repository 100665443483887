class FiniteStateMachine {
  constructor(parent) {
    this.parent = parent;
    this.states = {};
    this.currentState = null;
  }
  addState = (name, type) => {
    this.states[name] = type;
  };
  setState = (name) => {
    const prevState = this.currentState;

    if (prevState) {
      if (name === prevState.name) {
        return;
      }
      prevState.exit();
    }

    const state = this.states[name];
    this.currentState = state;
    state.enter(prevState);
  };
  update = (timeElapsed) => {
    if (this.currentState) {
      this.currentState.update(timeElapsed);
    }
  };
}
export default FiniteStateMachine;
