import * as THREE from "three";
import BaseObject from "./BaseObject";
import ArtBackWallGO from "./GameObjects/ArtBackWallGO";
import ArtOpensourceGO from "./GameObjects/ArtOpensourceGO";
import BookGO from "./GameObjects/BookGO";
import BookshelfBooksGO from "./GameObjects/BookshelfBooksGO";
import BookshelfGO from "./GameObjects/BookshelfGO";
import CurtainsGO from "./GameObjects/CurtainsGO";
import MonitorGO from "./GameObjects/MonitorGO";
import PhoneGO from "./GameObjects/PhoneGO";
import ReyGO from "./GameObjects/ReyGO";
import RobGO from "./GameObjects/RobGO";
import SynthesizerGO from "./GameObjects/SynthesizerGO";
import TVGO from "./GameObjects/TVGO";
// import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

class InputManagerBO extends BaseObject {
  constructor(params) {
    super();
    this._params = params;

    this._mouse = new THREE.Vector2();
    this._raycaster = new THREE.Raycaster();

    // this._controls = new OrbitControls(this._params.cameraGO.camera, this._params.renderer.domElement);

    this._params.renderer.domElement.addEventListener(
      "pointermove",
      this.onMouseOver,
      false
    );
    this._params.renderer.domElement.addEventListener(
      "click",
      this.onMouseClick,
      false
    );
  }
  // update(deltaTime) {
  //   this._controls.update();
  // }
  setRaycasterObjects = (objects) => {
    this._raycasterObjects = [
      objects.find(x => x instanceof ArtBackWallGO),
      objects.find(x => x instanceof ArtOpensourceGO).mesh,
      objects.find(x => x instanceof BookGO).mesh,
      objects.find(x => x instanceof BookshelfBooksGO).mesh,
      objects.find(x => x instanceof BookshelfGO).mesh,
      objects.find(x => x instanceof CurtainsGO).mesh,
      objects.find(x => x instanceof MonitorGO).mesh,
      objects.find(x => x instanceof PhoneGO).mesh,
      objects.find(x => x instanceof ReyGO).mesh,
      objects.find(x => x instanceof RobGO).mesh,
      objects.find(x => x instanceof SynthesizerGO).mesh,
      objects.find(x => x instanceof TVGO).mesh
    ];
  }
  onMouseOver = (event) => {
    if (this._raycasterObjects) {
      event.preventDefault();
      this._mouse.x =
        (event.clientX / this._params.renderer.domElement.clientWidth) * 2 - 1;
      this._mouse.y =
        -(event.clientY / this._params.renderer.domElement.clientHeight) * 2 + 1;
      this._raycaster.setFromCamera(this._mouse, this._params.cameraGO.camera);
      var intersects = this._raycaster.intersectObject(this._params.sceneGO.scene, true);
      
      if (intersects.length > 0 && intersects[0].object.hoverCallback) {
        intersects[0].object.hoverCallback();
      } else {
        this._params.gameManager.onHoverEvent();
      }
    }
  };
  onMouseClick = (event) => {
    if (this._raycasterObjects) {
      this._mouse.x =
        (event.clientX / this._params.renderer.domElement.clientWidth) * 2 - 1;
      this._mouse.y =
        -(event.clientY / this._params.renderer.domElement.clientHeight) * 2 + 1;
      this._raycaster.setFromCamera(this._mouse, this._params.cameraGO.camera);
      var intersects = this._raycaster.intersectObject(this._params.sceneGO.scene, true);

      if (intersects.length > 0 && intersects[0].object.clickCallback) {
        intersects[0].object.clickCallback();
      } else {
        this._params.gameManager.onClickEvent();
      }
    }
  };
  dispose = () => {
    this._params.renderer.domElement.removeEventListener(
      "pointermove",
      this.onMouseOver,
      false
    );
    this._params.renderer.domElement.removeEventListener(
      "click",
      this.onMouseClick,
      false
    );
  };
}
export default InputManagerBO;
