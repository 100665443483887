import * as THREE from "three";
import GameObject from "./GameObject";
import Monitor from "../../Assets/Models/Monitor.glb";
import MonitorImage0 from "../../Assets/Textures/monitor.jpg";
import MonitorImage1 from "../../Assets/Textures/monitor1.jpg";
import MonitorImage2 from "../../Assets/Textures/monitor2.jpg";
import MonitorImage3 from "../../Assets/Textures/monitor3.jpg";
import MonitorImage4 from "../../Assets/Textures/monitor4.jpg";
import MonitorImage5 from "../../Assets/Textures/monitor5.jpg";
import MonitorImage6 from "../../Assets/Textures/monitor6.jpg";
import MonitorImage7 from "../../Assets/Textures/monitor7.jpg";

class MonitorGO extends GameObject {
  constructor(params) {
    super(params);

    window.addEventListener("blur", () => this.onIFrameClicked(), false);
  }
  loadModels() {
    this._params.loader.load(Monitor, (object) => {
      object.scene.traverse((c) => {
        c.receiveShadow = true;
        c.castShadow = true;
        c.hoverCallback = () =>
          !this._isZoomedIn
            ? this.hoverCallback(object.scene.children)
            : this.hoverCallback([c]);
        c.clickCallback = () =>
          !this._isZoomedIn
            ? this.clickCallback(object.scene.children)
            : this.clickCallback([c]);

        if (c instanceof THREE.Mesh) {
          if (c.material.name === "Screen.001") {
            this._screen = c;
            c.material.emissive = new THREE.Color(1, 1, 1);
            c.material.emissiveMap = c.material.map;
            c.material.emissiveIntensity = 1;
          }

          if (c.name === "SeeWhatIsInTheWorks") {
            this._seeWhatsInTheWorks = c;
            c.visible = false;
            c.receiveShadow = false;
            c.castShadow = false;
          }

          if (c.name === "Stop") {
            this._stop = c;
            c.visible = false;
          }
        }
      });

      this._mesh = object.scene;

      this.setIsVisible(false);

      const tex_loader = new THREE.TextureLoader();
      tex_loader.load(MonitorImage0, (texture) => {
        this._monitor0 = texture;
        this.onLoaded();
      });
      tex_loader.load(MonitorImage1, (texture) => {
        this._monitor1 = texture;
      });
      tex_loader.load(MonitorImage2, (texture) => {
        this._monitor2 = texture;
        this.onLoaded();
      });
      tex_loader.load(MonitorImage3, (texture) => {
        this._monitor3 = texture;
        this.onLoaded();
      });
      tex_loader.load(MonitorImage4, (texture) => {
        this._monitor4 = texture;
        this.onLoaded();
      });
      tex_loader.load(MonitorImage5, (texture) => {
        this._monitor5 = texture;
        this.onLoaded();
      });
      tex_loader.load(MonitorImage6, (texture) => {
        this._monitor6 = texture;
        this.onLoaded();
      });
      tex_loader.load(MonitorImage7, (texture) => {
        this._monitor7 = texture;
        this.onLoaded();
      });

      this.onLoaded();
    });
  }
  hoverCallback = (meshes) => {
    if (this._canBeSelected) {
      if (
        !this._isZoomedIn ||
        meshes[0].name === "SeeWhatIsInTheWorks" ||
        meshes[0].name === "Back"
      ) {
        this._params.hoverEvent({
          items: meshes,
          source: this,
        });
        return;
      }
    }

    this._params.hoverEvent({
      items: [],
      source: this,
    });
  };
  clickCallback = (meshes) => {
    if (this._canBeSelected) {
      if (
        !this._isZoomedIn ||
        meshes[0].name === "SeeWhatIsInTheWorks" ||
        meshes[0].name === "Back"
      ) {
        this._params.clickEvent({
          items: meshes,
          source: this,
        });
      }
    }
  };
  onLoaded = () => {
    if (
      this._mesh &&
      this._monitor0 &&
      this._monitor1 &&
      this._monitor2 &&
      this._monitor3 &&
      this._monitor4 &&
      this._monitor5 &&
      this._monitor6 &&
      this._monitor7
    ) {
      this.reset();
      this.changeMonitorImage();
      this.dispatchModelLoaded(this._mesh);
    }
  };
  onIFrameClicked = () => {
    if (
      document.activeElement.tagName === "IFRAME" &&
      document.activeElement.id === "monitor_iframe"
    ) {
      this.clickCallback(this._mesh.children);
    }
  };
  changeMonitorImage() {
    if (!this._isZoomedIn) {
      if (!this._previousImage) {
        this._previousImage = 0;
      }

      switch (this._previousImage++) {
        case 0:
          break;
        case 1:
          this._screen.material.map = this._monitor1;
          break;
        case 2:
          this._screen.material.map = this._monitor2;
          break;
        case 3:
          this._screen.material.map = this._monitor3;
          break;
        case 4:
          this._screen.material.map = this._monitor4;
          break;
        case 5:
          this._screen.material.map = this._monitor5;
          break;
        case 6:
          this._screen.material.map = this._monitor6;
          break;
        case 7:
          this._screen.material.map = this._monitor7;
          break;
        default:
          this._previousImage = 0;
          this._screen.material.map = this._monitor0;
          break;
      }

      this._screen.material.emissiveMap = this._screen.material.map;
    }
  }
  setIsZoomedIn = (isZoomedIn) => {
    this._isZoomedIn = isZoomedIn;

    this._seeWhatsInTheWorks.visible = isZoomedIn;
  };
  setCanBeSelected = (canBeSelected) => {
    this._canBeSelected = canBeSelected;
  };
  reset = () => {
    this.setIsZoomedIn(false);
    this.setCanBeSelected(true);
  };
  dispatchPlayingMonitor = (isPlaying) => {
    this._params.actionEvent({ action: "monitor", isPlaying });
  };
  dispose() {
    window.removeEventListener("blur", () => this.onIFrameClicked());
    super.dispose();
  }
  get mesh() {
    return this._mesh;
  }
  get seeWhatIsInTheWorks() {
    return this._seeWhatsInTheWorks;
  }
}
export default MonitorGO;
