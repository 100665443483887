import SceneBO from "./Objects/SceneBO";
import CameraGO from "./Objects/GameObjects/CameraGO";
import AudioManagerBO from "./Objects/AudioManagerBO";
import InputManagerBO from "./Objects/InputManagerBO";
import RendererBO from "./Objects/RendererBO";
import PostProcessBO from "./Objects/PostProcessBO";
import GameObjectManagerBO from "./Objects/GameObjectManagerBO";
import LightManagerBO from "./Objects/LightManagerBO";
import BookshelfBooksGO from "./Objects/GameObjects/BookshelfBooksGO";
import BookshelfGO from "./Objects/GameObjects/BookshelfGO";
import BookGO from "./Objects/GameObjects/BookGO";
import RobGO from "./Objects/GameObjects/RobGO";
import TVGO from "./Objects/GameObjects/TVGO";
import MonitorGO from "./Objects/GameObjects/MonitorGO";
import ChairGO from "./Objects/GameObjects/ChairGO";
import SynthesizerGO from "./Objects/GameObjects/SynthesizerGO";
import ArtOpensourceGO from "./Objects/GameObjects/ArtOpensourceGO";
import { PianoSongs } from "./enums";
import ReyGO from "./Objects/GameObjects/ReyGO";
import TVIFrameGO from "./Objects/GameObjects/TVIFrameGO";
import RendererCSSBO from "./Objects/RendererCSSBO";
import MonitorIFrameGO from "./Objects/GameObjects/MonitorIFrameGO";
import CurtainsGO from "./Objects/GameObjects/CurtainsGO";
import PhoneGO from "./Objects/GameObjects/PhoneGO";
import ArtBackWallGO from "./Objects/GameObjects/ArtBackWallGO";
import PaintingTINTB from "./Objects/GameObjects/Paintings/PaintingTINTB";
import PhoneIFrameGO from "./Objects/GameObjects/PhoneIFrameGO";
import PaintingGemWars from "./Objects/GameObjects/Paintings/PaintingGemWars";
import PaintingOpenDJRadio from "./Objects/GameObjects/Paintings/PaintingOpenDJRadio";
import PaintingTheMonitaur from "./Objects/GameObjects/Paintings/PaintingTheMonitaur";
import PaintingGoldBunnyStore from "./Objects/GameObjects/Paintings/PaintingGoldBunnyStore";
import PaintingAllieChat from "./Objects/GameObjects/Paintings/PaintingAllieChat";
import PaintingPHS from "./Objects/GameObjects/Paintings/PaintingPHS";
import PaintingGitHub from "./Objects/GameObjects/Paintings/PaintingGitHub";
import PaintingNuGet from "./Objects/GameObjects/Paintings/PaintingNuGet";
import MenuManager from "./MenuManager";
import ReactGA from "react-ga4";

class GameManager {
  constructor(params) {
    this._params = params;

    this._sceneBO = new SceneBO({ modelLoadedEvent: this.onModelLoaded });
    this._cameraGO = new CameraGO({
      sceneType: this._params.sceneType,
      movingObjectEvent: this.onMovingObjectEvent,
    });

    this._rendererBO = new RendererBO();
    this._postProcessBO = new PostProcessBO({
      sceneBO: this._sceneBO,
      cameraGO: this._cameraGO,
      rendererBO: this._rendererBO,
    });
    this._rendererCSSBO = new RendererCSSBO({
      sceneBO: this._sceneBO,
      cameraGO: this._cameraGO,
    });

    this._lightManagerBO = new LightManagerBO({
      sceneBO: this._sceneBO,
    });

    this._audioManagerBO = new AudioManagerBO({
      muted: this._params.muted,
      volume: this._params.volume,
      actionEvent: this.onActionEvent,
    });

    this._gameObjectManagerBO = new GameObjectManagerBO({
      sceneBO: this._sceneBO,
      cameraGO: this._cameraGO,
      postProcessBO: this._postProcessBO,
      sceneType: params.sceneType,
      isDay: params.isDay,
      recaptchaRef: params.recaptchaRef,
      hoverEvent: this.onHoverEvent,
      clickEvent: this.onClickEvent,
      movingObjectEvent: this.onMovingObjectEvent,
      actionEvent: this.onActionEvent,
      modelLoadedEvent: this.onModelLoaded,
      disposeEvent: this.onDispose,
    });

    this._menuManager = new MenuManager({ gameObjects: this.gameObjects });

    this._inputManagerBO = new InputManagerBO({
      sceneGO: this._sceneBO,
      cameraGO: this._cameraGO,
      renderer: this._rendererBO.renderer,
      gameManager: this,
    });

    this._managers = [
      this._sceneBO,
      this._cameraGO,
      this._rendererBO,
      this._rendererCSSBO,
      this._postProcessBO,
      this._lightManagerBO,
      this._gameObjectManagerBO,
      this._inputManagerBO,
      this._audioManagerBO,
    ];

    this._state = "default";
    this._loadedObjects = [];

    window.addEventListener("resize", this.onWindowResize, false);
  }
  update(deltaTime) {
    for (let i = 0; i < this._managers.length; i++) {
      this._managers[i].update(deltaTime);
    }
  }
  onButtonClick = (eventData) => {
    this._menuManager.onButtonClick(eventData);
  };
  onButtonHover = (eventData) => {
    this._menuManager.onButtonHover(eventData);
  };
  onButtonLeave = () => {
    this._postProcessBO.outlinePass.selectedObjects = [];
  };
  onHoverEvent = (eventData) => {
    if (this._isMoving || this._isPlayingGame) {
      return;
    }

    if (eventData && eventData.source) {
      if (
        this._state !== "bookcase" &&
        eventData.source instanceof BookshelfBooksGO
      ) {
        this.gameObjects.find((x) => x instanceof BookshelfGO).onHoverEvent();
      } else {
        this._postProcessBO.outlinePass.selectedObjects = eventData.items;
      }
    } else {
      this._postProcessBO.outlinePass.selectedObjects = [];
    }

    this._params.hoverEvent(eventData);
  };
  onClickEvent = (eventData) => {
    if (
      !this.gameObjects.find((x) => x instanceof RobGO).isPianoActive &&
      this.gameObjects.find((x) => x instanceof TVGO).state !== "playing" &&
      (!eventData || !(eventData.source instanceof SynthesizerGO))
    ) {
      this._audioManagerBO.playBackgroundMusic();
    }

    if (this._isPlayingGame) {
      this._params.backClicked();
      return;
    }

    if (this._isMoving) {
      return;
    }

    if (this._state === "bookcaseBook") {
      if (eventData && eventData.source instanceof BookGO) {
        this.onBookClicked(eventData);
      } else {
        this.onBookReset();
      }
    } else if (this._state === "art") {
      if (
        eventData &&
        (eventData.source instanceof ArtBackWallGO ||
          eventData.source instanceof ArtOpensourceGO)
      ) {
        this.onPaintingClicked(eventData);
      } else {
        this.onPaintingReset();
      }
    } else if (this._state === "phone") {
      if (!eventData || !(eventData.source instanceof PhoneGO)) {
        this.onPhoneReset();
      }
    } else {
      if (eventData) {
        if (eventData.source instanceof BookshelfGO) {
          this.onBookcaseClicked(eventData);
        } else if (eventData.source instanceof BookshelfBooksGO) {
          this.onBookcaseBookClicked(eventData);
        } else if (eventData.source instanceof ArtOpensourceGO) {
          this.onArtOpensourceClicked(eventData);
        } else if (eventData.source instanceof ArtBackWallGO) {
          this.onArtBackWallClicked(eventData);
        } else if (eventData.source instanceof MonitorGO) {
          this.onMonitorClicked(eventData);
        } else if (eventData.source instanceof TVGO) {
          this.onTVClicked(eventData);
        } else if (eventData.source instanceof SynthesizerGO) {
          this.onSynthesizerClicked(eventData);
        } else if (eventData.source instanceof ReyGO) {
          this.onReyClicked(eventData);
        } else if (eventData.source instanceof CurtainsGO) {
          this.onCurtainsClicked(eventData);
        } else if (eventData.source instanceof PhoneGO) {
          this.onPhoneClicked(eventData);
        }
      } else if (this._state !== "default") {
        this.onResetScene();
      }
    }
  };
  onMovingObjectEvent = (eventData) => {
    this._isMoving = eventData.moving;

    switch (eventData.state) {
      case "bookcase":
        if (!this._isMoving) {
          this._state = "bookcase";
          this._postProcessBO.outlinePass.selectedObjects = [];
          this.gameObjects
            .find((x) => x instanceof BookshelfBooksGO)
            .setCanBeSelected(true);
          this._params.navigationEvent("bookcase");
        }
        break;
      case "showBook":
        if (!this._isMoving) {
          this._state = "bookcaseBook";
          this.gameObjects.find((x) => x instanceof BookGO).openBook();
        } else {
          this._params.navigationEvent();
        }
        break;
      case "openBook":
        if (!this._isMoving) {
          this._state = "bookcaseBook";
          const bookGO = this.gameObjects.find((x) => x instanceof BookGO);
          bookGO.setCanBeSelected(true);

          this._params.navigationEvent("showBook" + bookGO.book.bookType);
        }
        break;
      case "closeBook":
        if (!this._isMoving) {
          this._state = "bookcase";
          this.gameObjects
            .find((x) => x instanceof BookshelfBooksGO)
            .setCanBeSelected(true);
          this._cameraGO.moveToBookcase();
          this.gameObjects.find((x) => x instanceof BookGO).hideBook();
        }
        break;
      case "hideBook":
        if (!this._isMoving) {
          this._state = "bookcase";
          this.gameObjects
            .find((x) => x instanceof BookGO)
            .setCanBeSelected(false);

          this._params.navigationEvent("bookcase");
        }
        break;
      case "synthesizerRob":
        if (!this._isMoving) {
          this._isMoving = true;
          this.gameObjects
            .find((x) => x instanceof RobGO)
            .setState("startPlayingPiano");

          this._params.navigationEvent("synthesizer");
        }
        break;
      case "computerRob":
        if (!this._isMoving) {
          this._postProcessBO.outlinePass.selectedObjects = [];
          this.gameObjects
            .find((x) => x instanceof SynthesizerGO)
            .setCanBeSelected(true);
          this.gameObjects
            .find((x) => x instanceof RobGO)
            .setState("startingToTypeComputer");
        }
        break;
      case "monitor":
        if (!this._isMoving) {
          this._state = eventData.state;
          this._postProcessBO.outlinePass.selectedObjects = [];

          const monitor = this.gameObjects.find((x) => x instanceof MonitorGO);
          if (monitor.isPlaying) {
            this._params.navigationEvent("monitorOn");
          } else {
            this._params.navigationEvent("monitorOff");
          }
        }
        break;
      case "artBackWall":
        if (!this._isMoving) {
          this._state = eventData.state;
          this._postProcessBO.outlinePass.selectedObjects = [];
          this._params.navigationEvent("artBackWall");
        }
        break;
      case "artOpensource":
        if (!this._isMoving) {
          this._state = eventData.state;
          this._postProcessBO.outlinePass.selectedObjects = [];
          this._params.navigationEvent("artOpensource");
        }
        break;
      case "synthesizer":
        if (!this._isMoving) {
          this._state = eventData.state;
          this._postProcessBO.outlinePass.selectedObjects = [];

          if (
            this.gameObjects.find((x) => x instanceof RobGO).isMovingToPiano
          ) {
            this._isMoving = true;
          } else {
            this._params.navigationEvent("synthesizer");
          }
        }
        break;
      case "tv":
        if (!this._isMoving) {
          this._state = eventData.state;
          this._postProcessBO.outlinePass.selectedObjects = [];

          const tv = this.gameObjects.find((x) => x instanceof TVGO);
          tv.setState("on");

          switch (tv.state) {
            case "menu":
              this._params.navigationEvent("tvMenu");
              break;
            case "youtube":
              this._params.navigationEvent("tvYouTube");
              break;
            case "playing":
              this._params.navigationEvent("tvPlaying");
              break;
            default:
              break;
          }
        }
        break;
      case "reset":
        if (!this._isMoving) {
          this._params.navigationEvent("default");
        } else {
          this._params.titleText.fadeIn();
        }
        break;
      case "hidePhone":
        if (this._isMoving) {
          this.gameObjects
            .find((x) => x instanceof PhoneIFrameGO)
            .setIsVisible(false);

          this._params.titleText.fadeIn();
        } else {
          this._params.navigationEvent("default");
        }
        break;
      case "showPhone":
        if (!this._isMoving) {
          this._postProcessBO.outlinePass.selectedObjects = [];

          setTimeout(() => {
            this.gameObjects
              .find((x) => x instanceof PhoneIFrameGO)
              .setIsVisible(true);
          }, 1);

          this._params.navigationEvent("phone");
        }
        break;
      case "showPainting":
        if (!this._isMoving) {
          if (this._previousState === "artBackWall") {
            const artBackWall = this.gameObjects.find(
              (x) => x instanceof ArtBackWallGO
            );
            if (artBackWall.activeObject instanceof PaintingTINTB) {
              this._params.navigationEvent("showPaintingTINTB");
            } else if (artBackWall.activeObject instanceof PaintingGemWars) {
              this._params.navigationEvent("showPaintingGemWars");
            } else if (
              artBackWall.activeObject instanceof PaintingOpenDJRadio
            ) {
              this._params.navigationEvent("showPaintingOpenDJRadio");
            } else if (
              artBackWall.activeObject instanceof PaintingTheMonitaur
            ) {
              this._params.navigationEvent("showPaintingTheMonitaur");
            } else if (
              artBackWall.activeObject instanceof PaintingGoldBunnyStore
            ) {
              this._params.navigationEvent("showPaintingGoldBunnyStore");
            } else if (artBackWall.activeObject instanceof PaintingAllieChat) {
              this._params.navigationEvent("showPaintingAllieChat");
            } else if (artBackWall.activeObject instanceof PaintingPHS) {
              this._params.navigationEvent("showPaintingPHS");
            }
          } else if (this._previousState === "artOpensource") {
            const artOpensource = this.gameObjects.find(
              (x) => x instanceof ArtOpensourceGO
            );
            if (artOpensource.activeObject instanceof PaintingNuGet) {
              this._params.navigationEvent("showPaintingNuGet");
            } else if (artOpensource.activeObject instanceof PaintingGitHub) {
              this._params.navigationEvent("showPaintingGitHub");
            }
          }
        }
        break;
      case "hidePainting":
        if (!this._isMoving) {
          this._params.navigationEvent(this._state);
        }
        break;
      case "rey":
        if (!this._isMoving) {
          this._params.navigationEvent("rey");
        }
        break;
      default:
        break;
    }
  };
  onActionEvent = (eventData) => {
    switch (eventData.action) {
      case "moveToPiano":
        this._isMoving = true;
        if (this._isFromDefault) {
          this._cameraGO.moveToSynthesizer();
          this._params.titleText.fadeOut();
          this._audioManagerBO.playWhoosh();
        }

        this._postProcessBO.outlinePass.selectedObjects = [];

        this.gameObjects
          .find((x) => x instanceof RobGO)
          .robMoveToSynthesizer(
            this.gameObjects.find((x) => x instanceof ChairGO)
          );
        break;
      case "moveToComputer":
        if (this._state === "synthesizer" && this._isFromDefault) {
          this.onResetScene();
        }

        this.gameObjects
          .find((x) => x instanceof RobGO)
          .robMoveToComputer(
            this.gameObjects.find((x) => x instanceof ChairGO)
          );

        if (
          this.gameObjects.find((x) => x instanceof TVGO).state !== "playing"
        ) {
          this._audioManagerBO.fadeinBackgroundMusic();
        }
        break;
      case "playPiano":
        this._isMoving = false;

        let pianoSongType = PianoSongs.fast;
        if (Math.random() > 0.5) {
          pianoSongType = PianoSongs.slow;
          this.gameObjects
            .find((x) => x instanceof RobGO)
            .setState("pianoSlow");
        } else {
          this.gameObjects.find((x) => x instanceof RobGO).setState("piano");
        }
        this._audioManagerBO.playPiano(pianoSongType);
        break;
      case "pianoEnded":
        this.gameObjects
          .find((x) => x instanceof RobGO)
          .setState("movingToComputer");

        this._params.navigationEvent();
        break;
      case "pianoEndedSad":
        this.gameObjects
          .find((x) => x instanceof RobGO)
          .setState("movingToComputerSad");

        this._params.navigationEvent();
        break;
      case "playClapping":
        this._audioManagerBO.playClapping();
        break;
      case "typing":
        if (!this.gameObjects.find((x) => x instanceof RobGO).isPianoActive) {
          this.gameObjects
            .find((x) => x instanceof MonitorGO)
            .changeMonitorImage();
        }
        break;
      case "rey":
        switch (eventData.animation) {
          case "jump":
            this._cameraGO.reyJump();
            this._audioManagerBO.playWimper();
            break;
          case "attack":
            this._audioManagerBO.playBark();
            break;
          case "swipe":
            this._audioManagerBO.playGrowl();
            break;
          default:
            break;
        }
        break;
      case "tv":
        switch (eventData.state) {
          case "off":
            break;
          case "on":
            break;
          case "menu":
            this._audioManagerBO.fadeinBackgroundMusic();
            this._params.navigationEvent("tvMenu");
            break;
          case "youtube":
            this._params.navigationEvent("tvYouTube");
            break;
          case "playing":
            this._audioManagerBO.fadeoutBackgroundMusic();
            this._params.navigationEvent("tvPlaying");
            break;
          default:
            break;
        }
        break;
      case "monitor":
        const rob = this.gameObjects.find((x) => x instanceof RobGO);

        if (eventData.isPlaying) {
          this._params.navigationEvent("monitorOn");

          if (
            this.gameObjects.find((x) => x instanceof TVGO).state !== "playing"
          ) {
            this._audioManagerBO.fadeoutBackgroundMusic();
          }

          if (!rob.isPianoActive) {
            rob.setState("idle");
          }
        } else {
          this._params.navigationEvent("monitorOff");

          if (
            this.gameObjects.find((x) => x instanceof TVGO).state !== "playing"
          ) {
            this._audioManagerBO.fadeinBackgroundMusic();
          }

          if (!rob.isPianoActive) {
            rob.setState("computer");
          }
        }
        break;
      default:
        break;
    }
  };
  onWindowResize = () => {
    this._cameraGO.camera.aspect = window.innerWidth / window.innerHeight;

    if (this._state !== "phone" && this._state !== "art") {
      this._cameraGO.camera.fov = this._cameraGO.fov;
    }

    this._cameraGO.camera.updateProjectionMatrix();

    this._rendererBO.renderer.setPixelRatio(window.devicePixelRatio);
    this._rendererBO.renderer.setSize(window.innerWidth, window.innerHeight);

    this._rendererCSSBO.renderer.setSize(window.innerWidth, window.innerHeight);
  };
  onModelLoaded = (eventData) => {
    if (eventData.source instanceof SceneBO) {
      this._backgroundLoaded = true;
    } else {
      this._sceneBO.scene.add(eventData.target);
      this._loadedObjects.push(eventData.target);
    }
    if (this._backgroundLoaded && this._loadedObjects.length === 21) {
      for (let i = 0; i < this.gameObjects.length; i++) {
        if (this.gameObjects[i] instanceof RobGO) {
          this.gameObjects[i].setState("computer");
        } else if (this.gameObjects[i] instanceof ReyGO) {
          this.gameObjects[i].playNextAnimation();
        }

        if (
          !(this.gameObjects[i] instanceof TVIFrameGO) &&
          !(this.gameObjects[i] instanceof PhoneIFrameGO) &&
          !(this.gameObjects[i] instanceof MonitorIFrameGO)
        ) {
          this.gameObjects[i].setIsVisible(true);
        }
      }

      this._loadedObjects = undefined;
      this._backgroundLoaded = undefined;

      this._sceneBO.setBackground();
      this._audioManagerBO.playBackgroundMusic();
      this._inputManagerBO.setRaycasterObjects(this.gameObjects);
      this._params.navigationEvent("default");
    }
  };
  onBookcaseClicked = (detail) => {
    if (this._state !== "bookcase") {
      this._state = "bookcase";
      this.resetObjects();
      this.gameObjects
        .find((x) => x instanceof BookshelfGO)
        .setCanBeSelected(false);

      this._postProcessBO.outlinePass.selectedObjects = [];

      this._cameraGO.moveToBookcase();
      this._audioManagerBO.playWhoosh();
      this._params.titleText.fadeOut();

      this._params.navigationEvent();

      ReactGA.event("MoveToBookcase");
    }
  };
  onBookcaseBookClicked = (detail) => {
    if (this._state !== "bookcase") {
      this.gameObjects.find((x) => x instanceof BookshelfGO).onClickEvent();
      return;
    }

    if (!detail.source.canBeSelected) {
      return;
    }

    this._state = "bookcaseBook";
    this.gameObjects
      .find((x) => x instanceof BookshelfBooksGO)
      .selectBook(detail.items[0]);

    this.gameObjects
      .find((x) => x instanceof BookGO)
      .showBook(detail, this._cameraGO);

    this._audioManagerBO.playBookOpen();

    this._postProcessBO.outlinePass.selectedObjects = [];

    ReactGA.event("BookClicked - " + detail.bookType);
  };
  onBookClicked = (detail) => {
    window.open(detail.url, "_blank");

    ReactGA.event("BookItemClicked - " + detail.name);
  };
  onBookReset = () => {
    this.gameObjects.find((x) => x instanceof BookGO).closeBook();
    this._audioManagerBO.playBookClose();
    this._params.navigationEvent();
  };
  onArtBackWallClicked = (detail) => {
    if (this._state !== "artBackWall") {
      this._params.navigationEvent();

      this._state = "artBackWall";
      this.resetObjects();
      this.gameObjects
        .find((x) => x instanceof ArtBackWallGO)
        .setIsZoomedIn(true);

      this._cameraGO.moveToArtBackWall();
      this._audioManagerBO.playWhoosh();
      this._params.titleText.fadeOut();

      ReactGA.event("MoveToArtBackWall");
    } else {
      this._state = "art";
      this._previousState = "artBackWall";

      if (detail.painting instanceof PaintingTINTB) {
        this._audioManagerBO.fadeoutBackgroundMusic();
      }

      this.gameObjects
        .find((x) => x instanceof ArtBackWallGO)
        .showPainting(detail.painting, this._cameraGO);

      ReactGA.event("ArtBackWallClicked - " + detail.painting.name);
    }

    this._postProcessBO.outlinePass.selectedObjects = [];
    this._params.navigationEvent();
  };
  onArtOpensourceClicked = (detail) => {
    if (this._state !== "artOpensource") {
      this._state = "artOpensource";
      this.resetObjects();
      this.gameObjects
        .find((x) => x instanceof ArtOpensourceGO)
        .setIsZoomedIn(true);

      this._cameraGO.moveToArtOpensource();
      this._audioManagerBO.playWhoosh();
      this._params.titleText.fadeOut();

      ReactGA.event("MoveToArtOpensource");
    } else {
      this._state = "art";
      this._previousState = "artOpensource";

      this.gameObjects
        .find((x) => x instanceof ArtOpensourceGO)
        .showPainting(detail.painting, this._cameraGO);

      ReactGA.event("ArtOpensourceClicked - " + detail.painting.name);
    }

    this._postProcessBO.outlinePass.selectedObjects = [];
    this._params.navigationEvent();
  };
  onPaintingClicked = (detail) => {
    window.open(detail.url, "_blank");

    ReactGA.event("ArtClicked - " + detail.url);
  };
  onPaintingReset = () => {
    this._state = this._previousState;
    this._previousState = undefined;
    this._params.navigationEvent();

    switch (this._state) {
      case "artBackWall":
        const artBackWallGO = this.gameObjects.find(
          (x) => x instanceof ArtBackWallGO
        );

        if (artBackWallGO.activeObject instanceof PaintingTINTB) {
          this._audioManagerBO.fadeinBackgroundMusic();
        }

        artBackWallGO.hidePainting(this._cameraGO);
        break;
      case "artOpensource":
        this.gameObjects
          .find((x) => x instanceof ArtOpensourceGO)
          .hidePainting(this._cameraGO);
        break;
      default:
        break;
    }
  };
  onMonitorClicked = (detail) => {
    if (this._state !== "monitor") {
      this._state = "monitor";
      this.resetObjects();

      this.gameObjects.find((x) => x instanceof RobGO).setState("idle");
      this.gameObjects.find((x) => x instanceof MonitorGO).setIsZoomedIn(true);

      this._cameraGO.moveToMonitor();
      this._audioManagerBO.playWhoosh();
      this._params.titleText.fadeOut();
      this._params.navigationEvent();

      ReactGA.event("MoveToMonitor");
    } else {
      if (detail.items[0].name === "SeeWhatIsInTheWorks") {
        this.setIsPlayingGame(true);
        this._params.loadGemWars();
        this._params.navigationEvent();

        ReactGA.event("MonitorClicked - Play GemWars");
      }
    }

    this._postProcessBO.outlinePass.selectedObjects = [];
  };
  setIsPlayingGame = (isPlayingGame) => {
    this._isPlayingGame = isPlayingGame;
  };
  onTVClicked = (detail) => {
    if (this._state !== "tv") {
      this._state = "tv";
      this.resetObjects();
      this.gameObjects.find((x) => x instanceof TVGO).setIsZoomedIn(true);

      this._cameraGO.moveToTV();
      this._audioManagerBO.playWhoosh();
      this._params.titleText.fadeOut();
      this._params.navigationEvent();

      ReactGA.event("MoveToTV");
    } else {
      const tv = this.gameObjects.find((x) => x instanceof TVGO);
      tv.programSelected(detail);

      ReactGA.event("TVClicked - " + tv.state);
    }

    this._postProcessBO.outlinePass.selectedObjects = [];
  };
  onSynthesizerClicked = (detail) => {
    if (this._state !== "synthesizer") {
      this._isFromDefault = this._state === "default";

      this._state = "synthesizer";

      this.gameObjects
        .find((x) => x instanceof SynthesizerGO)
        .setCanBeSelected(false);

      const rob = this.gameObjects.find((x) => x instanceof RobGO);
      if (!rob.isPianoActive) {
        this._isMoving = true;
        this._audioManagerBO.fadeoutBackgroundMusic();
        rob.setState("movingToPiano");

        if (!this._isFromDefault) {
          this._cameraGO.moveToSynthesizer();
          this._params.titleText.fadeOut();
          this._audioManagerBO.playWhoosh();

          ReactGA.event("MoveToPiano");
        }
      } else {
        this._cameraGO.moveToSynthesizer();
        this._params.titleText.fadeOut();
        this._audioManagerBO.playWhoosh();

        ReactGA.event("MoveToPiano");
      }

      this._postProcessBO.outlinePass.selectedObjects = [];
      this._params.navigationEvent();
    }
  };
  onSynthesizerStop = () => {
    this._audioManagerBO.stopPiano();
  };
  onReyClicked = (detail) => {
    if (this._state !== "rey") {
      this._state = "rey";

      this._cameraGO.moveToRey();
      this._audioManagerBO.playWhoosh();
      this._params.titleText.fadeOut();

      this._params.navigationEvent();

      ReactGA.event("MoveToRey");
    } else {
      this.gameObjects
        .find((x) => x instanceof ReyGO)
        .onClickEvent(this._cameraGO);

      ReactGA.event("ReyClicked");
    }

    this._postProcessBO.outlinePass.selectedObjects = [];
  };
  onCurtainsClicked = (detail) => {
    this.gameObjects.find((x) => x instanceof CurtainsGO).toggleState();

    this._postProcessBO.outlinePass.selectedObjects = [];

    ReactGA.event("CurtainsClicked");
  };
  onPhoneClicked = (detail) => {
    if (this._state !== "phone") {
      this._state = "phone";
      this._postProcessBO.outlinePass.selectedObjects = [];

      this.gameObjects
        .find((x) => x instanceof PhoneGO)
        .showPhone(this._cameraGO);

      this._params.navigationEvent();

      this._params.titleText.fadeOut();

      ReactGA.event("MoveToPhone");
    }
  };
  onPhoneReset = () => {
    this._state = "default";

    this._params.navigationEvent();
    this.gameObjects
      .find((x) => x instanceof PhoneGO)
      .hidePhone(this._cameraGO);
  };
  onMuteClicked = (muted) => {
    this._audioManagerBO.setMuted(muted);

    ReactGA.event("MuteClicked");
  };
  onVolumeChanged = (volume) => {
    this._audioManagerBO.setVolume(volume);

    ReactGA.event("VolumeClicked");
  };
  onRecaptchaChanged = (token) => {
    this.gameObjects
      .find((x) => x instanceof PhoneIFrameGO)
      .onRecaptchaChanged(token);

    ReactGA.event("PhoneSubmit");
  };
  onDispose = (eventData) => {
    this._sceneBO.scene.remove(eventData.mesh);
  };
  resetObjects = () => {
    this.gameObjects.find((x) => x instanceof BookshelfGO).reset();
    this.gameObjects.find((x) => x instanceof BookshelfBooksGO).reset();
    this.gameObjects.find((x) => x instanceof BookGO).reset();

    this.gameObjects.find((x) => x instanceof ArtBackWallGO).reset();
    this.gameObjects.find((x) => x instanceof ArtOpensourceGO).reset();

    this.gameObjects.find((x) => x instanceof MonitorGO).reset();
    this.gameObjects.find((x) => x instanceof TVGO).reset();
    this.gameObjects.find((x) => x instanceof SynthesizerGO).reset();
    this.gameObjects.find((x) => x instanceof PhoneGO).reset();
  };
  onResetScene = () => {
    if (this._isPlayingGame) {
      return;
    }

    if (this._state === "monitor") {
      this.gameObjects.find((x) => x instanceof RobGO).setState("computer");
    }

    this._params.navigationEvent();
    this._state = "default";

    this.resetObjects();

    this._cameraGO.reset();
    this._audioManagerBO.playWhoosh();

    ReactGA.event("MoveReset");
  };
  dispose() {
    window.removeEventListener("resize", this.onWindowResize, false);

    for (let i = 0; i < this._managers.length; i++) {
      this._managers[i].dispose();
    }
  }
  get gameObjects() {
    return this._gameObjectManagerBO.gameObjects;
  }
  get state() {
    return this._state;
  }
}
export default GameManager;
