import GameObject from "./GameObject";
import Bookcase from "../../Assets/Models/Bookcase.glb";

class BookshelfGO extends GameObject {
  loadModels() {
    this._params.loader.load(Bookcase, (object) => {
      object.scene.traverse((c) => {
        c.receiveShadow = true;
        c.castShadow = true;
        
        c.hoverCallback = () => this.onHoverEvent();
        c.clickCallback = () => this.onClickEvent();
      });
      
      this._mesh = object.scene;
      
      this.setIsVisible(false);

      this.dispatchModelLoaded(this._mesh);

      this.reset();
    });
  }
  onHoverEvent = () => {
    if (this._canBeSelected) {
      this._params.hoverEvent({
        items: this._mesh.children,
        source: this,
      });
    } else {
      this._params.hoverEvent({
        items: [],
        source: this,
      });
    }
  };
  onClickEvent = () => {
    if (this._canBeSelected) {
      this._params.clickEvent({
        items: this._mesh.children,
        source: this,
      });
    }
  };
  setCanBeSelected = (canBeSelected) => {
    this._canBeSelected = canBeSelected;
  };
  reset = () => {
    this.setCanBeSelected(true);
  };
  setIsVisible = (isVisible) => {
    this._mesh.visible = isVisible;
  }
}
export default BookshelfGO;
