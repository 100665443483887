import * as THREE from "three";
import GameObject from "./GameObject";
import * as TWEEN from "@tweenjs/tween.js";
import TV from "../../Assets/Models/TV.glb";

class TVGO extends GameObject {
  constructor(params) {
    super(params);

    this._state = "off";

    window.addEventListener("blur", () => this.onIFrameClicked(), false);
  }
  loadModels() {
    this._params.loader.load(TV, (object) => {
      object.scene.traverse((c) => {
        c.receiveShadow = true;
        c.castShadow = true;

        if (c instanceof THREE.Mesh) {
          switch (c.name) {
            case "Twitch":
            case "YouTube":
              switch (c.name) {
                case "Twitch":
                  this._twitch = {
                    mesh: c,
                    position: new THREE.Vector3(
                      c.position.x,
                      c.position.y,
                      c.position.z
                    ),
                    positionHidden: new THREE.Vector3(100, 100, 100),
                  };
                  break;
                case "YouTube":
                  this._youtube = {
                    mesh: c,
                    position: new THREE.Vector3(
                      c.position.x,
                      c.position.y,
                      c.position.z
                    ),
                    positionHidden: new THREE.Vector3(100, 100, 100),
                  };
                  break;
                default:
                  break;
              }
              
              c.castShadow = false;
              c.material.transparent = true;
              c.material.opacity = 0;
              break;
            case "Menu":
              this._menu = {
                mesh: c,
                position: new THREE.Vector3(
                  c.position.x,
                  c.position.y,
                  c.position.z
                ),
                positionHidden: new THREE.Vector3(100, 100, 100),
              };
              c.castShadow = false;
              c.material.transparent = true;
              c.material.opacity = 0;
              c.position.set(100, 100, 100);
              break;
            case "PickAPlaylist":
            case "OAuthServerNET":
            case "GemWars":
            case "TheMonitaur":
            case "AllieChat":
            case "TINTB":
            case "OpenDJRadio":
              switch (c.name) {
                case "PickAPlaylist":
                  this._pickAPlaylist = {
                    mesh: c,
                    position: new THREE.Vector3(
                      c.position.x,
                      c.position.y,
                      c.position.z
                    ),
                    positionHidden: new THREE.Vector3(100, 100, 100),
                  };
                  break;
                case "OAuthServerNET":
                  this._oauthServerNET = {
                    mesh: c,
                    position: new THREE.Vector3(
                      c.position.x,
                      c.position.y,
                      c.position.z
                    ),
                    positionHidden: new THREE.Vector3(100, 100, 100),
                  };
                  break;
                case "GemWars":
                  this._gemWars = {
                    mesh: c,
                    position: new THREE.Vector3(
                      c.position.x,
                      c.position.y,
                      c.position.z
                    ),
                    positionHidden: new THREE.Vector3(100, 100, 100),
                  };
                  break;
                case "TheMonitaur":
                  this._theMonitaur = {
                    mesh: c,
                    position: new THREE.Vector3(
                      c.position.x,
                      c.position.y,
                      c.position.z
                    ),
                    positionHidden: new THREE.Vector3(100, 100, 100),
                  };
                  break;
                case "AllieChat":
                  this._allieChat = {
                    mesh: c,
                    position: new THREE.Vector3(
                      c.position.x,
                      c.position.y,
                      c.position.z
                    ),
                    positionHidden: new THREE.Vector3(100, 100, 100),
                  };
                  break;
                case "TINTB":
                  this._tintb = {
                    mesh: c,
                    position: new THREE.Vector3(
                      c.position.x,
                      c.position.y,
                      c.position.z
                    ),
                    positionHidden: new THREE.Vector3(100, 100, 100),
                  };
                  break;
                case "OpenDJRadio":
                  this._openDJRadio = {
                    mesh: c,
                    position: new THREE.Vector3(
                      c.position.x,
                      c.position.y,
                      c.position.z
                    ),
                    positionHidden: new THREE.Vector3(100, 100, 100),
                  };
                  break;
                default:
                  break;
              }
              
              c.castShadow = false;
              c.material.transparent = true;
              c.position.set(100, 100, 100);
              break;
            case "TV1_Screen_0":
              this._screen = c;
              this._screen.castShadow = false;
              this._screen.material.emissive = new THREE.Color(0.8625, 0.85, 1);
              this._screen.material.emissiveIntensity = 0;
              break;
            default:
              break;
          }
        }

        c.hoverCallback = () =>
          !this._isZoomedIn
            ? this.hoverCallback(object.scene.children)
            : this.hoverCallback([c]);
        c.clickCallback = () =>
          !this._isZoomedIn
            ? this.clickCallback(object.scene.children)
            : this.clickCallback([c]);
      });

      this._mesh = object.scene;

      this.setIsVisible(false);

      this._state = "off";

      this.dispatchModelLoaded(this._mesh);

      this.reset();
    });
  }
  hoverCallback = (meshes) => {
    if (this._canBeSelected) {
      if (
        !this._isZoomedIn ||
        meshes[0].name === this._twitch.mesh.name ||
        meshes[0].name === this._youtube.mesh.name ||
        meshes[0].name === this._menu.mesh.name ||
        meshes[0].name === this._oauthServerNET.mesh.name ||
        meshes[0].name === this._gemWars.mesh.name ||
        meshes[0].name === this._theMonitaur.mesh.name ||
        meshes[0].name === this._allieChat.mesh.name ||
        meshes[0].name === this._tintb.mesh.name ||
        meshes[0].name === this._openDJRadio.mesh.name
      ) {
        this._params.hoverEvent({
          items: meshes,
          source: this,
        });
        return;
      }
    }

    this._params.hoverEvent({
      items: [],
      source: this,
    });
  };
  clickCallback = (meshes) => {
    if (this._canBeSelected) {
      this._params.clickEvent({
        items: meshes,
        source: this,
      });
    }
  };
  onIFrameClicked = () => {
    if (document.activeElement.tagName === "IFRAME" && document.activeElement.id === "tv_iframe" && !this._isZoomedIn) {
      this.clickCallback(this._mesh.children);
    }
  }
  update = (deltaTime) => {
    if (this.tween) {
      this.tween.update();
    }
  };
  setState = (state) => {
    const previousState = this._state;
    this._state = state;

    switch (state) {
      case "off":
        if (previousState === "menu") {
          this.fadeOutMenu();
        } else if (previousState === "youtube") {
          this.fadeOutYoutube();
        }
        break;
      case "on":
        if (previousState === "off") {
          this.setState("menu");
        } else {
          this._state = previousState;
        }
        break;
      case "menu":
        this.fadeInMenu();
        this._params.tvIFrameGO.setIsVisible(false);
        break;
      case "youtube":
        this.displayYoutube();
        break;
      case "playing":
        this._twitch.mesh.position.copy(this._twitch.positionHidden);
        this._youtube.mesh.position.copy(this._youtube.positionHidden);

        this._pickAPlaylist.mesh.position.copy(this._pickAPlaylist.positionHidden);
        this._oauthServerNET.mesh.position.copy(this._oauthServerNET.positionHidden);
        this._gemWars.mesh.position.copy(this._gemWars.positionHidden);
        this._theMonitaur.mesh.position.copy(this._theMonitaur.positionHidden);
        this._allieChat.mesh.position.copy(this._allieChat.positionHidden);
        this._tintb.mesh.position.copy(this._tintb.positionHidden);
        this._openDJRadio.mesh.position.copy(this._openDJRadio.positionHidden);

        this._menu.mesh.position.copy(this._menu.position);
        this._menu.mesh.material.opacity = 1;
        this._screen.material.emissiveIntensity = 0;
        break;
      default:
        break;
    }

    this.dispatchStateChange();
  };
  fadeInMenu = () => {
    this._twitch.mesh.position.copy(this._twitch.position);
    this._twitch.mesh.material.opacity =
        this._screen.material.emissiveIntensity === 0.65 ? 1 : 0;

    this._youtube.mesh.position.copy(this._youtube.position);
    this._youtube.mesh.material.opacity =
        this._screen.material.emissiveIntensity === 0.65 ? 1 : 0;

    this._pickAPlaylist.mesh.position.copy(this._pickAPlaylist.positionHidden);
    this._oauthServerNET.mesh.position.copy(this._oauthServerNET.positionHidden);
    this._gemWars.mesh.position.copy(this._gemWars.positionHidden);
    this._theMonitaur.mesh.position.copy(this._theMonitaur.positionHidden);
    this._allieChat.mesh.position.copy(this._allieChat.positionHidden);
    this._tintb.mesh.position.copy(this._tintb.positionHidden);
    this._openDJRadio.mesh.position.copy(this._openDJRadio.positionHidden);
        
    this._menu.mesh.position.copy(this._menu.positionHidden);

    if (this._screen.material.emissiveIntensity !== 0.65) {
      this.tween = new TWEEN.Tween({ emissivity: 0, opacity: 0 })
        .to({ emissivity: 0.65, opacity: 1 }, 500)
        .easing(TWEEN.Easing.Quadratic.Out);

      this.tween.onUpdate((object, elapsed) => {
        this._screen.material.emissiveIntensity = object.emissivity;
        this._twitch.mesh.material.opacity = object.opacity;
        this._youtube.mesh.material.opacity = object.opacity;
      });

      this.tween.onComplete((object) => {
        this.tween = undefined;

        this._screen.material.emissiveIntensity = 0.65;
        this._twitch.mesh.material.opacity = 1;
        this._youtube.mesh.material.opacity = 1;

        this.dispatchStateChange();
      });

      this.tween.start();
    }
  };
  fadeOutMenu = () => {
    this._twitch.mesh.position.copy(this._twitch.position)
    this._youtube.mesh.position.copy(this._youtube.position)

    this._pickAPlaylist.mesh.position.copy(this._pickAPlaylist.positionHidden);
    this._oauthServerNET.mesh.position.copy(this._oauthServerNET.positionHidden);
    this._gemWars.mesh.position.copy(this._gemWars.positionHidden);
    this._theMonitaur.mesh.position.copy(this._theMonitaur.positionHidden);
    this._allieChat.mesh.position.copy(this._allieChat.positionHidden);
    this._tintb.mesh.position.copy(this._tintb.positionHidden);
    this._openDJRadio.mesh.position.copy(this._openDJRadio.positionHidden);
  
    this._menu.mesh.position.copy(this._menu.positionHidden);

    this.tween = new TWEEN.Tween({ emissivity: 0.65, opacity: 1 })
      .to({ emissivity: 0, opacity: 0 }, 500)
      .easing(TWEEN.Easing.Quadratic.Out);

    this.tween.onUpdate((object, elapsed) => {
      this._screen.material.emissiveIntensity = object.emissivity;
      this._twitch.mesh.material.opacity = object.opacity;
      this._youtube.mesh.material.opacity = object.opacity;
    });

    this.tween.onComplete((object) => {
      this.tween = undefined;

      this._screen.material.emissiveIntensity = 0;
      this._twitch.mesh.material.opacity = 0;
      this._youtube.mesh.material.opacity = 0;
    });

    this.tween.start();
  };
  displayYoutube = () => {
    this._twitch.mesh.position.copy(this._twitch.positionHidden);
    this._youtube.mesh.position.copy(this._youtube.positionHidden);

    this._pickAPlaylist.mesh.position.copy(this._pickAPlaylist.position);
    this._oauthServerNET.mesh.position.copy(this._oauthServerNET.position);
    this._gemWars.mesh.position.copy(this._gemWars.position);
    this._theMonitaur.mesh.position.copy(this._theMonitaur.position);
    this._allieChat.mesh.position.copy(this._allieChat.position);
    this._tintb.mesh.position.copy(this._tintb.position);
    this._openDJRadio.mesh.position.copy(this._openDJRadio.position);

    this._pickAPlaylist.mesh.material.opacity = 1;
    this._oauthServerNET.mesh.material.opacity = 1;
    this._gemWars.mesh.material.opacity = 1;
    this._theMonitaur.mesh.material.opacity = 1;
    this._allieChat.mesh.material.opacity = 1;
    this._tintb.mesh.material.opacity = 1;
    this._openDJRadio.mesh.material.opacity = 1;
   
    this._menu.mesh.position.copy(this._menu.position);
    this._menu.mesh.material.opacity = 1;
  };
  fadeOutYoutube = () => {
    this._twitch.mesh.position.copy(this._twitch.positionHidden);
    this._youtube.mesh.position.copy(this._youtube.positionHidden);

    this._pickAPlaylist.mesh.position.copy(this._pickAPlaylist.position);
    this._oauthServerNET.mesh.position.copy(this._oauthServerNET.position);
    this._gemWars.mesh.position.copy(this._gemWars.position);
    this._theMonitaur.mesh.position.copy(this._theMonitaur.position);
    this._allieChat.mesh.position.copy(this._allieChat.position);
    this._tintb.mesh.position.copy(this._tintb.position);
    this._openDJRadio.mesh.position.copy(this._openDJRadio.position);
  
    this._pickAPlaylist.mesh.material.opacity = 0.65;
    this._oauthServerNET.mesh.material.opacity = 0.65;
    this._gemWars.mesh.material.opacity = 0.65;
    this._theMonitaur.mesh.material.opacity = 0.65;
    this._allieChat.mesh.material.opacity = 0.65;
    this._tintb.mesh.material.opacity = 0.65;
    this._openDJRadio.mesh.material.opacity = 0.65;
   
    this._menu.mesh.position.copy(this._menu.position);

    this.tween = new TWEEN.Tween({ emissivity: 0.65, opacity: 1 })
      .to({ emissivity: 0, opacity: 0 }, 500)
      .easing(TWEEN.Easing.Quadratic.Out);

    this.tween.onUpdate((object, elapsed) => {
      this._screen.material.emissiveIntensity = object.emissivity;

      this._pickAPlaylist.mesh.material.opacity = object.opacity;
      this._oauthServerNET.mesh.material.opacity = object.opacity;
      this._gemWars.mesh.material.opacity = object.opacity;
      this._theMonitaur.mesh.material.opacity = object.opacity;
      this._allieChat.mesh.material.opacity = object.opacity;
      this._tintb.mesh.material.opacity = object.opacity;
      this._openDJRadio.mesh.material.opacity = object.opacity;

      this._menu.mesh.material.opacity = object.opacity;
    });

    this.tween.onComplete((object) => {
      this.tween = undefined;

      this._screen.material.emissiveIntensity = 0;

      this._pickAPlaylist.mesh.material.opacity = 0;
      this._oauthServerNET.mesh.material.opacity = 0;
      this._gemWars.mesh.material.opacity = 0;
      this._theMonitaur.mesh.material.opacity = 0;
      this._allieChat.mesh.material.opacity = 0;
      this._tintb.mesh.material.opacity = 0;
      this._openDJRadio.mesh.material.opacity = 0;

      this._menu.mesh.position.copy(this._menu.positionHidden);
    });

    this.tween.start();
  };
  programSelected = (detail) => {
    switch (detail.items[0].name) {
      case "Menu":
        if (this._state === "youtube" || this._state === "playing") {
          this._screen.material.emissiveIntensity = 0.65;
          this.setState("menu");
          this._params.tvIFrameGO.setIFrame("about:blank");
        }
        break;
      case "Twitch":
        this.setState("playing");
        this._params.tvIFrameGO.setIFrame(
          `https://player.twitch.tv?channel=liveordevtrying&parent=${window.location.host}`
        );
        break;
      case "YouTube":
        this.setState("youtube");
        break;
      case "OAuthServerNET":
        this.setState("playing");
        this._params.tvIFrameGO.setIFrame(
          "https://www.youtube.com/embed/videoseries?list=PLY6uIl_kmpxl3inPqmfSATXnVXz2RwG-B"
        );
        break;
      case "GemWars":
        this.setState("playing");
        this._params.tvIFrameGO.setIFrame(
          "https://www.youtube.com/embed/videoseries?list=PLY6uIl_kmpxmddR2GLodJfjTTCv2tmMJ8"
        );
        break;
      case "TheMonitaur":
        this.setState("playing");
        this._params.tvIFrameGO.setIFrame(
          "https://www.youtube.com/embed/videoseries?list=PLY6uIl_kmpxliSaTPYKEoLZsntzImUN7-"
        );
        break;
      case "AllieChat":
        this.setState("playing");
        this._params.tvIFrameGO.setIFrame(
          "https://www.youtube.com/embed/videoseries?list=PLY6uIl_kmpxk4aSMtpM9tRZcDBZcHeyA-"
        );
        break;
      case "TINTB":
        this.setState("playing");
        this._params.tvIFrameGO.setIFrame(
          "https://www.youtube.com/embed/videoseries?list=PLY6uIl_kmpxnrz_Cvl9pRUaIhyDla72h_"
        );
        break;
      case "OpenDJRadio":
        this.setState("playing");
        this._params.tvIFrameGO.setIFrame(
          "https://www.youtube.com/embed/videoseries?list=PLY6uIl_kmpxnMCM1l0lYYUoiDzjiOIBG8"
        );
        break;
      default:
        break;
    }
  }
  setIsZoomedIn = (isZoomedIn) => {
    this._isZoomedIn = isZoomedIn;

    if (!this._isZoomedIn) {
      this._menu.mesh.position.copy(this._menu.positionHidden);
    } else if (this._state === "playing") {
      this._menu.mesh.position.copy(this._menu.position);
    }
  }
  setCanBeSelected = (canBeSelected) => {
    this._canBeSelected = canBeSelected;
  }
  reset = () => {
    this.setCanBeSelected(true);
    this.setIsZoomedIn(false);

    if (this._state === "menu" || this._state === "youtube") {
      this.setState("off");
    }
  }
  dispatchStateChange = () => {
    this._params.actionEvent({action: "tv", state: this._state});
  }
  dispose() {
    window.removeEventListener("blur", () => this.onIFrameClicked());
    super.dispose();
  }
  get state() {
    return this._state;
  }
  get mesh() {
    return this._mesh;
  }
  get youtube() {
    return this._youtube;
  }
  get twitch() {
    return this._twitch;
  }
  get menu() {
    return this._menu;
  }
  get oauthServerNET() {
    return this._oauthServerNET;
  }
  get gemWars() {
    return this._gemWars;
  }
  get theMonitaur() {
    return this._theMonitaur;
  }
  get allieChat() {
    return this._allieChat;
  }
  get tintb() {
    return this._tintb;
  }
  get openDJRadio() {
    return this._openDJRadio;
  }
}
export default TVGO;
