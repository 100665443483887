import React from "react";
import "./GemWars.scss";

class GemWars extends React.Component {
  componentDidMount = async () => {
    await this.loadUnityInstance();
  }
  restartClicked = async () => {
    this.props.restartClicked();
    this._unityInstance.Quit();

    await this.loadUnityInstance();
  };
  backClicked = () => {
    this.props.backClicked();
  };
  loadUnityInstance = async () => {
    this._unityInstance = await window.createUnityInstance(document.querySelector("#gemwars-canvas"), {
        dataUrl: "https://robthegamedev.s3.us-west-2.amazonaws.com/Games/GemWars/Build/Build.data.br",
        frameworkUrl: "https://robthegamedev.s3.us-west-2.amazonaws.com/Games/GemWars/Build/Build.framework.js.br",
        codeUrl: "https://robthegamedev.s3.us-west-2.amazonaws.com/Games/GemWars/Build/Build.wasm.br",
        streamingAssetsUrl: "https://robthegamedev.s3.us-west-2.amazonaws.com/Games/GemWars/StreamingAssets",
        companyName: "Pixel Horror Studios",
        productName: "Gem Wars",
        productVersion: "0.1",
        // matchWebGLToCanvasSize: false, // Uncomment this to separately control WebGL canvas render size and DOM element size.
        // devicePixelRatio: 1, // Uncomment this to override low DPI rendering on high DPI displays.
    }, (progress) => {
        if (progress === 1) {
            this.props.loaded();
        }
    });
  }
  render = () => {
    return (
      <div className="gemwars-canvas-container">
        <canvas id="gemwars-canvas" width={340} height={580}></canvas>
        <div className="gemwars-actions-container">
          <button
            type="button"
            className="btn-menu"
            onClick={this.restartClicked}
          >
            Restart to Title
          </button>
          <button type="button" className="btn-menu" onClick={this.backClicked}>
            Back
          </button>
        </div>
      </div>
    );
  };
}
export default GemWars;
