import * as THREE from "three";

class PaintingTINTB {
  constructor(mesh) {
    window.addEventListener("focus", this.onWindowFocus);

    this._mesh = mesh;
    this._startingPosition = new THREE.Vector3(6.05, 4.65, 6.15);
    this._startingLookAt = new THREE.Vector3(6.05, 4.65, -20);

    this._mesh.position.copy(this._startingPosition);
    this._mesh.lookAt(this._startingLookAt);

    this._mesh.traverse((x) => {
      if (x instanceof THREE.Mesh) {
        switch (x.material.name) {
          case "TINTB_PaintingTexture":
            this._paintingTexture = x;
            this._paintingMaterialOrig = x.material;
            this._paintingMaterial = new THREE.MeshBasicMaterial({
              map: x.material.map,
              name: x.name,
            });
            break;
          case "VisitTheSite":
          case "GetItOnSteam":
          case "GetItOnGooglePlay":
            if (x.material.name === "VisitTheSite") {
              this._visitTheSite = x;
              x.visible = false;
            } else if (x.material.name === "GetItOnSteam") {
              this._getItOnSteam = x;
              x.visible = false;
            } else if (x.material.name === "GetItOnGooglePlay") {
              this._getItOnGooglePlay = x;
              x.visible = false;
            } 

            const mat = new THREE.MeshBasicMaterial({
              map: x.material.map,
              name: x.name,
            });
            x.material.dispose();
            x.material = mat;
            break;
          default:
            break;
        }
      }
    });
    
    const video = document.getElementById('tintb_video');
    const videoTexture = new THREE.VideoTexture(video);
    videoTexture.encoding = THREE.sRGBEncoding;
    videoTexture.flipY = false;
    videoTexture.needsUpdate = true;
    this._videoMaterial =  new THREE.MeshBasicMaterial( {map: videoTexture} );
    this._videoMaterial.needsUpdate = true;
  }
  setLinksActive = (isActive) => {
    this._visitTheSite.visible = isActive;
    this._getItOnSteam.visible = isActive;
    this._getItOnGooglePlay.visible = isActive;

    const video = document.getElementById('tintb_video');

    if (isActive) {
      this._paintingTexture.material = this._videoMaterial;
      video.play();
      this._isPlaying = true;
    } else {
      this._paintingTexture.material = this._paintingMaterial;
      video.pause();
      this._isPlaying = false;
    }
  };
  pauseVideo = () => {
    document.getElementById('tintb_video').pause();
  };
  onWindowFocus = () => {
    if (this._isPlaying) {
      document.getElementById('tintb_video').play();
    }
  };
  setMaterial = (isShowingPaining) => {
    this._paintingTexture.material = isShowingPaining ? this._paintingMaterial : this._paintingMaterialOrig;
  };
  getLinkUrl = (mesh) => {
    switch (mesh.name) {
      case this._visitTheSite.name:
        return "https://www.noturningbackgame.com";
      case this._getItOnSteam.name:
        return "https://store.steampowered.com/app/956350/There_Is_No_Turning_Back";
      case this._getItOnGooglePlay.name:
        return "https://play.google.com/store/apps/details?id=com.pixelhorrorstudios.thereisnoturningback";
      default:
        break;
    }
  };
  dispose = () => {
    window.removeEventListener("focus", this.onWindowFocus);
  }
  get name() {
    return "TINTB";
  }
  get mesh() {
    return this._mesh;
  }
  get visitTheSite() {
    return this._visitTheSite;
  }
  get getItOnSteam() {
    return this._getItOnSteam;
  }
  get getItOnGooglePlay() {
    return this._getItOnGooglePlay;
  }
  get startingPosition() {
    return this._startingPosition;
  }
  get startingLookAt() {
    return this._startingLookAt;
  }
}
export default PaintingTINTB;
