import "./Menu.scss";
import React from "react";
import ArtBackWallGO from "./Objects/GameObjects/ArtBackWallGO";
import ReyGO from "./Objects/GameObjects/ReyGO";
import BookshelfGO from "./Objects/GameObjects/BookshelfGO";
import PhoneGO from "./Objects/GameObjects/PhoneGO";
import BookshelfBooksGO from "./Objects/GameObjects/BookshelfBooksGO";
import BookGO from "./Objects/GameObjects/BookGO";
import ArtOpensourceGO from "./Objects/GameObjects/ArtOpensourceGO";
import SynthesizerGO from "./Objects/GameObjects/SynthesizerGO";
import MonitorGO from "./Objects/GameObjects/MonitorGO";
import TVGO from "./Objects/GameObjects/TVGO";

class Menu extends React.Component {
  onButtonHover = (e) => {
    this.props.buttonHover({ type: e.target.id });
  };
  onButtonLeave = (e) => {
    this.props.buttonLeave();
  };
  onButtonClick = (e) => {
    this.props.buttonClick({ type: e.target.id });
  };
  onObjectHoverEvent = (eventData) => {
    const elements = document.getElementsByClassName("hover");
    for (let i = 0; i < elements.length; i++) {
      elements[i].classList.remove("hover");
    }

    if (eventData) {
      if (eventData.source instanceof ReyGO) {
        if (eventData.items.length > 0 && this.props.location === "default") {
          document.getElementById("btn-rey").classList.add("hover");
        }
      } else if (eventData.source instanceof PhoneGO) {
        if (eventData.items.length > 0 && this.props.location === "default") {
          document.getElementById("btn-contact").classList.add("hover");
        }
      } else if (eventData.source instanceof SynthesizerGO) {
        if (eventData.items.length > 0 && this.props.location === "default") {
          document.getElementById("btn-piano").classList.add("hover");
        }
      } else if (eventData.source instanceof MonitorGO) {
        if (eventData.items.length > 0) {
          switch (eventData.items[0].name) {
            case "SeeWhatIsInTheWorks":
              if (this.props.location === "monitorOff") {
                document
                  .getElementById("btn-monitor-play-gemwars")
                  .classList.add("hover");
              }
              break;
            case "Back":
              if (this.props.location === "monitorOn") {
                document
                  .getElementById("btn-monitor-stop")
                  .classList.add("hover");
              }
              break;
            default:
              if (this.props.location === "default") {
                document.getElementById("btn-monitor").classList.add("hover");
              }
              break;
          }
        }
      } else if (eventData.source instanceof TVGO) {
        if (eventData.items.length > 0) {
          switch (eventData.items[0].name) {
            case "Twitch":
              if (this.props.location === "tvMenu") {
                document.getElementById("btn-tv-twitch").classList.add("hover");
              }
              break;
            case "YouTube":
              if (this.props.location === "tvMenu") {
                document
                  .getElementById("btn-tv-youtube")
                  .classList.add("hover");
              }
              break;
            case "Menu":
              if (
                this.props.location === "tvPlaying" ||
                this.props.location === "tvYouTube"
              ) {
                document.getElementById("btn-tv-menu").classList.add("hover");
              }
              break;
            case "OAuthServerNET":
              if (this.props.location === "tvYouTube") {
                document
                  .getElementById("btn-tv-youtube-oauthserver.net")
                  .classList.add("hover");
              }
              break;
            case "GemWars":
              if (this.props.location === "tvYouTube") {
                document
                  .getElementById("btn-tv-youtube-gemwars")
                  .classList.add("hover");
              }
              break;
            case "TheMonitaur":
              if (this.props.location === "tvYouTube") {
                document
                  .getElementById("btn-tv-youtube-themonitaur")
                  .classList.add("hover");
              }
              break;
            case "AllieChat":
              if (this.props.location === "tvYouTube") {
                document
                  .getElementById("btn-tv-youtube-alliechat")
                  .classList.add("hover");
              }
              break;
            case "TINTB":
              if (this.props.location === "tvYouTube") {
                document
                  .getElementById("btn-tv-youtube-tintb")
                  .classList.add("hover");
              }
              break;
            case "OpenDJRadio":
              if (this.props.location === "tvYouTube") {
                document
                  .getElementById("btn-tv-youtube-opendjradio")
                  .classList.add("hover");
              }
              break;
            default:
              if (this.props.location === "default") {
                document.getElementById("btn-videos").classList.add("hover");
              }
              break;
          }
        }
      } else if (eventData.source instanceof BookshelfGO) {
        if (eventData.items.length > 0 && this.props.location === "default") {
          document.getElementById("btn-packages").classList.add("hover");
        }
      } else if (eventData.source instanceof BookshelfBooksGO) {
        if (
          this.props.gameManager.state !== "bookcase" &&
          this.props.location === "default"
        ) {
          document.getElementById("btn-packages").classList.add("hover");
        } else {
          if (eventData.items.length > 0) {
            if (this.props.location === "bookcase") {
              switch (eventData.items[0].parent.name) {
                case "alliechat":
                  document
                    .getElementById("btn-bookcase-alliechat")
                    .classList.add("hover");
                  break;
                case "jwts":
                  document
                    .getElementById("btn-bookcase-jwts")
                    .classList.add("hover");
                  break;
                case "ntiernet":
                  document
                    .getElementById("btn-bookcase-ntier.net")
                    .classList.add("hover");
                  break;
                case "oauthservernet":
                  document
                    .getElementById("btn-bookcase-oauthserver.net")
                    .classList.add("hover");
                  break;
                case "paypalnet":
                  document
                    .getElementById("btn-bookcase-paypal.net")
                    .classList.add("hover");
                  break;
                case "phscore":
                  document
                    .getElementById("btn-bookcase-phs.core")
                    .classList.add("hover");
                  break;
                case "sitemapsnet":
                  document
                    .getElementById("btn-bookcase-sitemaps.net")
                    .classList.add("hover");
                  break;
                case "steamnet":
                  document
                    .getElementById("btn-bookcase-steam.net")
                    .classList.add("hover");
                  break;
                case "tcpnet":
                  document
                    .getElementById("btn-bookcase-tcp.net")
                    .classList.add("hover");
                  break;
                case "themonitaur":
                  document
                    .getElementById("btn-bookcase-themonitaur")
                    .classList.add("hover");
                  break;
                case "twitchbotsnet":
                  document
                    .getElementById("btn-bookcase-twitchbots.net")
                    .classList.add("hover");
                  break;
                case "websocketssimple":
                  document
                    .getElementById("btn-bookcase-websocketssimple")
                    .classList.add("hover");
                  break;
                default:
                  break;
              }
            }
          }
        }
      } else if (eventData.source instanceof BookGO) {
        if (
          eventData.items.length > 0 &&
          (this.props.location === "showBookjwts" ||
            this.props.location === "showBookoauthserver.net" ||
            this.props.location === "showBookwebsocketssimple" ||
            this.props.location === "showBooktcp.net" ||
            this.props.location === "showBooksteam.net" ||
            this.props.location === "showBooksitemaps.net" ||
            this.props.location === "showBookntier.net" ||
            this.props.location === "showBookpaypal.net" ||
            this.props.location === "showBookthemonitaur" ||
            this.props.location === "showBookallie.chat" ||
            this.props.location === "showBookphs.core" ||
            this.props.location === "showBooktwitchbots.net")
        ) {
          switch (eventData.items[0].name) {
            case "NuGet":
              document
                .getElementById("btn-bookcase-book-nuget")
                .classList.add("hover");
              break;
            case "GitHub":
              document
                .getElementById("btn-bookcase-book-github")
                .classList.add("hover");
              break;
            case "Extra":
              switch (eventData.source.book.bookType) {
                case "themonitaur":
                case "allie.chat":
                  document
                    .getElementById("btn-bookcase-book-site")
                    .classList.add("hover");
                  break;
                case "oauthserver.net":
                  document
                    .getElementById("btn-bookcase-book-docker")
                    .classList.add("hover");
                  break;
                default:
                  break;
              }
              break;
            default:
              break;
          }
        }
      } else if (
        eventData.source instanceof ArtOpensourceGO &&
        eventData.items.length > 0
      ) {
        switch (eventData.items[0].parent.name) {
          case "Scene":
            if (this.props.location === "default") {
              document.getElementById("btn-opensource").classList.add("hover");
            }
            break;
          case "NuGet":
            switch (eventData.items[0].name) {
              case "NuGet_TC":
                if (this.props.location === "showPaintingNuGet") {
                  document
                    .getElementById("btn-opensource-nuget-site")
                    .classList.add("hover");
                }
                break;
              default:
                if (this.props.location === "artOpensource") {
                  document
                    .getElementById("btn-opensource-nuget")
                    .classList.add("hover");
                }
                break;
            }
            break;
          case "GitHub":
            switch (eventData.items[0].name) {
              case "GitHub_TC001":
                if (this.props.location === "showPaintingGitHub") {
                  document
                    .getElementById("btn-opensource-github-site")
                    .classList.add("hover");
                }
                break;
              default:
                if (this.props.location === "artOpensource") {
                  document
                    .getElementById("btn-opensource-github")
                    .classList.add("hover");
                }
                break;
            }
            break;
          default:
            break;
        }
      } else if (
        eventData.source instanceof ArtBackWallGO &&
        eventData.items.length > 0
      ) {
        if (eventData.items[0].parent) {
          switch (eventData.items[0].parent.name) {
            case "Scene":
              if (this.props.location === "default") {
                document.getElementById("btn-projects").classList.add("hover");
              }
              break;
            case "TINTB":
              switch (eventData.items[0].name) {
                case "TINTB_TL001":
                  if (this.props.location === "showPaintingTINTB") {
                    document
                      .getElementById("btn-projects-tintb-steam")
                      .classList.add("hover");
                  }
                  break;
                case "TINTB_TC001":
                  if (this.props.location === "showPaintingTINTB") {
                    document
                      .getElementById("btn-projects-tintb-site")
                      .classList.add("hover");
                  }
                  break;
                case "TINTB_TR001":
                  if (this.props.location === "showPaintingTINTB") {
                    document
                      .getElementById("btn-projects-tintb-googlePlay")
                      .classList.add("hover");
                  }
                  break;
                default:
                  if (this.props.location === "artBackWall") {
                    document
                      .getElementById("btn-projects-tintb")
                      .classList.add("hover");
                  }
                  break;
              }
              break;
            case "GemWars":
              if (this.props.location === "artBackWall") {
                document
                  .getElementById("btn-projects-gemwars")
                  .classList.add("hover");
              }
              break;
            case "OpenDJRadio":
              switch (eventData.items[0].name) {
                case "OpenDJRadio_TC":
                  if (this.props.location === "showPaintingOpenDJRadio") {
                    document
                      .getElementById("btn-projects-opendjradio-site")
                      .classList.add("hover");
                  }
                  break;
                default:
                  if (this.props.location === "artBackWall") {
                    document
                      .getElementById("btn-projects-opendjradio")
                      .classList.add("hover");
                  }
                  break;
              }
              break;
            case "TheMonitaur":
              switch (eventData.items[0].name) {
                case "TheMonitaur_TC":
                  if (this.props.location === "showPaintingTheMonitaur") {
                    document
                      .getElementById("btn-projects-themonitaur-site")
                      .classList.add("hover");
                  }
                  break;
                default:
                  if (this.props.location === "artBackWall") {
                    document
                      .getElementById("btn-projects-themonitaur")
                      .classList.add("hover");
                  }
                  break;
              }
              break;
            case "GoldBunnyStore":
              switch (eventData.items[0].name) {
                case "GoldBunnyStore_TC":
                  if (this.props.location === "showPaintingGoldBunnyStore") {
                    document
                      .getElementById("btn-projects-goldbunnystore-site")
                      .classList.add("hover");
                  }
                  break;
                default:
                  if (this.props.location === "artBackWall") {
                    document
                      .getElementById("btn-projects-goldbunnystore")
                      .classList.add("hover");
                  }
                  break;
              }
              break;
            case "AllieChat":
              switch (eventData.items[0].name) {
                case "AllieChat_TC":
                  if (this.props.location === "showPaintingAllieChat") {
                    document
                      .getElementById("btn-projects-alliechat-site")
                      .classList.add("hover");
                  }
                  break;
                default:
                  if (this.props.location === "artBackWall") {
                    document
                      .getElementById("btn-projects-alliechat")
                      .classList.add("hover");
                  }
                  break;
              }
              break;
            case "PHS":
              switch (eventData.items[0].name) {
                case "PHS_TC":
                  if (this.props.location === "showPaintingPHS") {
                    document
                      .getElementById("btn-projects-phs-site")
                      .classList.add("hover");
                  }
                  break;
                default:
                  if (this.props.location === "artBackWall") {
                    document
                      .getElementById("btn-projects-phs")
                      .classList.add("hover");
                  }
                  break;
              }
              break;
            default:
              break;
          }
        }
      }
    }
  };
  menu = () => {
    if (this.props.location) {
      switch (this.props.location) {
        case "default":
          return (
            <div className="menu-block">
              <button
                className="btn-menu"
                type="button"
                id="btn-projects"
                onMouseOver={this.onButtonHover}
                onMouseLeave={this.onButtonLeave}
                onClick={this.onButtonClick}
              >
                Projects
              </button>
              <button
                className="btn-menu"
                type="button"
                id="btn-packages"
                onMouseOver={this.onButtonHover}
                onMouseLeave={this.onButtonLeave}
                onClick={this.onButtonClick}
              >
                Packages
              </button>
              <button
                className="btn-menu"
                type="button"
                id="btn-videos"
                onMouseOver={this.onButtonHover}
                onMouseLeave={this.onButtonLeave}
                onClick={this.onButtonClick}
              >
                Videos
              </button>
              <button
                className="btn-menu"
                type="button"
                id="btn-monitor"
                onMouseOver={this.onButtonHover}
                onMouseLeave={this.onButtonLeave}
                onClick={this.onButtonClick}
              >
                Play Games
              </button>
              <button
                className="btn-menu"
                type="button"
                id="btn-opensource"
                onMouseOver={this.onButtonHover}
                onMouseLeave={this.onButtonLeave}
                onClick={this.onButtonClick}
              >
                Opensource
              </button>
              <button
                className="btn-menu"
                type="button"
                id="btn-piano"
                onMouseOver={this.onButtonHover}
                onMouseLeave={this.onButtonLeave}
                onClick={this.onButtonClick}
              >
                Piano
              </button>
              <button
                className="btn-menu"
                type="button"
                id="btn-rey"
                onMouseOver={this.onButtonHover}
                onMouseLeave={this.onButtonLeave}
                onClick={this.onButtonClick}
              >
                Rey
              </button>
              <button
                className="btn-menu"
                type="button"
                id="btn-contact"
                onMouseOver={this.onButtonHover}
                onMouseLeave={this.onButtonLeave}
                onClick={this.onButtonClick}
              >
                Contact Me
              </button>
            </div>
          );
        case "artBackWall":
          return (
            <div className="menu-block">
              <button
                className="btn-menu"
                type="button"
                id="btn-projects-tintb"
                onMouseOver={this.onButtonHover}
                onMouseLeave={this.onButtonLeave}
                onClick={this.onButtonClick}
              >
                There Is No Turning Back!
              </button>
              <button
                className="btn-menu"
                type="button"
                id="btn-projects-gemwars"
                onMouseOver={this.onButtonHover}
                onMouseLeave={this.onButtonLeave}
                onClick={this.onButtonClick}
              >
                Gem Wars
              </button>
              <button
                className="btn-menu"
                type="button"
                id="btn-projects-themonitaur"
                onMouseOver={this.onButtonHover}
                onMouseLeave={this.onButtonLeave}
                onClick={this.onButtonClick}
              >
                TheMonitaur
              </button>
              <button
                className="btn-menu"
                type="button"
                id="btn-projects-alliechat"
                onMouseOver={this.onButtonHover}
                onMouseLeave={this.onButtonLeave}
                onClick={this.onButtonClick}
              >
                Allie.Chat
              </button>
              <button
                className="btn-menu"
                type="button"
                id="btn-projects-opendjradio"
                onMouseOver={this.onButtonHover}
                onMouseLeave={this.onButtonLeave}
                onClick={this.onButtonClick}
              >
                OpenDJRadio
              </button>
              <button
                className="btn-menu"
                type="button"
                id="btn-projects-phs"
                onMouseOver={this.onButtonHover}
                onMouseLeave={this.onButtonLeave}
                onClick={this.onButtonClick}
              >
                PixelHorrorStudios
              </button>
              <button
                className="btn-menu"
                type="button"
                id="btn-projects-goldbunnystore"
                onMouseOver={this.onButtonHover}
                onMouseLeave={this.onButtonLeave}
                onClick={this.onButtonClick}
              >
                GoldBunnyStore
              </button>
              <button
                className="btn-menu"
                type="button"
                id="btn-projects-back"
                onMouseOver={this.onButtonHover}
                onMouseLeave={this.onButtonLeave}
                onClick={this.props.gameManager.onResetScene}
              >
                Back
              </button>
            </div>
          );
        case "artOpensource":
          return (
            <div className="menu-block">
              <button
                className="btn-menu"
                type="button"
                id="btn-opensource-github"
                onMouseOver={this.onButtonHover}
                onMouseLeave={this.onButtonLeave}
                onClick={this.onButtonClick}
              >
                GitHub
              </button>
              <button
                className="btn-menu"
                type="button"
                id="btn-opensource-nuget"
                onMouseOver={this.onButtonHover}
                onMouseLeave={this.onButtonLeave}
                onClick={this.onButtonClick}
              >
                NuGet
              </button>
              <button
                className="btn-menu"
                type="button"
                id="btn-opensource-back"
                onMouseOver={this.onButtonHover}
                onMouseLeave={this.onButtonLeave}
                onClick={this.props.gameManager.onResetScene}
              >
                Back
              </button>
            </div>
          );
        case "showPaintingTINTB":
          return (
            <div className="menu-block">
              <button
                className="btn-menu"
                type="button"
                id="btn-projects-tintb-steam"
                onMouseOver={this.onButtonHover}
                onMouseLeave={this.onButtonLeave}
                onClick={this.onButtonClick}
              >
                Get It On Steam
              </button>
              <button
                className="btn-menu"
                type="button"
                id="btn-projects-tintb-site"
                onMouseOver={this.onButtonHover}
                onMouseLeave={this.onButtonLeave}
                onClick={this.onButtonClick}
              >
                Visit the Site
              </button>
              <button
                className="btn-menu"
                type="button"
                id="btn-projects-tintb-googlePlay"
                onMouseOver={this.onButtonHover}
                onMouseLeave={this.onButtonLeave}
                onClick={this.onButtonClick}
              >
                Get It on Google Play
              </button>
              <button
                className="btn-menu"
                type="button"
                id="btn-projects-back"
                onMouseOver={this.onButtonHover}
                onMouseLeave={this.onButtonLeave}
                onClick={this.props.gameManager.onPaintingReset}
              >
                Back
              </button>
            </div>
          );
        case "showPaintingGemWars":
          return (
            <div className="menu-block">
              <button
                className="btn-menu"
                type="button"
                id="btn-projects-gemwars-back"
                onMouseOver={this.onButtonHover}
                onMouseLeave={this.onButtonLeave}
                onClick={this.props.gameManager.onPaintingReset}
              >
                Back
              </button>
            </div>
          );
        case "showPaintingOpenDJRadio":
          return (
            <div className="menu-block">
              <button
                className="btn-menu"
                type="button"
                id="btn-projects-opendjradio-site"
                onMouseOver={this.onButtonHover}
                onMouseLeave={this.onButtonLeave}
                onClick={this.onButtonClick}
              >
                Visit the Site
              </button>
              <button
                className="btn-menu"
                type="button"
                id="btn-projects-back"
                onMouseOver={this.onButtonHover}
                onMouseLeave={this.onButtonLeave}
                onClick={this.props.gameManager.onPaintingReset}
              >
                Back
              </button>
            </div>
          );
        case "showPaintingTheMonitaur":
          return (
            <div className="menu-block">
              <button
                className="btn-menu"
                type="button"
                id="btn-projects-themonitaur-site"
                onMouseOver={this.onButtonHover}
                onMouseLeave={this.onButtonLeave}
                onClick={this.onButtonClick}
              >
                Visit the Site
              </button>
              <button
                className="btn-menu"
                type="button"
                id="btn-projects-back"
                onMouseOver={this.onButtonHover}
                onMouseLeave={this.onButtonLeave}
                onClick={this.props.gameManager.onPaintingReset}
              >
                Back
              </button>
            </div>
          );
        case "showPaintingGoldBunnyStore":
          return (
            <div className="menu-block">
              <button
                className="btn-menu"
                type="button"
                id="btn-projects-goldbunnystore-site"
                onMouseOver={this.onButtonHover}
                onMouseLeave={this.onButtonLeave}
                onClick={this.onButtonClick}
              >
                Visit the Site
              </button>
              <button
                className="btn-menu"
                type="button"
                id="btn-projects-back"
                onMouseOver={this.onButtonHover}
                onMouseLeave={this.onButtonLeave}
                onClick={this.props.gameManager.onPaintingReset}
              >
                Back
              </button>
            </div>
          );
        case "showPaintingAllieChat":
          return (
            <div className="menu-block">
              <button
                className="btn-menu"
                type="button"
                id="btn-projects-alliechat-site"
                onMouseOver={this.onButtonHover}
                onMouseLeave={this.onButtonLeave}
                onClick={this.onButtonClick}
              >
                Visit the Site
              </button>
              <button
                className="btn-menu"
                type="button"
                id="btn-projects-back"
                onMouseOver={this.onButtonHover}
                onMouseLeave={this.onButtonLeave}
                onClick={this.props.gameManager.onPaintingReset}
              >
                Back
              </button>
            </div>
          );
        case "showPaintingPHS":
          return (
            <div className="menu-block">
              <button
                className="btn-menu"
                type="button"
                id="btn-projects-phs-site"
                onMouseOver={this.onButtonHover}
                onMouseLeave={this.onButtonLeave}
                onClick={this.onButtonClick}
              >
                Visit the Site
              </button>
              <button
                className="btn-menu"
                type="button"
                id="btn-projects-back"
                onMouseOver={this.onButtonHover}
                onMouseLeave={this.onButtonLeave}
                onClick={this.props.gameManager.onPaintingReset}
              >
                Back
              </button>
            </div>
          );
        case "showPaintingNuGet":
          return (
            <div className="menu-block">
              <button
                className="btn-menu"
                type="button"
                id="btn-opensource-nuget-site"
                onMouseOver={this.onButtonHover}
                onMouseLeave={this.onButtonLeave}
                onClick={this.onButtonClick}
              >
                Visit the Site
              </button>
              <button
                className="btn-menu"
                type="button"
                id="btn-opensource-back"
                onMouseOver={this.onButtonHover}
                onMouseLeave={this.onButtonLeave}
                onClick={this.props.gameManager.onPaintingReset}
              >
                Back
              </button>
            </div>
          );
        case "showPaintingGitHub":
          return (
            <div className="menu-block">
              <button
                className="btn-menu"
                type="button"
                id="btn-opensource-github-site"
                onMouseOver={this.onButtonHover}
                onMouseLeave={this.onButtonLeave}
                onClick={this.onButtonClick}
              >
                Visit the Site
              </button>
              <button
                className="btn-menu"
                type="button"
                id="btn-opensource-back"
                onMouseOver={this.onButtonHover}
                onMouseLeave={this.onButtonLeave}
                onClick={this.props.gameManager.onPaintingReset}
              >
                Back
              </button>
            </div>
          );
        case "bookcase":
          return (
            <div className="menu-block">
              <button
                className="btn-menu"
                type="button"
                id="btn-bookcase-alliechat"
                onMouseOver={this.onButtonHover}
                onMouseLeave={this.onButtonLeave}
                onClick={this.onButtonClick}
              >
                Allie.Chat
              </button>
              <button
                className="btn-menu"
                type="button"
                id="btn-bookcase-jwts"
                onMouseOver={this.onButtonHover}
                onMouseLeave={this.onButtonLeave}
                onClick={this.onButtonClick}
              >
                JWTs
              </button>
              <button
                className="btn-menu"
                type="button"
                id="btn-bookcase-ntier.net"
                onMouseOver={this.onButtonHover}
                onMouseLeave={this.onButtonLeave}
                onClick={this.onButtonClick}
              >
                NTier.NET
              </button>
              <button
                className="btn-menu"
                type="button"
                id="btn-bookcase-oauthserver.net"
                onMouseOver={this.onButtonHover}
                onMouseLeave={this.onButtonLeave}
                onClick={this.onButtonClick}
              >
                OAuthServer.NET
              </button>
              <button
                className="btn-menu"
                type="button"
                id="btn-bookcase-paypal.net"
                onMouseOver={this.onButtonHover}
                onMouseLeave={this.onButtonLeave}
                onClick={this.onButtonClick}
              >
                Paypal.NET
              </button>
              <button
                className="btn-menu"
                type="button"
                id="btn-bookcase-phs.core"
                onMouseOver={this.onButtonHover}
                onMouseLeave={this.onButtonLeave}
                onClick={this.onButtonClick}
              >
                PHS.Core
              </button>
              <button
                className="btn-menu"
                type="button"
                id="btn-bookcase-sitemaps.net"
                onMouseOver={this.onButtonHover}
                onMouseLeave={this.onButtonLeave}
                onClick={this.onButtonClick}
              >
                SiteMaps.NET
              </button>
              <button
                className="btn-menu"
                type="button"
                id="btn-bookcase-steam.net"
                onMouseOver={this.onButtonHover}
                onMouseLeave={this.onButtonLeave}
                onClick={this.onButtonClick}
              >
                Steam.NET
              </button>
              <button
                className="btn-menu"
                type="button"
                id="btn-bookcase-tcp.net"
                onMouseOver={this.onButtonHover}
                onMouseLeave={this.onButtonLeave}
                onClick={this.onButtonClick}
              >
                Tcp.NET
              </button>
              <button
                className="btn-menu"
                type="button"
                id="btn-bookcase-themonitaur"
                onMouseOver={this.onButtonHover}
                onMouseLeave={this.onButtonLeave}
                onClick={this.onButtonClick}
              >
                TheMonitaur
              </button>
              <button
                className="btn-menu"
                type="button"
                id="btn-bookcase-twitchbots.net"
                onMouseOver={this.onButtonHover}
                onMouseLeave={this.onButtonLeave}
                onClick={this.onButtonClick}
              >
                TwitchBots.NET
              </button>
              <button
                className="btn-menu"
                type="button"
                id="btn-bookcase-websocketssimple"
                onMouseOver={this.onButtonHover}
                onMouseLeave={this.onButtonLeave}
                onClick={this.onButtonClick}
              >
                WebsocketsSimple
              </button>
              <button
                className="btn-menu"
                type="button"
                id="btn-bookcase-back.net"
                onMouseOver={this.onButtonHover}
                onMouseLeave={this.onButtonLeave}
                onClick={this.props.gameManager.onResetScene}
              >
                Back
              </button>
            </div>
          );
        case "showBookjwts":
        case "showBookwebsocketssimple":
        case "showBooktcp.net":
        case "showBooksteam.net":
        case "showBooksitemaps.net":
        case "showBookpaypal.net":
        case "showBookntier.net":
        case "showBookphs.core":
        case "showBooktwitchbots.net":
          return (
            <div className="menu-block">
              <button
                className="btn-menu"
                type="button"
                id="btn-bookcase-book-github"
                onMouseOver={this.onButtonHover}
                onMouseLeave={this.onButtonLeave}
                onClick={this.onButtonClick}
              >
                GitHub
              </button>
              <button
                className="btn-menu"
                type="button"
                id="btn-bookcase-book-nuget"
                onMouseOver={this.onButtonHover}
                onMouseLeave={this.onButtonLeave}
                onClick={this.onButtonClick}
              >
                NuGet
              </button>
              <button
                className="btn-menu"
                type="button"
                id="btn-bookcase-book-back"
                onMouseOver={this.onButtonHover}
                onMouseLeave={this.onButtonLeave}
                onClick={this.props.gameManager.onBookReset}
              >
                Back
              </button>
            </div>
          );
        case "showBookthemonitaur":
        case "showBookallie.chat":
          return (
            <div className="menu-block">
              <button
                className="btn-menu"
                type="button"
                id="btn-bookcase-book-site"
                onMouseOver={this.onButtonHover}
                onMouseLeave={this.onButtonLeave}
                onClick={this.onButtonClick}
              >
                Visit the Website
              </button>
              <button
                className="btn-menu"
                type="button"
                id="btn-bookcase-book-github"
                onMouseOver={this.onButtonHover}
                onMouseLeave={this.onButtonLeave}
                onClick={this.onButtonClick}
              >
                GitHub
              </button>
              <button
                className="btn-menu"
                type="button"
                id="btn-bookcase-book-nuget"
                onMouseOver={this.onButtonHover}
                onMouseLeave={this.onButtonLeave}
                onClick={this.onButtonClick}
              >
                NuGet
              </button>
              <button
                className="btn-menu"
                type="button"
                id="btn-bookcase-book-back"
                onMouseOver={this.onButtonHover}
                onMouseLeave={this.onButtonLeave}
                onClick={this.props.gameManager.onBookReset}
              >
                Back
              </button>
            </div>
          );
        case "showBookoauthserver.net":
          return (
            <div className="menu-block">
              <button
                className="btn-menu"
                type="button"
                id="btn-bookcase-book-docker"
                onMouseOver={this.onButtonHover}
                onMouseLeave={this.onButtonLeave}
                onClick={this.onButtonClick}
              >
                DockerHub
              </button>
              <button
                className="btn-menu"
                type="button"
                id="btn-bookcase-book-github"
                onMouseOver={this.onButtonHover}
                onMouseLeave={this.onButtonLeave}
                onClick={this.onButtonClick}
              >
                GitHub
              </button>
              <button
                className="btn-menu"
                type="button"
                id="btn-bookcase-book-nuget"
                onMouseOver={this.onButtonHover}
                onMouseLeave={this.onButtonLeave}
                onClick={this.onButtonClick}
              >
                NuGet
              </button>
              <button
                className="btn-menu"
                type="button"
                id="btn-bookcase-book-back"
                onMouseOver={this.onButtonHover}
                onMouseLeave={this.onButtonLeave}
                onClick={this.props.gameManager.onBookReset}
              >
                Back
              </button>
            </div>
          );
        case "synthesizer":
          return (
            <div className="menu-block">
              <button
                className="btn-menu"
                type="button"
                id="btn-synthesizer-stop"
                onMouseOver={this.onButtonHover}
                onMouseLeave={this.onButtonLeave}
                onClick={this.props.gameManager.onSynthesizerStop}
              >
                Stop Playing
              </button>
              <button
                className="btn-menu"
                type="button"
                id="btn-back"
                onMouseOver={this.onButtonHover}
                onMouseLeave={this.onButtonLeave}
                onClick={this.props.gameManager.onResetScene}
              >
                Back
              </button>
            </div>
          );
        case "rey":
          return (
            <div className="menu-block">
              <button
                className="btn-menu"
                type="button"
                id="btn-rey"
                onMouseOver={this.onButtonHover}
                onMouseLeave={this.onButtonLeave}
                onClick={this.onButtonClick}
              >
                Pet
              </button>
              <button
                className="btn-menu"
                type="button"
                id="btn-back"
                onMouseOver={this.onButtonHover}
                onMouseLeave={this.onButtonLeave}
                onClick={this.props.gameManager.onResetScene}
              >
                Back
              </button>
            </div>
          );
        case "monitorOff":
          return (
            <div className="menu-block">
              <button
                className="btn-menu"
                type="button"
                id="btn-monitor-play-gemwars"
                onMouseOver={this.onButtonHover}
                onMouseLeave={this.onButtonLeave}
                onClick={this.onButtonClick}
              >
                Play GemWars
              </button>
              <button
                className="btn-menu"
                type="button"
                id="btn-back"
                onMouseOver={this.onButtonHover}
                onMouseLeave={this.onButtonLeave}
                onClick={this.props.gameManager.onResetScene}
              >
                Back
              </button>
            </div>
          );
        case "monitorOn":
          return (
            <div className="menu-block">
              <button
                className="btn-menu"
                type="button"
                id="btn-monitor-stop"
                onMouseOver={this.onButtonHover}
                onMouseLeave={this.onButtonLeave}
                onClick={this.onButtonClick}
              >
                Stop
              </button>
              <button
                className="btn-menu"
                type="button"
                id="btn-back"
                onMouseOver={this.onButtonHover}
                onMouseLeave={this.onButtonLeave}
                onClick={this.props.gameManager.onResetScene}
              >
                Back
              </button>
            </div>
          );
        case "tvMenu":
          return (
            <div className="menu-block">
              <button
                className="btn-menu"
                type="button"
                id="btn-tv-twitch"
                onMouseOver={this.onButtonHover}
                onMouseLeave={this.onButtonLeave}
                onClick={this.onButtonClick}
              >
                Twitch
              </button>
              <button
                className="btn-menu"
                type="button"
                id="btn-tv-youtube"
                onMouseOver={this.onButtonHover}
                onMouseLeave={this.onButtonLeave}
                onClick={this.onButtonClick}
              >
                YouTube
              </button>
              <button
                className="btn-menu"
                type="button"
                id="btn-back"
                onMouseOver={this.onButtonHover}
                onMouseLeave={this.onButtonLeave}
                onClick={this.props.gameManager.onResetScene}
              >
                Back
              </button>
            </div>
          );
        case "tvYouTube":
          return (
            <div className="menu-block">
              <button
                className="btn-menu"
                type="button"
                id="btn-tv-youtube-alliechat"
                onMouseOver={this.onButtonHover}
                onMouseLeave={this.onButtonLeave}
                onClick={this.onButtonClick}
              >
                Allie.Chat
              </button>
              <button
                className="btn-menu"
                type="button"
                id="btn-tv-youtube-gemwars"
                onMouseOver={this.onButtonHover}
                onMouseLeave={this.onButtonLeave}
                onClick={this.onButtonClick}
              >
                Gem Wars
              </button>
              <button
                className="btn-menu"
                type="button"
                id="btn-tv-youtube-oauthserver.net"
                onMouseOver={this.onButtonHover}
                onMouseLeave={this.onButtonLeave}
                onClick={this.onButtonClick}
              >
                OAuthServer.NET
              </button>
              <button
                className="btn-menu"
                type="button"
                id="btn-tv-youtube-opendjradio"
                onMouseOver={this.onButtonHover}
                onMouseLeave={this.onButtonLeave}
                onClick={this.onButtonClick}
              >
                OpenDJRadio
              </button>
              <button
                className="btn-menu"
                type="button"
                id="btn-tv-youtube-tintb"
                onMouseOver={this.onButtonHover}
                onMouseLeave={this.onButtonLeave}
                onClick={this.onButtonClick}
              >
                There Is No Turning Back!
              </button>
              <button
                className="btn-menu"
                type="button"
                id="btn-tv-youtube-themonitaur"
                onMouseOver={this.onButtonHover}
                onMouseLeave={this.onButtonLeave}
                onClick={this.onButtonClick}
              >
                The Monitaur
              </button>
              <button
                className="btn-menu"
                type="button"
                id="btn-tv-menu"
                onMouseOver={this.onButtonHover}
                onMouseLeave={this.onButtonLeave}
                onClick={this.onButtonClick}
              >
                Menu
              </button>
              <button
                className="btn-menu"
                type="button"
                id="btn-back"
                onMouseOver={this.onButtonHover}
                onMouseLeave={this.onButtonLeave}
                onClick={this.props.gameManager.onResetScene}
              >
                Back
              </button>
            </div>
          );
        case "tvPlaying":
          return (
            <div className="menu-block">
              <button
                className="btn-menu"
                type="button"
                id="btn-tv-menu"
                onMouseOver={this.onButtonHover}
                onMouseLeave={this.onButtonLeave}
                onClick={this.onButtonClick}
              >
                Menu
              </button>
              <button
                className="btn-menu"
                type="button"
                id="btn-back"
                onMouseOver={this.onButtonHover}
                onMouseLeave={this.onButtonLeave}
                onClick={this.props.gameManager.onResetScene}
              >
                Back
              </button>
            </div>
          );

        case "phone":
          return (
            <div className="menu-block">
              <button
                className="btn-menu"
                type="button"
                id="btn-back"
                onMouseOver={this.onButtonHover}
                onMouseLeave={this.onButtonLeave}
                onClick={this.props.gameManager.onPhoneReset}
              >
                Back
              </button>
            </div>
          );
        default:
          break;
      }
    }
  };
  render = () => {
    return <div className="menu">{this.menu()}</div>;
  };
}
export default Menu;
