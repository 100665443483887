import GameObject from "./GameObject";
import Chair from "../../Assets/Models/Chair.glb";

class ChairGO extends GameObject {
  loadModels() {
    this._params.loader.load(Chair, (object) => {
      object.scene.traverse((c) => {
        c.receiveShadow = true;
        c.castShadow = true;
      });

      this._mesh = object.scene;
      object.scene.position.set(9.9, 0.2, 0);

      this.setIsVisible(false);
      
      this.dispatchModelLoaded(this._mesh);
    });
  }
  setIsVisible = (isVisible) => {
    this._mesh.visible = isVisible;
  }
  get mesh() {
    return this._mesh;
  }
}
export default ChairGO;
