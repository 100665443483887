import BookshelfBooksGO from "./Objects/GameObjects/BookshelfBooksGO";
import BookshelfGO from "./Objects/GameObjects/BookshelfGO";
import BookGO from "./Objects/GameObjects/BookGO";
import TVGO from "./Objects/GameObjects/TVGO";
import MonitorGO from "./Objects/GameObjects/MonitorGO";
import SynthesizerGO from "./Objects/GameObjects/SynthesizerGO";
import ArtOpensourceGO from "./Objects/GameObjects/ArtOpensourceGO";
import ReyGO from "./Objects/GameObjects/ReyGO";
import PhoneGO from "./Objects/GameObjects/PhoneGO";
import ArtBackWallGO from "./Objects/GameObjects/ArtBackWallGO";
import PaintingTINTB from "./Objects/GameObjects/Paintings/PaintingTINTB";
import PaintingGemWars from "./Objects/GameObjects/Paintings/PaintingGemWars";
import PaintingOpenDJRadio from "./Objects/GameObjects/Paintings/PaintingOpenDJRadio";
import PaintingTheMonitaur from "./Objects/GameObjects/Paintings/PaintingTheMonitaur";
import PaintingGoldBunnyStore from "./Objects/GameObjects/Paintings/PaintingGoldBunnyStore";
import PaintingAllieChat from "./Objects/GameObjects/Paintings/PaintingAllieChat";
import PaintingPHS from "./Objects/GameObjects/Paintings/PaintingPHS";
import PaintingGitHub from "./Objects/GameObjects/Paintings/PaintingGitHub";
import PaintingNuGet from "./Objects/GameObjects/Paintings/PaintingNuGet";

class MenuManager {
  constructor(params) {
    this._params = params;
  }

  onButtonHover = (eventData) => {
    switch (eventData.type) {
      case "btn-projects":
      case "btn-projects-tintb":
      case "btn-projects-tintb-steam":
      case "btn-projects-tintb-site":
      case "btn-projects-tintb-googlePlay":
      case "btn-projects-gemwars":
      case "btn-projects-opendjradio":
      case "btn-projects-opendjradio-site":
      case "btn-projects-themonitaur":
      case "btn-projects-themonitaur-site":
      case "btn-projects-goldbunnystore":
      case "btn-projects-goldbunnystore-site":
      case "btn-projects-alliechat":
      case "btn-projects-alliechat-site":
      case "btn-projects-phs":
      case "btn-projects-phs-site":
        const artBackWall = this.gameObjects.find(
          (x) => x instanceof ArtBackWallGO
        );

        switch (eventData.type) {
          case "btn-projects-tintb":
          case "btn-projects-tintb-steam":
          case "btn-projects-tintb-site":
          case "btn-projects-tintb-googlePlay":
            const paintingTINTB = artBackWall.paintings.find(
              (x) => x instanceof PaintingTINTB
            );

            switch (eventData.type) {
              case "btn-projects-tintb-steam":
                artBackWall.hoverCallback([paintingTINTB.getItOnSteam]);
                break;
              case "btn-projects-tintb-site":
                artBackWall.hoverCallback([paintingTINTB.visitTheSite]);
                break;
              case "btn-projects-tintb-googlePlay":
                artBackWall.hoverCallback([paintingTINTB.getItOnGooglePlay]);
                break;
              default:
                artBackWall.hoverCallback(paintingTINTB.mesh.children);
                break;
            }
            break;
          case "btn-projects-gemwars":
            const paintingGemWars = artBackWall.paintings.find(
              (x) => x instanceof PaintingGemWars
            );
            artBackWall.hoverCallback(paintingGemWars.mesh.children);
            break;
          case "btn-projects-opendjradio":
          case "btn-projects-opendjradio-site":
            const paintingOpenDJRadio = artBackWall.paintings.find(
              (x) => x instanceof PaintingOpenDJRadio
            );

            switch (eventData.type) {
              case "btn-projects-opendjradio-site":
                artBackWall.hoverCallback([paintingOpenDJRadio.visitTheSite]);
                break;
              default:
                artBackWall.hoverCallback(paintingOpenDJRadio.mesh.children);
                break;
            }
            break;
          case "btn-projects-themonitaur":
          case "btn-projects-themonitaur-site":
            const paintingTheMonitaur = artBackWall.paintings.find(
              (x) => x instanceof PaintingTheMonitaur
            );
            switch (eventData.type) {
              case "btn-projects-themonitaur-site":
                artBackWall.hoverCallback([paintingTheMonitaur.visitTheSite]);
                break;
              default:
                artBackWall.hoverCallback(paintingTheMonitaur.mesh.children);
                break;
            }
            break;
          case "btn-projects-goldbunnystore":
          case "btn-projects-goldbunnystore-site":
            const paintingGoldBunnyStore = artBackWall.paintings.find(
              (x) => x instanceof PaintingGoldBunnyStore
            );

            switch (eventData.type) {
              case "btn-projects-goldbunnystore-site":
                artBackWall.hoverCallback([
                  paintingGoldBunnyStore.visitTheSite,
                ]);
                break;
              default:
                artBackWall.hoverCallback(paintingGoldBunnyStore.mesh.children);
                break;
            }
            break;
          case "btn-projects-alliechat":
          case "btn-projects-alliechat-site":
            const paintingAllieChat = artBackWall.paintings.find(
              (x) => x instanceof PaintingAllieChat
            );

            switch (eventData.type) {
              case "btn-projects-alliechat-site":
                artBackWall.hoverCallback([paintingAllieChat.visitTheSite]);
                break;
              default:
                artBackWall.hoverCallback(paintingAllieChat.mesh.children);
                break;
            }
            break;
          case "btn-projects-phs":
          case "btn-projects-phs-site":
            const paintingPHS = artBackWall.paintings.find(
              (x) => x instanceof PaintingPHS
            );

            switch (eventData.type) {
              case "btn-projects-phs-site":
                artBackWall.hoverCallback([paintingPHS.visitTheSite]);
                break;
              default:
                artBackWall.hoverCallback(paintingPHS.mesh.children);
                break;
            }
            break;
          default:
            artBackWall.hoverCallback(artBackWall.mesh.children);
            break;
        }
        break;
      case "btn-packages":
        const bookcase = this.gameObjects.find((x) => x instanceof BookshelfGO);
        bookcase.onHoverEvent();
        break;
      case "btn-bookcase-alliechat":
      case "btn-bookcase-paypal.net":
      case "btn-bookcase-jwts":
      case "btn-bookcase-oauthserver.net":
      case "btn-bookcase-ntier.net":
      case "btn-bookcase-sitemaps.net":
      case "btn-bookcase-steam.net":
      case "btn-bookcase-tcp.net":
      case "btn-bookcase-websocketssimple":
      case "btn-bookcase-themonitaur":
      case "btn-bookcase-phs.core":
      case "btn-bookcase-twitchbots.net":
        const bookshelfBooks = this.gameObjects.find(
          (x) => x instanceof BookshelfBooksGO
        );

        switch (eventData.type) {
          case "btn-bookcase-alliechat":
            bookshelfBooks.onHoverCallback(
              bookshelfBooks.allieChat.children[0]
            );
            break;
          case "btn-bookcase-paypal.net":
            bookshelfBooks.onHoverCallback(
              bookshelfBooks.paypalNET.children[0]
            );
            break;
          case "btn-bookcase-jwts":
            bookshelfBooks.onHoverCallback(bookshelfBooks.jwts.children[0]);
            break;
          case "btn-bookcase-oauthserver.net":
            bookshelfBooks.onHoverCallback(
              bookshelfBooks.oauthServerNET.children[0]
            );
            break;
          case "btn-bookcase-ntier.net":
            bookshelfBooks.onHoverCallback(bookshelfBooks.nTierNET.children[0]);
            break;
          case "btn-bookcase-sitemaps.net":
            bookshelfBooks.onHoverCallback(
              bookshelfBooks.siteMapsNET.children[0]
            );
            break;
          case "btn-bookcase-steam.net":
            bookshelfBooks.onHoverCallback(bookshelfBooks.steamNET.children[0]);
            break;
          case "btn-bookcase-tcp.net":
            bookshelfBooks.onHoverCallback(bookshelfBooks.tcpNET.children[0]);
            break;
          case "btn-bookcase-websocketssimple":
            bookshelfBooks.onHoverCallback(
              bookshelfBooks.websocketsSimple.children[0]
            );
            break;
          case "btn-bookcase-themonitaur":
            bookshelfBooks.onHoverCallback(
              bookshelfBooks.theMonitaur.children[0]
            );
            break;
          case "btn-bookcase-phs.core":
            bookshelfBooks.onHoverCallback(bookshelfBooks.phsCore.children[0]);
            break;
          case "btn-bookcase-twitchbots.net":
            bookshelfBooks.onHoverCallback(
              bookshelfBooks.twitchBotsNET.children[0]
            );
            break;
          default:
            break;
        }
        break;
      case "btn-bookcase-book-github":
      case "btn-bookcase-book-nuget":
      case "btn-bookcase-book-docker":
      case "btn-bookcase-book-site":
        const book = this.gameObjects.find((x) => x instanceof BookGO);

        switch (eventData.type) {
          case "btn-bookcase-book-docker":
          case "btn-bookcase-book-site":
            book.hoverCallback(book.extra);
            break;
          case "btn-bookcase-book-github":
            book.hoverCallback(book.gitHub);
            break;
          case "btn-bookcase-book-nuget":
            book.hoverCallback(book.nuGet);
            break;
          default:
            break;
        }
        break;
      case "btn-opensource":
      case "btn-opensource-github":
      case "btn-opensource-github-site":
      case "btn-opensource-nuget":
      case "btn-opensource-nuget-site":
        const artOpensource = this.gameObjects.find(
          (x) => x instanceof ArtOpensourceGO
        );

        switch (eventData.type) {
          case "btn-opensource-github":
          case "btn-opensource-github-site":
            const paintingGithub = artOpensource.paintings.find(
              (x) => x instanceof PaintingGitHub
            );

            switch (eventData.type) {
              case "btn-opensource-github-site":
                artOpensource.hoverCallback([paintingGithub.visitTheSite]);
                break;
              default:
                artOpensource.hoverCallback(paintingGithub.mesh.children);
                break;
            }
            break;
          case "btn-opensource-nuget":
          case "btn-opensource-nuget-site":
            const paintingNuget = artOpensource.paintings.find(
              (x) => x instanceof PaintingNuGet
            );

            switch (eventData.type) {
              case "btn-opensource-nuget-site":
                artOpensource.hoverCallback([paintingNuget.visitTheSite]);
                break;
              default:
                artOpensource.hoverCallback(paintingNuget.mesh.children);
                break;
            }
            break;
          default:
            artOpensource.hoverCallback(artOpensource.mesh.children);
            break;
        }
        break;
      case "btn-piano":
        const piano = this.gameObjects.find((x) => x instanceof SynthesizerGO);
        piano.hoverCallback(piano.mesh);
        break;
      case "btn-monitor":
      case "btn-monitor-play-gemwars":
      case "btn-monitor-stop":
        const monitor = this.gameObjects.find((x) => x instanceof MonitorGO);
        switch (eventData.type) {
          case "btn-monitor-play-gemwars":
            monitor.hoverCallback([monitor.seeWhatIsInTheWorks]);
            break;
          case "btn-monitor-stop":
            monitor.hoverCallback([monitor.stop]);
            break;
          default:
            monitor.hoverCallback(monitor.mesh.children);
            break;
        }
        break;
      case "btn-videos":
      case "btn-tv-twitch":
      case "btn-tv-youtube":
      case "btn-tv-menu":
      case "btn-tv-youtube-oauthserver.net":
      case "btn-tv-youtube-gemwars":
      case "btn-tv-youtube-themonitaur":
      case "btn-tv-youtube-alliechat":
      case "btn-tv-youtube-tintb":
      case "btn-tv-youtube-opendjradio":
        const tv = this.gameObjects.find((x) => x instanceof TVGO);
        switch (eventData.type) {
          case "btn-tv-twitch":
            tv.hoverCallback([tv.twitch.mesh]);
            break;
          case "btn-tv-youtube":
            tv.hoverCallback([tv.youtube.mesh]);
            break;
          case "btn-tv-menu":
            tv.hoverCallback([tv.menu.mesh]);
            break;
          case "btn-tv-youtube-oauthserver.net":
            tv.hoverCallback([tv.oauthServerNET.mesh]);
            break;
          case "btn-tv-youtube-gemwars":
            tv.hoverCallback([tv.gemWars.mesh]);
            break;
          case "btn-tv-youtube-themonitaur":
            tv.hoverCallback([tv.theMonitaur.mesh]);
            break;
          case "btn-tv-youtube-alliechat":
            tv.hoverCallback([tv.allieChat.mesh]);
            break;
          case "btn-tv-youtube-tintb":
            tv.hoverCallback([tv.tintb.mesh]);
            break;
          case "btn-tv-youtube-opendjradio":
            tv.hoverCallback([tv.openDJRadio.mesh]);
            break;
          default:
            tv.hoverCallback(tv.mesh.children);
            break;
        }
        break;
      case "btn-rey":
        const rey = this.gameObjects.find((x) => x instanceof ReyGO);
        rey.hoverCallback(rey.mesh.children[0]);
        break;
      case "btn-contact":
        const phone = this.gameObjects.find((x) => x instanceof PhoneGO);
        phone.hoverCallback(phone.mesh.children);
        break;
      default:
        break;
    }
  };
  onButtonClick = (eventData) => {
    switch (eventData.type) {
      case "btn-projects":
      case "btn-projects-tintb":
      case "btn-projects-tintb-steam":
      case "btn-projects-tintb-site":
      case "btn-projects-tintb-googlePlay":
      case "btn-projects-gemwars":
      case "btn-projects-opendjradio":
      case "btn-projects-opendjradio-site":
      case "btn-projects-themonitaur":
      case "btn-projects-themonitaur-site":
      case "btn-projects-goldbunnystore":
      case "btn-projects-goldbunnystore-site":
      case "btn-projects-alliechat":
      case "btn-projects-alliechat-site":
      case "btn-projects-phs":
      case "btn-projects-phs-site":
        const artBackWall = this.gameObjects.find(
          (x) => x instanceof ArtBackWallGO
        );

        switch (eventData.type) {
          case "btn-projects-tintb":
          case "btn-projects-tintb-steam":
          case "btn-projects-tintb-site":
          case "btn-projects-tintb-googlePlay":
            const paintingTINTB = artBackWall.paintings.find(
              (x) => x instanceof PaintingTINTB
            );

            switch (eventData.type) {
              case "btn-projects-tintb-steam":
                artBackWall.clickCallback([paintingTINTB.getItOnSteam]);
                break;
              case "btn-projects-tintb-site":
                artBackWall.clickCallback([paintingTINTB.visitTheSite]);
                break;
              case "btn-projects-tintb-googlePlay":
                artBackWall.clickCallback([paintingTINTB.getItOnGooglePlay]);
                break;
              default:
                artBackWall.clickCallback(paintingTINTB.mesh.children);
                break;
            }
            break;
          case "btn-projects-gemwars":
            const paintingGemWars = artBackWall.paintings.find(
              (x) => x instanceof PaintingGemWars
            );
            artBackWall.clickCallback(paintingGemWars.mesh.children);
            break;
          case "btn-projects-opendjradio":
          case "btn-projects-opendjradio-site":
            const paintingOpenDJRadio = artBackWall.paintings.find(
              (x) => x instanceof PaintingOpenDJRadio
            );

            switch (eventData.type) {
              case "btn-projects-opendjradio-site":
                artBackWall.clickCallback([paintingOpenDJRadio.visitTheSite]);
                break;
              default:
                artBackWall.clickCallback(paintingOpenDJRadio.mesh.children);
                break;
            }
            break;
          case "btn-projects-themonitaur":
          case "btn-projects-themonitaur-site":
            const paintingTheMonitaur = artBackWall.paintings.find(
              (x) => x instanceof PaintingTheMonitaur
            );

            switch (eventData.type) {
              case "btn-projects-themonitaur-site":
                artBackWall.clickCallback([paintingTheMonitaur.visitTheSite]);
                break;
              default:
                artBackWall.clickCallback(paintingTheMonitaur.mesh.children);
                break;
            }
            break;
          case "btn-projects-goldbunnystore":
          case "btn-projects-goldbunnystore-site":
            const paintingGoldBunnyStore = artBackWall.paintings.find(
              (x) => x instanceof PaintingGoldBunnyStore
            );

            switch (eventData.type) {
              case "btn-projects-goldbunnystore-site":
                artBackWall.clickCallback([
                  paintingGoldBunnyStore.visitTheSite,
                ]);
                break;
              default:
                artBackWall.clickCallback(paintingGoldBunnyStore.mesh.children);
                break;
            }
            break;
          case "btn-projects-alliechat":
          case "btn-projects-alliechat-site":
            const paintingAllieChat = artBackWall.paintings.find(
              (x) => x instanceof PaintingAllieChat
            );

            switch (eventData.type) {
              case "btn-projects-alliechat-site":
                artBackWall.clickCallback([paintingAllieChat.visitTheSite]);
                break;
              default:
                artBackWall.clickCallback(paintingAllieChat.mesh.children);
                break;
            }
            break;
          case "btn-projects-phs":
          case "btn-projects-phs-site":
            const paintingPHS = artBackWall.paintings.find(
              (x) => x instanceof PaintingPHS
            );

            switch (eventData.type) {
              case "btn-projects-phs-site":
                artBackWall.clickCallback([paintingPHS.visitTheSite]);
                break;
              default:
                artBackWall.clickCallback(paintingPHS.mesh.children);
                break;
            }
            break;
          default:
            artBackWall.clickCallback(artBackWall.mesh.children);
            break;
        }
        break;
      case "btn-packages":
        const bookshelf = this.gameObjects.find(
          (x) => x instanceof BookshelfGO
        );
        bookshelf.onClickEvent();
        break;
      case "btn-bookcase-alliechat":
      case "btn-bookcase-paypal.net":
      case "btn-bookcase-jwts":
      case "btn-bookcase-oauthserver.net":
      case "btn-bookcase-ntier.net":
      case "btn-bookcase-sitemaps.net":
      case "btn-bookcase-steam.net":
      case "btn-bookcase-tcp.net":
      case "btn-bookcase-websocketssimple":
      case "btn-bookcase-themonitaur":
      case "btn-bookcase-phs.core":
      case "btn-bookcase-twitchbots.net":
        const bookshelfBooks = this.gameObjects.find(
          (x) => x instanceof BookshelfBooksGO
        );

        switch (eventData.type) {
          case "btn-bookcase-alliechat":
            bookshelfBooks.onClickCallback(
              bookshelfBooks.allieChat.children[0]
            );
            break;
          case "btn-bookcase-paypal.net":
            bookshelfBooks.onClickCallback(
              bookshelfBooks.paypalNET.children[0]
            );
            break;
          case "btn-bookcase-jwts":
            bookshelfBooks.onClickCallback(bookshelfBooks.jwts.children[0]);
            break;
          case "btn-bookcase-oauthserver.net":
            bookshelfBooks.onClickCallback(
              bookshelfBooks.oauthServerNET.children[0]
            );
            break;
          case "btn-bookcase-ntier.net":
            bookshelfBooks.onClickCallback(bookshelfBooks.nTierNET.children[0]);
            break;
          case "btn-bookcase-sitemaps.net":
            bookshelfBooks.onClickCallback(
              bookshelfBooks.siteMapsNET.children[0]
            );
            break;
          case "btn-bookcase-steam.net":
            bookshelfBooks.onClickCallback(bookshelfBooks.steamNET.children[0]);
            break;
          case "btn-bookcase-tcp.net":
            bookshelfBooks.onClickCallback(bookshelfBooks.tcpNET.children[0]);
            break;
          case "btn-bookcase-websocketssimple":
            bookshelfBooks.onClickCallback(
              bookshelfBooks.websocketsSimple.children[0]
            );
            break;
          case "btn-bookcase-themonitaur":
            bookshelfBooks.onClickCallback(
              bookshelfBooks.theMonitaur.children[0]
            );
            break;
          case "btn-bookcase-phs.core":
            bookshelfBooks.onClickCallback(bookshelfBooks.phsCore.children[0]);
            break;
          case "btn-bookcase-twitchbots.net":
            bookshelfBooks.onClickCallback(
              bookshelfBooks.twitchBotsNET.children[0]
            );
            break;
          default:
            break;
        }
        break;
      case "btn-bookcase-book-github":
      case "btn-bookcase-book-nuget":
      case "btn-bookcase-book-docker":
      case "btn-bookcase-book-site":
        const book = this.gameObjects.find((x) => x instanceof BookGO);

        switch (eventData.type) {
          case "btn-bookcase-book-docker":
          case "btn-bookcase-book-site":
            book.clickCallback(book.extra);
            break;
          case "btn-bookcase-book-github":
            book.clickCallback(book.gitHub);
            break;
          case "btn-bookcase-book-nuget":
            book.clickCallback(book.nuGet);
            break;
          default:
            break;
        }
        break;
      case "btn-opensource":
      case "btn-opensource-github":
      case "btn-opensource-github-site":
      case "btn-opensource-nuget":
      case "btn-opensource-nuget-site":
        const artOpensource = this.gameObjects.find(
          (x) => x instanceof ArtOpensourceGO
        );

        switch (eventData.type) {
          case "btn-opensource-github":
          case "btn-opensource-github-site":
            const paintingGithub = artOpensource.paintings.find(
              (x) => x instanceof PaintingGitHub
            );

            switch (eventData.type) {
              case "btn-opensource-github-site":
                artOpensource.clickCallback([paintingGithub.visitTheSite]);
                break;
              default:
                artOpensource.clickCallback(paintingGithub.mesh.children);
                break;
            }
            break;
          case "btn-opensource-nuget":
          case "btn-opensource-nuget-site":
            const paintingNuget = artOpensource.paintings.find(
              (x) => x instanceof PaintingNuGet
            );

            switch (eventData.type) {
              case "btn-opensource-nuget-site":
                artOpensource.clickCallback([paintingNuget.visitTheSite]);
                break;
              default:
                artOpensource.clickCallback(paintingNuget.mesh.children);
                break;
            }
            break;
          default:
            artOpensource.clickCallback(artOpensource.mesh.children);
            break;
        }
        break;
      case "btn-piano":
        const piano = this.gameObjects.find((x) => x instanceof SynthesizerGO);
        piano.clickCallback(piano.mesh);
        break;
      case "btn-monitor":
      case "btn-monitor-play-gemwars":
      case "btn-monitor-stop":
        const monitor = this.gameObjects.find((x) => x instanceof MonitorGO);
        switch (eventData.type) {
          case "btn-monitor-play-gemwars":
            monitor.clickCallback([monitor.seeWhatIsInTheWorks]);
            break;
          case "btn-monitor-stop":
            monitor.clickCallback([monitor.stop]);
            break;
          default:
            monitor.clickCallback(monitor.mesh.children);
            break;
        }
        break;
      case "btn-videos":
      case "btn-tv-twitch":
      case "btn-tv-youtube":
      case "btn-tv-menu":
      case "btn-tv-youtube-oauthserver.net":
      case "btn-tv-youtube-gemwars":
      case "btn-tv-youtube-themonitaur":
      case "btn-tv-youtube-alliechat":
      case "btn-tv-youtube-tintb":
      case "btn-tv-youtube-opendjradio":
        const tv = this.gameObjects.find((x) => x instanceof TVGO);
        switch (eventData.type) {
          case "btn-tv-twitch":
            tv.clickCallback([tv.twitch.mesh]);
            break;
          case "btn-tv-youtube":
            tv.clickCallback([tv.youtube.mesh]);
            break;
          case "btn-tv-menu":
            tv.clickCallback([tv.menu.mesh]);
            break;
          case "btn-tv-youtube-oauthserver.net":
            tv.clickCallback([tv.oauthServerNET.mesh]);
            break;
          case "btn-tv-youtube-gemwars":
            tv.clickCallback([tv.gemWars.mesh]);
            break;
          case "btn-tv-youtube-themonitaur":
            tv.clickCallback([tv.theMonitaur.mesh]);
            break;
          case "btn-tv-youtube-alliechat":
            tv.clickCallback([tv.allieChat.mesh]);
            break;
          case "btn-tv-youtube-tintb":
            tv.clickCallback([tv.tintb.mesh]);
            break;
          case "btn-tv-youtube-opendjradio":
            tv.clickCallback([tv.openDJRadio.mesh]);
            break;
          default:
            tv.clickCallback(tv.mesh.children);
            break;
        }
        break;
      case "btn-rey":
        const rey = this.gameObjects.find((x) => x instanceof ReyGO);
        rey.clickCallback(rey.mesh);
        break;
      case "btn-contact":
        const phone = this.gameObjects.find((x) => x instanceof PhoneGO);
        phone.clickCallback(phone.mesh);
        break;
      default:
        break;
    }
  };
  get gameObjects() {
    return this._params.gameObjects;
  }
}
export default MenuManager;
