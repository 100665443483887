class State {
  constructor(parent) {
    this.parent = parent;

    this.finishedCallback = () => {
      this.finished();
    };
  }
  enter = () => {}
  finished = () => {}
  cleanup = () => {}
  exit = () => {}
  update = () => {}
}
export default State;
