import * as THREE from "three";
import BaseObject from "./BaseObject";
import {Howl} from 'howler';
import { PianoSongs } from "../enums";
import { S3_BUCKET } from "../env";
import Whoosh from "../Assets/Sfx/whoosh.mp3";
import BookOpen from "../Assets/Sfx/bookOpen.mp3";
import BookClose from "../Assets/Sfx/bookClose.mp3";
import Clapping from "../Assets/Sfx/clapping.mp3";
import Bark from "../Assets/Sfx/Rey/bark.mp3";
import Wimper0 from "../Assets/Sfx/Rey/wimper0.mp3";
import Wimper1 from "../Assets/Sfx/Rey/wimper1.mp3";
import Growl0 from "../Assets/Sfx/Rey/growl0.mp3";

class AudioManagerBO extends BaseObject {
  constructor(params) {
    super();
    this._params = params;

    this._listener = new THREE.AudioListener();

    const audioLoader = new THREE.AudioLoader();
    audioLoader.load(Whoosh, buffer => {
      this._whoosh = buffer;
    });
    audioLoader.load(BookOpen, buffer => {
      this._bookOpen = buffer;
    });
    audioLoader.load(BookClose, buffer => {
      this._bookClose = buffer;
    });
    audioLoader.load(Clapping, buffer => {
      this._clapping = buffer;
    });
    audioLoader.load(Bark, buffer => {
      this._bark = buffer;
    });
    audioLoader.load(Wimper0, buffer => {
      this._wimper0 = buffer;
    });
    audioLoader.load(Wimper1, buffer => {
      this._wimper1 = buffer;
    });
    audioLoader.load(Growl0, buffer => {
      this._growl0 = buffer;
    });
    
    this._muted = this._params.muted;
    this._volume = this._params.volume / 100;

    document.getElementById('tintb_video').volume = this._muted ? 0 : this._volume;
  }
  playWhoosh = () => {
    if (!this._muted) {
      const sound = new THREE.Audio(this._listener);
      sound.setBuffer(this._whoosh);
      sound.setLoop(false);
      sound.offset = .2;
      sound.setVolume(.5 * this._volume);
      sound.setPlaybackRate(0.85);
      sound.detune = (Math.random() * 500 - 250);
      sound.play();
    }
  }
  playBookOpen = () => {
    if (!this._muted) {
      setTimeout(() => {
        const sound = new THREE.Audio(this._listener);
        sound.setBuffer(this._bookOpen);
        sound.setLoop(false);
        sound.offset = .2;
        sound.setVolume(.5 * this._volume);
        sound.detune = (Math.random() * 500 - 250);
        sound.play();
      }, 350);
    }
  }
  playBookClose = () => {
    if (!this._muted) {
      const sound = new THREE.Audio(this._listener);
      sound.setBuffer(this._bookOpen);
      sound.setLoop(false);
      sound.setVolume(.5 * this._volume);
      sound.detune = (Math.random() * 500 - 250);
      sound.play();
    }
  }
  playClapping = () => {
    if (!this._muted) {
      const sound = new THREE.Audio(this._listener);
      sound.setBuffer(this._clapping);
      sound.setLoop(false);
      sound.setVolume(.3 * this._volume);
      sound.play();
    }
  }
  playBackgroundMusic = () => {
    if (!this._music) {
      let song = S3_BUCKET + "Music/background.mp3";
      const random = Math.random();

      if (random > 0.857) {
        song = S3_BUCKET + "Music/background6.mp3";
      } else if (random > 0.714) {
        song = S3_BUCKET + "Music/background5.mp3";
      } else if (random > 0.571) {
        song = S3_BUCKET + "Music/background4.mp3";
      } else if (random > 0.429) {
        song = S3_BUCKET + "Music/background3.mp3";
      } else if (random > 0.286) {
        song = S3_BUCKET + "Music/background2.mp3";
      } else if (random > 0.143) {
        song = S3_BUCKET + "Music/background1.mp3";
      }

      this._music = new Howl({
        src: [song],
        html5: false,
        loop: false,
        volume: 1
      });

      this._music.on('end', () => {
        this._musicId = undefined;
        this._music = undefined;
        this.playBackgroundMusic();
      });
    } 

    if (!this._pianoMusic && !this._muted) {
      this._music.volume(0.25 * this._volume, this._musicId);
    } else {
      this._music.volume(0, this._musicId);
    }

    this._musicId = this._music.play(this._musicId);
  }
  playBark = () => {
    if (!this._muted) {
      const sound = new THREE.Audio(this._listener);
      sound.setBuffer(this._bark);
      sound.setLoop(false);
      sound.setVolume(.3 * this._volume);
      sound.play();
    }
  }
  playWimper = () => {
    if (!this._muted) {
      const sound = new THREE.Audio(this._listener);
      sound.setBuffer(Math.random() > 0.5 ? this._wimper0 : this._wimper1);
      sound.setLoop(false);
      sound.setVolume(1 * this._volume);
      sound.play();
    }
  }
  playGrowl = () => {
    if (!this._muted) {
      const sound = new THREE.Audio(this._listener);
      sound.setBuffer(this._growl0);
      sound.setLoop(false);
      sound.setVolume(.7 * this._volume);
      sound.play();
    }
  }
  fadeoutBackgroundMusic = () => {
    if (this._music && !this._isFadedOut) {
      this._isFadedOut = true;

      if (!this._muted) {
        this._music.fade(.25 * this._volume, 0, 1000, this._musicId);
      }
    }
  } 
  fadeinBackgroundMusic = () => {
    if (!this._pianoMusic) {
      if (!this._music) {
        this.playBackgroundMusic();
      } else if (this._isFadedOut) {
        this._isFadedOut = false;
        
        if (!this._muted) {
          this._music.fade(0, .25 * this._volume, 1000, this._musicId);
        }
      }
    }
  }
  playPiano = (pianoSong) => {
    let song = S3_BUCKET + "Music/piano1.mp3";

    switch (pianoSong) {
      case PianoSongs.fast:
        song = S3_BUCKET + "Music/piano0.mp3";
        break;
      default:
        break;
    }

    this._pianoMusic = new Howl({
      src: [song],
      html5: false,
      loop: false,
      volume: 1
    });

    this._pianoMusic.on('end', () => {
      this._pianoMusicId = undefined;
      this._pianoMusic = undefined;

      this.dispatchActionEvent({
        action: "pianoEnded"
      });
    });

    if (!this._muted) {
      this._pianoMusic.volume(0.5 * this._volume, this._pianoMusicId);
    } else {
      this._pianoMusic.volume(0, this._pianoMusicId);
    }

    this._pianoMusicId = this._pianoMusic.play(this._pianoMusicId);
  }
  stopPiano = () => {
    this._pianoMusic.stop();
    
    this._pianoMusicId = undefined;
    this._pianoMusic = undefined;

    this.dispatchActionEvent({
      action: "pianoEndedSad"
    });
  }
  setMuted = (muted) => {
    this._muted = muted;

    if (this._music) {
      if (this._muted) {
        this._music.volume(0, this._musicId);
      } else {
        this.playBackgroundMusic();
      }
    }

    if (this._pianoMusic) {
      if (this._muted) {
        this._pianoMusic.volume(0, this._pianoMusicId);
      } else {
        this._pianoMusic.volume(0.5 * this._volume, this._pianoMusicId);
      }
    }

    document.getElementById('tintb_video').volume = this._muted ? 0 : this._volume;
  }
  setVolume = (volume) => {
    this._volume = volume / 100

    if (this._music && !this._muted) {
      this.playBackgroundMusic();
    }

    if (this._pianoMusic && !this._muted) {
      this._pianoMusic.volume(0.5 * this._volume, this._pianoMusicId);
    }

    document.getElementById('tintb_video').volume = this._muted ? 0 : this._volume;
  }
  dispatchActionEvent = (detail) => {
    this._params.actionEvent(detail);
  }
  dispose() {
    if (this._music) {
      this._music.stop(this._musicId);
    }

    if (this._pianoMusic) {
      this._pianoMusic.stop(this._pianoMusicId);
    }
  }
}
export default AudioManagerBO;
