import GameObject from "./GameObject";
import Synthesizer from "../../Assets/Models/Synthesizer.glb";

class SynthesizerGO extends GameObject {
  loadModels() {
    this._params.loader.load(Synthesizer, (object) => {
      object.scene.traverse((c) => {
        c.receiveShadow = true;
        c.castShadow = true;

        c.hoverCallback = () => this.hoverCallback();
        c.clickCallback = () => this.clickCallback();
      });

      this._mesh = object.scene;

      this.setIsVisible(false);

      this.dispatchModelLoaded(this._mesh);

      this.reset();
    });
  }
  hoverCallback = () => {
    if (this._canBeSelected) {
      this._params.hoverEvent({
        items: this._mesh.children,
        source: this,
      });
    }
  };
  clickCallback = () => {
    if (this._canBeSelected) {
      this._params.clickEvent({
        items: this._mesh.children,
        source: this,
      });
    }
  };
  reset = () => {
    this.setCanBeSelected(true);
  }
  setCanBeSelected = (canBeSelected) => {
    this._canBeSelected = canBeSelected;
  }
  get mesh() {
    return this._mesh;
  }
}
export default SynthesizerGO;
