import * as THREE from "three";
import FiniteStateMachine from "./FiniteStateMachine";
import AnimationState from "./States/AnimationState";
import { RobAnimationType } from "./States/enums";

class RobFSM extends FiniteStateMachine {
  constructor(parent) {
    super(parent);
    this.init();
  }
  init = () => {
    this.addState(RobAnimationType.typing, new AnimationState(this, RobAnimationType.typing, THREE.LoopPingPong, 0.5));
    this.addState(RobAnimationType.sitting, new AnimationState(this, RobAnimationType.sitting, THREE.LoopOnce, 0.5));
    this.addState(RobAnimationType.sittingLoop, new AnimationState(this, RobAnimationType.sittingLoop, THREE.LoopRepeat, 0.5));
    this.addState(RobAnimationType.sittingIdle, new AnimationState(this, RobAnimationType.sittingIdle, THREE.LoopOnce, 0.5));
    this.addState(RobAnimationType.sittingClap, new AnimationState(this, RobAnimationType.sittingClap, THREE.LoopOnce, 0.2));
    this.addState(RobAnimationType.sittingCheer, new AnimationState(this, RobAnimationType.sittingCheer, THREE.LoopOnce, 0.2));
    this.addState(RobAnimationType.sittingLaughing, new AnimationState(this, RobAnimationType.sittingLaughing, THREE.LoopOnce, 0.5));
    this.addState(RobAnimationType.sittingDisapproval, new AnimationState(this, RobAnimationType.sittingDisapproval, THREE.LoopOnce, 0.5));
    this.addState(RobAnimationType.sittingDisbelief, new AnimationState(this, RobAnimationType.sittingDisbelief, THREE.LoopOnce, 0.2));
    this.addState(RobAnimationType.sittingRubbingArm, new AnimationState(this, RobAnimationType.sittingRubbingArm, THREE.LoopOnce, 1, .55));
    this.addState(RobAnimationType.sittingGunMotion, new AnimationState(this, RobAnimationType.sittingGunMotion, THREE.LoopOnce, 1.5, .35));
    this.addState(RobAnimationType.sittingAngry, new AnimationState(this, RobAnimationType.sittingAngry, THREE.LoopOnce, 0.2));
    this.addState(RobAnimationType.sittingVictory, new AnimationState(this, RobAnimationType.sittingVictory, THREE.LoopOnce, 0.2));
    this.addState(RobAnimationType.typeToSit, new AnimationState(this, RobAnimationType.typeToSit, THREE.LoopOnce, 0.5));
    this.addState(RobAnimationType.sitToType, new AnimationState(this, RobAnimationType.sitToType, THREE.LoopOnce, 0.5, 1.0));
    this.addState(RobAnimationType.piano, new AnimationState(this, RobAnimationType.piano, THREE.LoopPingPong, 0.5, 1.35));
    this.addState(RobAnimationType.pianoSlow, new AnimationState(this, RobAnimationType.pianoSlow, THREE.LoopPingPong, 0.5, .75));
  };
}
export default RobFSM;
