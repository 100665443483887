import "./VolumeControls.scss";
import React from "react";
import SpeakerMax from "./Assets/Textures/SpeakerMax.png";
import SpeakerMute from "./Assets/Textures/SpeakerMute.png";
import SpeakerMid from "./Assets/Textures/SpeakerMid.png";
import SpeakerLow from "./Assets/Textures/SpeakerLow.png";

export default function VolumeControls(props) {
  const volumeSlider = () => {
    if (!props.loading && !props.muted) {
      return (
        <input
          type="range"
          min="1"
          max="100"
          value={props.volume}
          onChange={onVolumeChange}
          className="slider fade-in"
          id="volume-slider"
        />
      );
    }
  };
  const speakerButton = () => {
    if (props.loading) {
      return;
    }

    let speaker = SpeakerMax;
    if (props.muted) {
      speaker = SpeakerMute;
    } else if (props.volume < 33) {
      speaker = SpeakerLow;
    } else if (props.volume < 66) {
      speaker = SpeakerMid;
    }

    return (
      <img
        src={speaker}
        id="speaker-icon"
        alt="Speaker"
        draggable="false"
        onMouseDown={speakerButtonDown}
        onMouseUp={onMuteClicked}
        className="fade-in"
      />
    );
  };
  const speakerButtonDown = () => {
    document.getElementById("speaker-icon").classList.add("active");
  };
  const onMuteClicked = () => {
    const muted = !props.muted;
    props.setMuted(muted);
    localStorage.setItem("mute", muted);
  };
  const onVolumeChange = (event) => {
    const volume = event.target.value;
    props.setVolume(volume);
    localStorage.setItem("volume", volume);
  };

  return (
    <div>
      {speakerButton()}
      {volumeSlider()}
    </div>
  );
}
