import GameObject from "./GameObject";
import Furniture from "../../Assets/Models/Furniture.glb";

class FurnitureGO extends GameObject {
  loadModels() {
    this._params.loader.load(Furniture, (object) => {
      object.scene.traverse((c) => {
        c.receiveShadow = true;
        c.castShadow = true;

        if (c.parent && c.parent.name === "CeilingLamps") {
          // c.receiveShadow = false;
          c.castShadow = false;
        }
      });

      this._mesh = object.scene;

      this.setIsVisible(false);

      this.dispatchModelLoaded(this._mesh);
    });
  }
  setIsVisible = (isVisible) => {
    this._mesh.visible = isVisible;
  }
}
export default FurnitureGO;
